import authReducer from "./authReducer";
import placeReducer from "./placeReducer";
import modalReducer from "./modalReducer";
import tripReducer from "./tripReducer";
import listReducer from "./listReducer";
import mapReducer from "./mapReducer";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  auth: authReducer,
  places: placeReducer,
  modals: modalReducer,
  trips: tripReducer,
  lists: listReducer,
  map: mapReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
});

export default rootReducer;
