import moment from "moment";
import history from "../../Router/history";
import { createApi } from "unsplash-js";
import { TRIP_VIEW_PERMISSION } from "../../components/Constants";

const unsplash = new createApi({
  accessKey: "a94effabbbb479c3178917787b35c886187d35d069f52abaeca3aaae64310727",
});

export const createTrip = tripDetails => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    const userId = getState().firebase.auth.uid;
    // Need explicit diff and duration with days to account for DST change
    const numDays =
      moment.duration(tripDetails.endDate.diff(tripDetails.startDate, "days"), "days").asDays() + 1;
    var newTrip = {
      lat: tripDetails.lat,
      lng: tripDetails.lng,
      title: tripDetails.title,
      location: tripDetails.location,
      photo: {
        altText: "aerial photography of airliner",
        url:
          "https://images.unsplash.com/photo-1436491865332-7a61a109cc05?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9",
        firstName: "Ross",
        lastName: "Parmly",
        profileUrl: "https://unsplash.com/@rparmly",
        username: "rparmly",
      },
      startDate: firebase.firestore.Timestamp.fromDate(tripDetails.startDate.toDate()),
      endDate: firebase.firestore.Timestamp.fromDate(tripDetails.endDate.toDate()),
      numDays: numDays,
      dayDescriptions: Array(numDays).fill(""),
      lodgingList: Array(numDays).fill(""),
      createdAt: new Date(),
      userId: userId,
      viewPermission: TRIP_VIEW_PERMISSION.PRIVATE,
    };

    // Pre-filled helper text to teach users
    newTrip["dayDescriptions"][0] = "Click to add day notes";

    firestore
      .collection("trips")
      .add(newTrip)
      .then(doc => {
        firestore.collection("lists").add({
          title: "Attractions",
          tripId: doc.id,
          userId: userId,
          listIndex: 0,
        });
        firestore.collection("lists").add({
          title: "Food",
          tripId: doc.id,
          userId: userId,
          listIndex: 1,
        });
        firestore.collection("lists").add({
          title: "Accommodations",
          tripId: doc.id,
          userId: userId,
          listIndex: 2,
        });
        history.push("/t/" + doc.id);

        unsplash.search
          .getPhotos({ query: tripDetails.city, orientation: "landscape" })
          .then(json => {
            if (
              json.response &&
              json.response.results &&
              json.response.results.length > 0 &&
              json.response.results[0]
            ) {
              const imageResult = json.response.results[0];
              doc.update({
                photo: {
                  altText: imageResult.alt_description || "",
                  url: (imageResult.urls && imageResult.urls.raw) || "",
                  userFirstName: (imageResult.user && imageResult.user.first_name) || "",
                  userLastName: (imageResult.user && imageResult.user.last_name) || "",
                  userProfileUrl:
                    (imageResult.user && imageResult.user.links && imageResult.user.links.html) ||
                    "",
                  username: (imageResult.user && imageResult.user.username) || "",
                },
              });
              unsplash.photos.trackDownload({
                downloadLocation: imageResult.links.download_location,
              });
            }
          })
          .catch(error => {
            console.error(error);
          });

        dispatch({
          type: "CREATE_TRIP",
          destination: tripDetails.location,
          title: tripDetails.title,
        });
      })
      .catch(err => {
        dispatch({ type: "CREATE_TRIP_ERROR", err });
      });
  };
};

export const updateTripDates = (tripId, startDate, endDate) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    var updatedDetails = {
      startDate: firebase.firestore.Timestamp.fromDate(startDate.toDate()),
      endDate: firebase.firestore.Timestamp.fromDate(endDate.toDate()),
      numDays: moment.duration(endDate.diff(startDate, "days"), "days").asDays() + 1,
      updatedAt: new Date(),
    };
    const docRef = firestore.collection("trips").doc(tripId);
    docRef
      .update({
        ...updatedDetails,
      })
      .then(() => {
        dispatch({ type: "UPDATE_TRIP_DATES", id: tripId });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_TRIP_DATES_ERROR", err });
      });
  };
};

export const updateTripViewPermission = (tripId, viewPermission) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    let updatedDetails = {
      viewPermission: viewPermission,
    };
    if (viewPermission === TRIP_VIEW_PERMISSION.PUBLIC) {
      // Also set isPublic to true on the trip if it's being published with public permissions
      // This is to ensure that the public views will work properly for the trip
      updatedDetails.isPublic = true;
    }
    const docRef = firestore.collection("trips").doc(tripId);
    docRef
      .update({
        ...updatedDetails,
      })
      .then(() => {
        dispatch({ type: "UPDATE_TRIP_VIEW_PERMISSION" });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_TRIP_VIEW_PERMISSION_ERROR", err });
      });
  };
};

export const addDay = tripId => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    // Increment the numDays from the trip
    const tripRef = firestore.collection("trips").doc(tripId);
    firestore
      .runTransaction(function(transaction) {
        return transaction.get(tripRef).then(function(tripDoc) {
          if (!tripDoc.exists) {
            throw "No trip matching tripId: " + tripId;
          }

          var newEndDate = firebase.firestore.Timestamp.fromDate(
            moment
              .unix(tripDoc.get("endDate").seconds)
              .add(1, "days")
              .toDate()
          );
          transaction.update(tripRef, {
            endDate: newEndDate,
            numDays: firebase.firestore.FieldValue.increment(1),
            updatedAt: new Date(),
            dayDescriptions: [...tripDoc.get("dayDescriptions"), ""],
            lodgingList: [...tripDoc.get("lodgingList"), ""],
          });
        });
      })
      .then(() => {
        dispatch({ type: "ADD_DAY", id: tripId });
      })
      .catch(err => {
        dispatch({ type: "ADD_DAY_ERROR", err });
      });
  };
};

export const deleteTrip = tripId => {
  return async (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    // Delete the trip itself, firestore trigger deletes associated places and lists
    firestore
      .collection("trips")
      .doc(tripId)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_TRIP", id: tripId });
      })
      .catch(err => {
        dispatch({ type: "DELETE_TRIP_ERROR", err: err });
      });
  };
};

export const deleteDay = (tripId, dayIndex) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    // Delete all Places that match the current tripId and dayIndex
    const matchingPlacesQuery = firestore
      .collection("places")
      .where("tripId", "==", tripId)
      .where("dayIndex", "==", dayIndex);
    matchingPlacesQuery
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          doc.ref.update({ dayIndex: -1, itineraryPlaceIndex: null }).catch(err => {
            console.error("Error removing place", err);
          });
        });

        // Decrement the dayIndex of all places that currently have a greater dayIndex
        // (i.e., the days after the deleted day)
        const laterDayPlacesQuery = firestore
          .collection("places")
          .where("tripId", "==", tripId)
          .where("dayIndex", ">", dayIndex);
        laterDayPlacesQuery
          .get()
          .then(laterDaysSnapshot => {
            let daysBatch = firestore.batch();
            laterDaysSnapshot.forEach(doc => {
              // const currentDayIndex = doc.get("dayIndex");
              daysBatch.update(doc.ref, {
                dayIndex: firebase.firestore.FieldValue.increment(-1),
              });
            });
            daysBatch.commit().catch(err => {
              dispatch({ type: "DELETE_DAY_ERROR", err: err });
            });
          })
          .catch(err => {
            console.error("Error getting places after current dayIndex", err);
          });

        // Decrement the numDays from the trip
        const tripRef = firestore.collection("trips").doc(tripId);
        firestore
          .runTransaction(function(transaction) {
            return transaction.get(tripRef).then(function(tripDoc) {
              if (!tripDoc.exists) {
                throw "Trip does not exist!";
              }
              const newEndDate = firebase.firestore.Timestamp.fromDate(
                moment
                  .unix(tripDoc.data().endDate.seconds)
                  .subtract(1, "days")
                  .toDate()
              );
              const dayDescriptions = tripDoc.data().dayDescriptions;
              const lodgingList = tripDoc.data().lodgingList;
              // Remove the deleted day's description
              // Need this check because older trips don't have the dayDescriptions field
              if (dayDescriptions) {
                let updatedDayDescriptions = [...dayDescriptions];
                updatedDayDescriptions.splice(dayIndex, 1);
                transaction.update(tripRef, {
                  dayDescriptions: updatedDayDescriptions,
                });
              }
              // Remove the deleted day's lodging details
              // Need this check because older trips don't have the lodgingList field
              if (lodgingList) {
                let updatedLodgingList = [...lodgingList];
                updatedLodgingList.splice(dayIndex, 1);
                transaction.update(tripRef, {
                  lodgingList: updatedLodgingList,
                });
              }

              transaction.update(tripRef, {
                endDate: newEndDate,
                numDays: firebase.firestore.FieldValue.increment(-1),
                updatedAt: new Date(),
              });
            });
          })
          .then(() => {
            dispatch({ type: "DELETE_DAY", id: tripId });
          })
          .catch(err => {
            dispatch({ type: "DELETE_DAY_ERROR", err });
          });
      })
      .catch(err => {
        console.error("Error getting matching places");
        dispatch({ type: "DELETE_DAY_ERROR", err });
      });
  };
};

export const renameTrip = (tripId, tripTitle) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const docRef = firestore.collection("trips").doc(tripId);
    docRef
      .update({
        title: tripTitle,
      })
      .then(() => {
        dispatch({ type: "RENAME_TRIP", id: tripId, title: tripTitle });
      })
      .catch(err => {
        dispatch({ type: "RENAME_TRIP_ERROR", err });
      });
  };
};

export const updateDayDescription = (tripId, dayIndex, description) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();

    const tripRef = firestore.collection("trips").doc(tripId);
    firestore
      .runTransaction(function(transaction) {
        return transaction.get(tripRef).then(function(tripDoc) {
          if (!tripDoc.exists) {
            throw "Trip does not exist!";
          }
          const tripNumDays = tripDoc.data().numDays;
          if (!tripDoc.data().dayDescriptions) {
            // Trip doesn't have dayDescriptions
            let newDayDescriptions = Array(tripNumDays).fill("");
            newDayDescriptions[dayIndex] = description;
            transaction.update(tripRef, { dayDescriptions: newDayDescriptions });
          } else {
            let updatedDayDescriptions = tripDoc.data().dayDescriptions;
            if (tripNumDays > updatedDayDescriptions.length) {
              console.error("Number of days in trip is greater than length of dayDescriptions");
              updatedDayDescriptions.push(
                ...new Array(tripNumDays - updatedDayDescriptions.length).fill("")
              );
            }
            updatedDayDescriptions[dayIndex] = description;
            transaction.update(tripRef, {
              dayDescriptions: updatedDayDescriptions,
            });
          }
        });
      })
      .then(() => {
        dispatch({ type: "UPDATE_DAY_DESCRIPTION", dayIndex: dayIndex, description: description });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_DAY_DESCRIPTION_ERROR", err });
      });
  };
};

export const clearLodgingForDay = (tripId, dayIndex) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();

    const tripRef = firestore.collection("trips").doc(tripId);
    firestore
      .runTransaction(function(transaction) {
        return transaction.get(tripRef).then(function(tripDoc) {
          if (!tripDoc.exists) {
            throw "Trip does not exist!";
          }
          let updatedLodgingList = tripDoc.get("lodgingList");
          updatedLodgingList[dayIndex] = "";
          transaction.update(tripRef, {
            lodgingList: updatedLodgingList,
          });
        });
      })
      .then(() => {
        dispatch({ type: "CLEAR_LODGING_FOR_DAY", dayIndex: dayIndex });
      })
      .catch(err => {
        dispatch({ type: "CLEAR_LODGING_FOR_DAY_ERROR", err });
      });
  };
};

export const addLodgingToDates = (tripId, placeId, startDate, endDate) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();

    const tripRef = firestore.collection("trips").doc(tripId);
    firestore
      .runTransaction(function(transaction) {
        return transaction.get(tripRef).then(function(tripDoc) {
          const tripNumDays = tripDoc.get("numDays");
          const startDayIndex = startDate.diff(
            moment.unix(tripDoc.get("startDate").seconds),
            "days"
          );
          const numberOfDays = endDate.diff(startDate, "days");
          if (!tripDoc.exists) {
            throw "Trip does not exist!";
          }
          if (!tripDoc.get("lodgingList")) {
            // Trip doesn't have lodgingList
            let newLodgingsArray = Array(tripNumDays).fill("");
            for (let i = 0; i < numberOfDays; i++) {
              newLodgingsArray[startDayIndex + i] = placeId;
            }
            transaction.update(tripRef, { lodgingList: newLodgingsArray });
          } else {
            let updatedLodgings = tripDoc.get("lodgingList");
            if (tripNumDays > updatedLodgings.length) {
              console.error("Number of days in trip is greater than length of lodgings");
              updatedLodgings.push(...new Array(tripNumDays - updatedLodgings.length).fill(""));
            }
            for (let i = 0; i < numberOfDays; i++) {
              updatedLodgings[startDayIndex + i] = placeId;
            }
            transaction.update(tripRef, {
              lodgingList: updatedLodgings,
            });
          }
        });
      })
      .then(() => {
        dispatch({ type: "ADD_LODGING_TO_DATES", placeId, startDate, endDate });
      })
      .catch(err => {
        dispatch({ type: "ADD_LODGING_TO_DATES_ERROR", err });
      });
  };
};

export const updateTripNotes = (tripId, notes) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const docRef = firestore.collection("trips").doc(tripId);
    docRef
      .update({
        notes: notes,
      })
      .then(() => {
        dispatch({ type: "UPDATE_TRIP_NOTES", id: tripId, notes: notes });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_TRIP_NOTES_ERROR", err });
      });
  };
};

export const setPublicView = () => {
  return (dispatch, getState) => {
    dispatch({ type: "SET_PUBLIC" });
  };
};

export const setNonPublicView = () => {
  return (dispatch, getState) => {
    dispatch({ type: "SET_NON_PUBLIC" });
  };
};

export const showItineraryPane = () => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_ITINERARY_PANE" });
  };
};

export const hideItineraryPane = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_ITINERARY_PANE" });
  };
};

export const showItineraryMapView = () => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_ITINERARY_MAP_VIEW" });
  };
};

export const hideItineraryMapView = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_ITINERARY_MAP_VIEW" });
  };
};

export const signInAnonymouslyAndJoinAsCollaborator = (tripId, token) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    // Set up auth state changed function
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        dispatch(joinAsCollaborator(user.uid, token, tripId));
      } else {
      }
    });

    // Sign in anonymously
    firebase
      .auth()
      .signInAnonymously()
      .catch(function(err) {
        dispatch({ type: "ERROR_SIGNING_IN_ANONYMOUSLY", err });
      });
  };
};

export const checkTripAccess = tripId => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const tripDocRef = firestore.collection("trips").doc(tripId);

    tripDocRef
      .get()
      .then(tripDocSnapshot => {
        if (tripDocSnapshot.exists) {
          dispatch({ type: "ACCESS_ALLOWED" });
        } else {
          dispatch({ type: "CHECK_TRIP_ACCESS_ERROR", err: "Trip doesn't exist" });
        }
      })
      .catch(err => {
        dispatch({ type: "CHECK_TRIP_ACCESS_ERROR", err });
      });
  };
};

export const inviteUserToTrip = (email, editTripUrl, tripPhotoUrl, tripTitle, senderEmail) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const sendInviteToTripEmail = firebase.functions().httpsCallable("sendInviteToTripEmail");
    sendInviteToTripEmail({
      email: email,
      tripUrl: editTripUrl,
      tripPhotoUrl: tripPhotoUrl,
      tripTitle: tripTitle,
      senderEmail: senderEmail,
    })
      .then(() => {
        dispatch({ type: "SEND_INVITE_TO_TRIP_EMAIL" });
      })
      .catch(function(error) {
        // TODO: Propagate error back to the UI
        var code = error.code;
        var message = error.message;
        var details = error.details;
        console.error(code, message, details);
      });
  };
};

export const joinAsCollaborator = (userId, token, tripId) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    const joinAsCollaborator = firebase.functions().httpsCallable("joinAsCollaborator");
    joinAsCollaborator({
      userId: userId,
      token: token,
      tripId: tripId,
    })
      .then(() => {
        dispatch({ type: "ADD_COLLABORATOR_TO_TRIP_SUCCESS" });
      })
      .catch(function(err) {
        dispatch({ type: "ADD_COLLABORATOR_TO_TRIP_ERROR", err });
      });
  };
};

export const setTripPublic = tripId => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const docRef = firestore.collection("trips").doc(tripId);
    docRef
      .update({
        isPublic: true,
      })
      .then(() => {
        dispatch({ type: "SET_TRIP_PUBLIC", id: tripId });
      })
      .catch(err => {
        dispatch({ type: "SET_TRIP_PUBLIC_ERROR", err });
      });
  };
};

export const clearAddCollaboratorState = () => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_ADD_COLLABORATOR_STATE" });
  };
};

export const selectTab = tabName => {
  return (dispatch, getState) => {
    dispatch({ type: "SELECT_TAB", tabName });
  };
};

export const makeACopyOfTrip = (tripId, newTripTitle, newTripStartDateMillis) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const copyTripWithValidation = firebase.functions().httpsCallable("copyTripWithValidation");
    copyTripWithValidation({
      tripId: tripId,
      newTripTitle: newTripTitle,
      newTripStartDateMillis: newTripStartDateMillis,
    })
      .then(response => {
        dispatch({
          type: "LOADING_REDIRECT_DONE",
          loadingRedirectRoute: `/t/${response.data.copiedTripId}`,
        });
      })
      .catch(function(error) {
        var code = error.code;
        var message = error.message;
        var details = error.details;
        console.error(code, message, details);
        dispatch({ type: "LOADING_REDIRECT_ERROR", loadingRedirectError: error });
      });
  };
};

export const tripLoadingCompleted = () => {
  return (dispatch, getState) => {
    dispatch({ type: "TRIP_LOADING_COMPLETED" });
  };
};

export const fetchAndSetTripCreatorProStatus = tripId => {
  return (dispatch, getState, { getFirebase }) => {
    var checkTrip = getFirebase()
      .functions()
      .httpsCallable("isTripFromProSubscriber");

    return checkTrip({ tripId: tripId })
      .then(response => {
        dispatch({ type: "FETCH_AND_SET_TRIP_CREATOR_PRO_STATUS", status: response.data });
      })
      .catch(e => {
        console.error(e);
      });
  };
};

export const resetTripCreatorProStatus = () => {
  return (dispatch, getState) => {
    dispatch({ type: "RESET_TRIP_CREATOR_PRO_STATUS" });
  };
};
