import React from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";

const PasswordResetEmailSent = ({ auth }) => {
  if (auth.uid) return <Redirect to="/home" />;

  return (
    <div className="main-container fullscreen mt-7">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-6 col-md-7 text-center ">
            <div className="mb-2">
              <h2>Check your email</h2>
              <p className="lead">A link to reset your password has been sent to your email</p>
            </div>
            <Link to="/signin">
              <button className="btn btn-lg btn-outline-primary" role="button" type="submit">
                Go to sign in
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
  };
};

export default connect(mapStateToProps)(PasswordResetEmailSent);
