import React from "react";
import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signOut, fetchAndSetSubscriptionStatus } from "../store/actions/authActions";
import LoadingComponent from "./LoadingComponent";
import GenericServerError from "./error_pages/GenericServerError";

const Header = () => {
  const dispatch = useDispatch();
  const auth = useSelector(state => {
    return state.firebase.auth;
  });
  const isProUser = useSelector(state => {
    return state.auth.isProUser;
  });
  const profile = useSelector(state => {
    return state.firebase.profile;
  });
  const [isLoadingSubscriptionPortal, setIsLoadingSubscriptionPortal] = useState(false);
  const [isSubscriptionPortalError, setIsSubscriptionPortalError] = useState(false);

  useEffect(() => {
    dispatch(fetchAndSetSubscriptionStatus());
  }, []);

  const navigateToBillingPortal = async () => {
    setIsLoadingSubscriptionPortal(true);
    const getPortalLink = firebase
      .functions()
      .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
    try {
      const { data } = await getPortalLink({ returnUrl: window.location.origin });
      window.location.assign(data.url);
    } catch (error) {
      console.error("Error loading Subscription Portal", error);
      setIsLoadingSubscriptionPortal(false);
      setIsSubscriptionPortalError(true);
    }
  };

  if (isLoadingSubscriptionPortal) {
    return (
      <body>
        <LoadingComponent message="Loading subscription portal..." />
      </body>
    );
  }

  if (isSubscriptionPortalError) {
    return <GenericServerError />;
  }

  return (
    <header className="header">
      <nav className="navbar navbar-expand-sm shadow navbar-light bg-white">
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <Link to="/" className="navbar-brand py-0">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Brand%2Flogo-landing-page.png?alt=media&token=7c1e5c41-fafa-4718-ba01-cf535478e34a"
                style={{ width: 100 }}
                className="d-inline-block"
                alt="Scout logo"
              />
              {isProUser && (
                <span className="badge badge-secondary" title="greetings, globetrotter">
                  Pro
                </span>
              )}
            </Link>
          </div>
          <button
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            className="navbar-toggler navbar-toggler-right collapsed"
          >
            <i className="fa fa-bars" />
          </button>

          <div id="navbarCollapse" className="navbar-collapse collapse">
            <ul className="ml-auto navbar-nav">
              <li className="nav-item">
                <Link to="/explore" className="nav-link">
                  Explore
                </Link>
              </li>

              {auth && auth.email && profile ? (
                <>
                  {!isProUser && (
                    <li className="nav-item">
                      <Link to="/pro" className="btn btn-outline-primary ml-sm-3 mb-3 mb-sm-0">
                        Upgrade to Pro
                      </Link>
                    </li>
                  )}
                  <li className="nav-item dropdown ml-sm-3">
                    <a
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        alt="User profile"
                        src={
                          profile.photoURL
                            ? profile.photoURL
                            : "/assets/icons/profile_placeholder.png"
                        }
                        className="avatar avatar-sm avatar-border-white"
                      />
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      style={{ transform: "translate(16px, 6px)" }}
                    >
                      {auth && profile && (
                        <span className="dropdown-item disabled">{profile.name || auth.email}</span>
                      )}
                      {auth && profile && process.env.NODE_ENV === "development" && (
                        <span className="dropdown-item disabled">userID: {auth.uid}</span>
                      )}
                      {isProUser && (
                        <>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={navigateToBillingPortal}
                          >
                            Manage Subscription
                          </button>
                          <Link to="/pro" className="dropdown-item">
                            Scout Pro Benefits
                          </Link>
                        </>
                      )}
                      <div className="dropdown-divider" />
                      <a
                        href="https://forms.gle/miBpopVvQ88ACdjh7"
                        target="_blank"
                        className="dropdown-item"
                      >
                        Give Feedback 💡
                      </a>
                      {!isProUser && (
                        <a
                          href="https://www.buymeacoffee.com/ericmao"
                          target="_blank"
                          className="dropdown-item"
                        >
                          Support Scout ☕️
                        </a>
                      )}
                      <a
                        href="#"
                        onClick={() => dispatch(signOut())}
                        className="dropdown-item"
                        id="logout_button"
                      >
                        Log Out
                      </a>
                    </div>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <Link to="/about" className="nav-link">
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/pro" className="nav-link">
                      Scout Pro
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/signin" className="nav-link">
                      Sign in
                    </Link>
                  </li>
                  <li className="nav-item mt-3 mt-sm-0 ml-sm-3">
                    <Link to="/signup" className="btn btn-primary">
                      Sign up
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
