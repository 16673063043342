import React from "react";

const MiniProfileCard = ({ photoSrc, primaryText, secondaryText }) => {
  return (
    <>
      <div className="d-flex">
        {photoSrc && (
          <div className="mr-3">
            <img src={photoSrc} className="avatar" alt="Author" />
          </div>
        )}
        <div className="flex-grow-1">
          <div className="d-flex flex-column">
            <span>{primaryText}</span>
            <span className="text-muted text-sm">{secondaryText}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default MiniProfileCard;
