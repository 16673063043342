import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import moment from "moment";
import history from "../Router/history";
import CustomSingleDatePicker from "./CustomSingleDatePicker";
import { useFocus } from "../hooks/CustomHooks";
import { makeACopyOfTrip } from "../store/actions/tripActions";

const CopyTripModalContent = ({ handleClose, tripToCopyId, tripToCopyTitle }) => {
  const [copiedTripTitle, setCopiedTripTitle] = useState(
    (tripToCopyTitle && "Copy of " + tripToCopyTitle) || ""
  );
  const oneMonthFromToday = moment().add(1, "months");
  const [isCalendarFocused, setIsCalendarFocused] = useState(false);
  const [startDate, setStartDate] = useState(oneMonthFromToday);
  const [tripTitleInputRef, setTripTitleInputRefFocus] = useFocus();
  const dispatch = useDispatch();

  useEffect(() => {
    setTripTitleInputRefFocus();
  }, [copiedTripTitle, setTripTitleInputRefFocus]);

  const updateInput = e => {
    setCopiedTripTitle(e.target.value);
  };

  const handleDateChange = startDate => {
    // startDate is a Moment object in local timezones so we need
    // to convert it to UTC while maintaining the same time by
    // converting to UTC then adding the offset in
    const utcStartDate =
      startDate &&
      moment(startDate)
        .utc()
        .add(startDate.utcOffset(), "m");
    setStartDate(utcStartDate);
  };

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(makeACopyOfTrip(tripToCopyId, copiedTripTitle, startDate.valueOf()));
    history.push("/copyingTrip");
    handleClose();
  };

  return (
    <>
      <form id="rename-form" onSubmit={handleSubmit}>
        <Modal.Body>
          <button
            type="button"
            className="close btn btn-round"
            onClick={handleClose}
            aria-label="Close"
          >
            <i className="material-icons">close</i>
          </button>
          <h4 className="modal-title mb-4">Copy Trip</h4>
          <p className="text-muted">
            Save a copy of this trip to your trips. You'll be able to edit and add your own places.
          </p>
          <div className="form-group">
            <label htmlFor="tripName" className="form-label">
              Trip Name *
            </label>
            <input
              id="copiedTripTitle"
              name="copiedTripTitle"
              className="form-control"
              type="text"
              aria-describedby="copiedTripTitle"
              onChange={updateInput}
              value={copiedTripTitle}
              required={true}
              placeholder="Give it a name"
              autoComplete="off"
              ref={tripTitleInputRef}
            />
          </div>
          <div className="form-group">
            <label className="form-label">Start Date *</label>
            <CustomSingleDatePicker
              id="copyTripSingleDatePicker"
              placeholder="Start Date"
              date={startDate}
              onDateChange={handleDateChange}
              focused={isCalendarFocused}
              onFocusChange={({ focused }) => setIsCalendarFocused(focused)}
              isOutsideRange={() => false}
              required={true}
              block={true}
              numberOfMonths={1}
              hideKeyboardShortcutsPanel={true}
            />
            <small className="form-text text-muted">
              You can always change this later in trip settings.
            </small>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <button className="btn btn-outline-muted" onClick={handleClose} type="button">
            Cancel
          </button>
          <button className="btn btn-primary" type="submit">
            Copy Trip
          </button>
        </Modal.Footer>
      </form>
    </>
  );
};

export default CopyTripModalContent;
