import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { hideAddToItineraryModal } from "../store/actions/modalActions";
import { removePlaceFromItinerary, updatePlaceList } from "../store/actions/placeActions";
import { addDay, showItineraryPane } from "../store/actions/tripActions";
import { hideInfoWindow, showOverlayRoute } from "../store/actions/mapActions";
import moment from "moment";

class AddToItineraryModal extends Component {
  handleSelectDay = dayIndex => {
    const placeId = this.props.addToItineraryPlaceId;
    const currentPlace = this.props.placesMap[placeId];

    if (currentPlace && currentPlace.dayIndex !== dayIndex) {
      const sourceDayIndex = currentPlace.dayIndex;
      const sourcePlaceIndex = currentPlace.itineraryPlaceIndex;
      let newPlaceList = [];

      let sourceDayPlaces = this.props.orderedPlaces.filter(
        place => place.dayIndex === sourceDayIndex && place.itineraryPlaceIndex >= sourcePlaceIndex
      );

      // Decrement place index of places from source day that have a higher place
      // index than the moved place
      sourceDayPlaces.map(place => {
        const placeIndex = place.itineraryPlaceIndex;
        if (placeIndex > sourcePlaceIndex) {
          newPlaceList.push({ id: place.id, placeIndex: placeIndex - 1 });
        }
      });

      // Update place index of moved place
      const itineraryPlaceIndex = this.props.orderedPlaces.filter(
        place => place.dayIndex === dayIndex
      ).length;
      newPlaceList.push({ id: placeId, placeIndex: itineraryPlaceIndex });
      const context = {
        movedPlaceId: placeId,
        movedPlaceDestinationListOrDay: dayIndex,
      };

      this.props.updatePlaceList("DAY", "BETWEEN", newPlaceList, context);
      this.props.showOverlayRoute(dayIndex);

      window.analytics.track("Place Added to Itinerary", {
        placeName: currentPlace.name,
        placeId: placeId,
        tripId: currentPlace.tripId,
        listId: currentPlace.listId,
        googlePlaceId: currentPlace.googlePlaceId,
      });
    }

    this.props.hideInfoWindow();

    this.handleClose();
    this.props.showItineraryPane();
  };

  handleRemoveFromItinerary = () => {
    this.props.removePlaceFromItinerary(this.props.addToItineraryPlaceId);
    this.props.hideInfoWindow();
    this.handleClose();
  };

  handleClose = () => {
    this.props.hideAddToItineraryModal();
  };

  render() {
    let days = [];
    const { trip, placesMap, addToItineraryPlaceId } = this.props;

    if (trip) {
      const startDate = moment.unix(trip.startDate.seconds);

      for (let i = 0; i < trip.numDays; i++) {
        const isScheduled =
          addToItineraryPlaceId &&
          placesMap[addToItineraryPlaceId] &&
          placesMap[addToItineraryPlaceId].dayIndex !== null &&
          placesMap[addToItineraryPlaceId].dayIndex === i;
        days.push({ date: moment(startDate).add(i, "days"), isScheduled: isScheduled });
      }
    }
    return (
      <div>
        <Modal show={this.props.showAddToItineraryModal} onHide={this.handleClose}>
          <Modal.Body>
            <button type="button" className="close btn btn-round" onClick={this.handleClose}>
              <i className="material-icons">close</i>
            </button>
            {days && days.length > 0 && <h3 className="modal-title mb-3">Choose a day</h3>}
            <div className="d-flex flex-column align-items-center">
              {days &&
                (days.length > 0 ? (
                  [
                    days.map((day, index) => {
                      return (
                        <button
                          type="button"
                          key={day.date.utc().unix()}
                          className={"btn mb-2 w-75 btn-outline-primary"}
                          onClick={
                            day.isScheduled ? this.handleClose : () => this.handleSelectDay(index)
                          }
                        >
                          <span>
                            {day.isScheduled && <i className="fas fa-check mr-1" />}
                            {day.date.utc().format("dddd M/D")}
                          </span>
                          {trip.dayDescriptions && trip.dayDescriptions[index] && (
                            <>
                              <br />
                              <small>{trip.dayDescriptions[index]}</small>
                            </>
                          )}
                        </button>
                      );
                    }),
                    addToItineraryPlaceId &&
                      placesMap[addToItineraryPlaceId] &&
                      placesMap[addToItineraryPlaceId].dayIndex >= 0 && (
                        <a
                          key={trip.id + "removeFromItinerary"}
                          href="#"
                          onClick={this.handleRemoveFromItinerary}
                          className="text-secondary mt-2"
                        >
                          Remove from itinerary
                        </a>
                      ),
                  ]
                ) : (
                  <div className="text-center my-4">
                    <p className="text-muted">Add a day to start building your itinerary</p>
                    <button
                      className="btn btn-primary btn-block"
                      onClick={() => this.props.addDay(trip.id)}
                    >
                      Add a day
                    </button>
                  </div>
                ))}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showAddToItineraryModal: state.modals.showAddToItineraryModal,
    addToItineraryPlaceId: state.modals.addToItineraryPlaceId,
    orderedPlaces: state.firestore.ordered.places,
    placesMap: state.firestore.data.places,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideAddToItineraryModal: () => dispatch(hideAddToItineraryModal()),
    updatePlaceList: (listType, moveType, places, context) =>
      dispatch(updatePlaceList(listType, moveType, places, context)),
    addDay: tripId => dispatch(addDay(tripId)),
    hideInfoWindow: () => dispatch(hideInfoWindow()),
    showItineraryPane: () => dispatch(showItineraryPane()),
    removePlaceFromItinerary: placeId => dispatch(removePlaceFromItinerary(placeId)),
    showOverlayRoute: dayIndex => dispatch(showOverlayRoute(dayIndex)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddToItineraryModal);
