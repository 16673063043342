/* eslint-disable no-undef */

import React, { Component } from "react";
import { connect } from "react-redux";
import { hideInfoWindow, setSearchBounds } from "../store/actions/mapActions";
import MyMapComponent from "./MyMapComponent";
import SignUpBanner from "./SignUpBanner";
import BiggerThanTabletOnly from "./media_queries/BiggerThanTabletOnly";

class Map extends Component {
  constructor(props) {
    super(props);

    this.map = null;

    this.setMapRef = element => {
      this.map = element;
      this.panMapToPlaces(props.places);
    };
  }

  componentWillUnmount() {
    this.props.hideInfoWindow();
  }

  handleBoundsChanged = () => {
    this.props.setSearchBounds(this.map.getBounds());
  };

  panMapToPlaces = places => {
    if (!places || !places.length > 0 || !this.map) {
      return;
    }

    let bounds = new google.maps.LatLngBounds();
    places.forEach(place => {
      place.lat && place.lng && bounds.extend(new google.maps.LatLng(place.lat, place.lng));
    });

    // Don't zoom in too far when there's only one marker
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      var extendPoint1 = new google.maps.LatLng(
        bounds.getNorthEast().lat() + 0.01,
        bounds.getNorthEast().lng() + 0.01
      );
      var extendPoint2 = new google.maps.LatLng(
        bounds.getNorthEast().lat() - 0.01,
        bounds.getNorthEast().lng() - 0.01
      );
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }

    this.map.fitBounds(bounds);
  };

  render() {
    const { places, isPublic, mapCenter, placeSearchResults, hideInfoWindow } = this.props;
    let defaultCenter = mapCenter;
    let defaultZoom = 13;

    // If no mapCenter lat or lng, center map on Kansas City, MO so entire US is in view
    if (defaultCenter && (defaultCenter.lat === "" || defaultCenter.lng === "")) {
      defaultCenter.lat = 39.100154;
      defaultCenter.lng = -94.577972;
      defaultZoom = 4;
    }

    if (places && defaultCenter) {
      return (
        <>
          <div className="d-flex flex-column" style={{ height: "100%" }}>
            <BiggerThanTabletOnly>
              <SignUpBanner isPublic={isPublic} />
            </BiggerThanTabletOnly>
            <MyMapComponent
              containerElement={
                <div className="flex-grow-1" style={{ height: "100%", position: "relative" }} />
              }
              mapElement={<div style={{ height: `100%` }} />}
              loadingElement={<div style={{ height: "100%" }} />}
              places={places}
              placeSearchResults={placeSearchResults}
              panMapToPlaces={this.panMapToPlaces}
              defaultCenter={defaultCenter}
              defaultZoom={defaultZoom}
              hideInfoWindow={hideInfoWindow}
              mapRef={this.setMapRef}
              mapNode={this.map}
              handleBoundsChanged={this.handleBoundsChanged}
            />
          </div>
        </>
      );
    } else {
      return (
        <div>
          <h3>Loading...</h3>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.firebase.auth,
    placeSearchResults: state.places.placeSearchResults,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideInfoWindow: () => dispatch(hideInfoWindow()),
    setSearchBounds: mapBounds => dispatch(setSearchBounds(mapBounds)),
    showAuthenticatedModal: (authAction, authActionProps) =>
      dispatch(showAuthenticatedModal(authAction, authActionProps)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Map);
