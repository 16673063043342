import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { createTrip } from "../store/actions/tripActions";
import { Redirect } from "react-router-dom";
import CustomDateRangePicker from "./CustomDateRangePicker";
import moment from "moment";

class AddTripForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: props.initialStartDate,
      endDate: props.initialEndDate,
      location: "",
      city: "",
      title: "",
      lat: "",
      lng: "",
      placePhotoUrl: "",
      showPickDatePopover: false,
    };
  }

  componentDidMount = () => {
    // Declare Options For Autocomplete
    var options = { types: ["(cities)"] };

    // Initialize Google Autocomplete
    /*global google*/
    var autoCompleteInput = document.getElementById("cityInput");
    if (autoCompleteInput) {
      this.autocomplete = new google.maps.places.Autocomplete(autoCompleteInput, options);
      // Fire Event when a suggested name is selected
      this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
    }

    window.analytics.page("New Trip");
  };

  handlePlaceSelect = () => {
    // Extract City From Address Object
    let addressObject = this.autocomplete.getPlace();

    // Check if address is valid
    if (addressObject) {
      this.setState({
        // Defaulting to San Francisco if not lat/lng is obtained
        lat:
          addressObject.geometry && addressObject.geometry.location
            ? addressObject.geometry.location.lat()
            : 37.777027,
        lng:
          addressObject.geometry && addressObject.geometry.location
            ? addressObject.geometry.location.lng()
            : -122.408465,
        location: addressObject.formatted_address || addressObject.name,
        city: addressObject.vicinity,
      });
      if (this.state.title === "" && addressObject.vicinity) {
        this.setState({ title: addressObject.vicinity + " Trip" });
      }
    }
  };

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    if (!this.state.startDate || !this.state.endDate) {
      this.handleShowPickDatePopover();
    } else {
      this.props.createTrip(this.state);
    }
  };

  handleDateChange = ({ startDate, endDate }) => {
    // startDate and endDate are Moment objects in local timezones so we need
    // to convert it to UTC while maintaining the same time by converting to UTC
    // then adding the offset in
    const utcStartDate =
      startDate &&
      moment(startDate)
        .utc()
        .add(startDate.utcOffset(), "m");
    const utcEndDate =
      endDate &&
      moment(endDate)
        .utc()
        .add(endDate.utcOffset(), "m");
    this.setState({
      startDate: utcStartDate,
      endDate: utcEndDate,
    });
  };

  handleShowPickDatePopover = () => {
    this.setState({ showPickDatePopover: true });
    setTimeout(() => {
      this.setState({ showPickDatePopover: false });
    }, 3000);
  };

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;
    return (
      <div className="container-fluid px-3">
        <div className="row min-vh-100">
          <div className="col-md-8 col-lg-6 col-xl-5 d-flex align-items-center">
            <div className="w-100 py-5 px-md-5 px-xl-6 position-relative">
              <div className="mb-5">
                <h1>New Trip</h1>
              </div>
              <div>
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="cityInput">
                      Where <span className="text-muted">*</span>
                    </label>
                    <input
                      className="form-control"
                      id="cityInput"
                      name="location"
                      type="text"
                      placeholder="City (e.g., New York)"
                      autoFocus
                      autoComplete="off"
                      value={this.state.location}
                      onChange={this.updateInput}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Dates <span className="text-muted">*</span>
                    </label>
                    <div className="w-100" />
                    <OverlayTrigger
                      placement="top"
                      trigger="manual"
                      show={this.state.showPickDatePopover}
                      onHide={() => this.setState({ showPickDatePopover: false })}
                      overlay={<Tooltip>Pick a start and end date</Tooltip>}
                    >
                      <CustomDateRangePicker
                        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                        startDateId="startDate" // PropTypes.string.isRequired,
                        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                        endDateId="endDate" // PropTypes.string.isRequired,
                        onDatesChange={this.handleDateChange} // PropTypes.func.isRequired,
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                        onClose={this.handleDatepickerClosed}
                        isOutsideRange={() => false}
                        required={true}
                        block={true}
                        hideKeyboardShortcutsPanel={true}
                      />
                    </OverlayTrigger>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName">
                      Trip Name <span className="text-muted">*</span>
                    </label>
                    <input
                      className="form-control"
                      id="inputName"
                      type="text"
                      placeholder="Give it a name"
                      name="title"
                      autoComplete="off"
                      value={this.state.title}
                      onChange={this.updateInput}
                      required
                    />
                  </div>
                  <button className="btn btn-block btn-lg btn-primary" role="button" type="submit">
                    Create Trip
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-6 col-xl-7 d-none d-md-block">
            <div
              style={{
                backgroundImage:
                  "url(https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/map-laptop-planning.jpg?alt=media&token=205a1c4f-2c64-41bc-812a-cc21dfa207be)",
              }}
              className="bg-cover h-100 mr-n3"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createTrip: tripDetails => dispatch(createTrip(tripDetails)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTripForm);
