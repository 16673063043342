/* eslint-disable no-undef */
import React, { Component } from "react";
import { connect } from "react-redux";
import { showPlaceSearchResults } from "../store/actions/placeActions";
import { showSubscribeModal } from "../store/actions/modalActions";
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";
import { SCOUT_PRO_PLACE_COUNT_THRESHOLD } from "./Constants";

class PlaceSearch extends Component {
  constructor(props) {
    super(props);

    this.searchBoxRef = null;

    this.setSearchBoxRef = element => {
      this.searchBoxRef = element;
    };
  }

  handlePlacesChanged = () => {
    let placeObjects = this.searchBoxRef.getPlaces();
    let formattedPlaceList = [];
    placeObjects.map(place => {
      formattedPlaceList.push({
        name: place.name,
        address: place.formatted_address || "",
        googlePlaceId: place.place_id || "",
        lat: (place.geometry && place.geometry.location.lat()) || "",
        lng: (place.geometry && place.geometry.location.lng()) || "",
        formattedPhone: place.formatted_phone_number || "",
        phone: place.international_phone_number || "",
        rating: place.rating || "",
        totalRatings: place.user_ratings_total || "",
        priceLevel: place.price_level || "",
        website: place.website || "",
        googleMapsUrl: place.url || "",
      });
    });
    this.props.showPlaceSearchResults(formattedPlaceList);
    this.props.panMapToPlaces(formattedPlaceList);
  };

  shouldDisableInput = () => {
    return (
      this.props.places?.length > SCOUT_PRO_PLACE_COUNT_THRESHOLD &&
      !this.props.isProUser &&
      !this.props.isTripCreatorProUser
    );
  };

  onPlaceInputClick = () => {
    if (this.shouldDisableInput()) {
      this.props.showSubscribeModal();
    }
  };

  render() {
    return (
      <SearchBox
        ref={this.setSearchBoxRef}
        controlPosition={google.maps.ControlPosition.TOP_LEFT}
        onPlacesChanged={this.handlePlacesChanged}
        bounds={this.props.searchBounds}
      >
        <div style={{ marginLeft: "32px", marginTop: "20px" }}>
          <i
            className="fas fa-search fa-lg text-muted"
            style={{
              right: "4px",
              padding: "15px",
              position: "absolute",
            }}
          />
          <input
            type="text"
            className="form-control"
            id="placeSearch"
            placeholder="Search a place"
            readOnly={this.shouldDisableInput()}
            onClick={this.onPlaceInputClick}
            style={{
              width: "400px",
              zIndex: 2,
              paddingRight: "40px",
            }}
          />
        </div>
      </SearchBox>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchBounds: state.map.searchBounds,
    places: state.firestore.ordered.places,
    isProUser: state.auth.isProUser,
    isTripCreatorProUser: state.trips.isTripCreatorProUser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showPlaceSearchResults: placeSearchResults =>
      dispatch(showPlaceSearchResults(placeSearchResults)),
    showSubscribeModal: () => dispatch(showSubscribeModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceSearch);
