/* eslint-disable no-undef */
import React, { Component } from "react";
import { Marker } from "react-google-maps";
import PlaceMarkerInfoWindow from "./PlaceMarkerInfoWindow";
import { connect } from "react-redux";
import { showInfoWindow } from "../store/actions/mapActions";
import {
  getMarkerFromListIndex,
  COLOR_MARKER_MAP,
  SEARCH_MARKER_URL,
  COLOR_MARKER_ARRAY,
  MARKER_TYPES,
  ORANGE_DOT,
} from "./markerColors";

class PlaceMarker extends Component {
  render() {
    const {
      place,
      lists,
      type,
      showHighlightMarker,
      highlightMarkerId,
      index,
      showInfoWindowIndex,
      infoWindowIsOpen,
      showInfoWindow,
      uncheckedLists,
      isItineraryMapViewVisible,
      isOverlayRouteVisible,
      overlayRouteDayIndex,
    } = this.props;

    const marker = {
      size: new google.maps.Size(44, 44),
      scaledSize: new google.maps.Size(44, 44),
      labelOrigin: new google.maps.Point(17, 18),
      anchor: new google.maps.Point(20, 44),
    };

    const searchResultMarker = {
      ...marker,
      size: new google.maps.Size(24, 24),
      scaledSize: new google.maps.Size(24, 24),
      anchor: new google.maps.Point(12, 12),
      url: SEARCH_MARKER_URL,
    };

    let markerIsVisible = true;
    if (uncheckedLists.has(place.listId)) {
      markerIsVisible = false;
    }

    if (
      markerIsVisible &&
      lists &&
      place.lat &&
      place.lng &&
      place.lat !== "" &&
      place.lng !== ""
    ) {
      let iconSet =
        place?.listId &&
        lists[place.listId] &&
        lists[place.listId].color &&
        lists[place.listId].color in COLOR_MARKER_MAP &&
        COLOR_MARKER_MAP[lists[place.listId].color];

      // Default to red dot marker
      let icon = COLOR_MARKER_MAP.red.dotMarker;
      if (type === "SEARCH_RESULT") {
        icon = searchResultMarker;
      } else if (type === "POI") {
        icon = dotMarkerColors[0];
      } else if (type === "LIST") {
        if (lists[place.listId] && lists[place.listId].listIndex !== undefined) {
          if (
            (isOverlayRouteVisible && overlayRouteDayIndex === place.dayIndex) ||
            (place.dayIndex >= 0 && isItineraryMapViewVisible)
          ) {
            icon = COLOR_MARKER_ARRAY[place.dayIndex % COLOR_MARKER_ARRAY.length].filledMarker;
          } else {
            if (isItineraryMapViewVisible) {
              icon = ORANGE_DOT;
            } else if (place.types && place.types.includes("food")) {
              icon = iconSet
                ? iconSet.foodMarker
                : getMarkerFromListIndex(lists[place.listId].listIndex, MARKER_TYPES.FOOD);
            } else if (
              (place.types && place.types.includes("lodging")) ||
              (place.notes && place.notes.toLowerCase().includes("airbnb")) ||
              (place.title && place.title.toLowerCase().includes("airbnb"))
            ) {
              icon = iconSet
                ? iconSet.bedMarker
                : getMarkerFromListIndex(lists[place.listId].listIndex, MARKER_TYPES.BED);
            } else {
              icon = iconSet
                ? iconSet.dotMarker
                : getMarkerFromListIndex(lists[place.listId].listIndex, MARKER_TYPES.DOT);
            }
          }
        } else {
          // If all else false, choose a random colored marker for that list
          icon = getMarkerFromListIndex(place.listId.charCodeAt(0), MARKER_TYPES.DOT);
        }
      }

      if (showHighlightMarker && highlightMarkerId === place.id) {
        icon = {
          ...icon,
          size: new google.maps.Size(54, 54),
          scaledSize: new google.maps.Size(54, 54),
          labelOrigin: new google.maps.Point(18, 18),
          anchor: new google.maps.Point(24, 52),
        };
      }

      let zIndex = null;
      if (showHighlightMarker && highlightMarkerId === place.id) {
        zIndex = 999;
      } else if (type === "POI") {
        zIndex = 998;
      } else if (type === "SEARCH_RESULT") {
        zIndex = 997;
      }
      return (
        <Marker
          position={{ lat: place.lat, lng: place.lng }}
          label={
            (showHighlightMarker && highlightMarkerId === place.id) || type === "SEARCH_RESULT"
              ? null
              : isOverlayRouteVisible && overlayRouteDayIndex === place.dayIndex
              ? {
                  color: "white",
                  fontWeight: "bold",
                  text: (place.itineraryPlaceIndex + 1).toString(),
                }
              : place.dayIndex >= 0 && isItineraryMapViewVisible
              ? {
                  color: "white",
                  fontWeight: "bold",
                  text: (place.dayIndex + 1).toString(),
                }
              : null
          }
          key={index}
          zIndex={zIndex}
          title={place.title}
          icon={icon}
          onClick={() => {
            showInfoWindow(index);
          }}
        >
          {infoWindowIsOpen && showInfoWindowIndex === index && (
            <PlaceMarkerInfoWindow place={place} type={type} />
          )}
        </Marker>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => {
  return {
    showHighlightMarker: state.places.showHighlightMarker,
    highlightMarkerId: state.places.highlightMarkerId,
    infoWindowIsOpen: state.map.infoWindowIsOpen,
    showInfoWindowIndex: state.map.showInfoWindowIndex,
    lists: state.firestore.data.lists,
    uncheckedLists: state.lists.uncheckedLists,
    isItineraryMapViewVisible: state.trips.isItineraryMapViewVisible,
    isOverlayRouteVisible: state.map.isOverlayRouteVisible,
    overlayRouteDayIndex: state.map.overlayRouteDayIndex,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showInfoWindow: index => dispatch(showInfoWindow(index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceMarker);
