import React from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import moment from "moment";
import { hidePlaceTimeModal } from "../store/actions/modalActions";
import { setTime } from "../store/actions/placeActions";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

class PlaceTimeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startTime: null,
      endTime: null,
      showEndTime: false,
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.setTime(this.props.placeId, this.state.startTime, this.state.endTime);
    this.handleClose();
  };

  handleExited = () => {
    this.setState({ startTime: null, endTime: null, showEndTime: false });
  };

  handleClose = () => {
    this.props.hidePlaceTimeModal();
  };

  handleEnter = () => {
    // Prepopulate the state for the time pickers based on start and end times of the place
    if (this.props.placeId && this.props.placesMap) {
      const currentPlace = this.props.placesMap[this.props.placeId];
      if (currentPlace) {
        if (currentPlace.startTime) {
          const startTime = moment.unix(currentPlace.startTime.seconds);
          this.setState({
            startTime: startTime.utc().subtract(startTime.utcOffset(), "m"),
          });
        }
        if (currentPlace.endTime) {
          const endTime = moment.unix(currentPlace.endTime.seconds);
          this.setState({
            endTime: endTime.utc().subtract(endTime.utcOffset(), "m"),
          });
        }
      } else {
        console.error("Could not fetch the place with id: ", this.props.placeId);
      }
    }
  };

  handleStartTimeChange = newStartTime => {
    if (!newStartTime) {
      // Clear both start and end time if start time is removed
      // Otherwise we get into weird cases of trying to propagate the
      // time difference forward
      this.setState({ startTime: null, endTime: null });
      return;
    }
    const newStartTimeUtc = newStartTime && newStartTime.parseZone();
    const oldStartTime = this.state.startTime;
    this.setState({ startTime: newStartTimeUtc });
    if (this.state.endTime && newStartTimeUtc && oldStartTime) {
      // Set the end time automatically to the same time difference away as before
      // if there were valid previous times, and a valid new start time.
      const existingTimeDifference = this.state.endTime - oldStartTime;
      this.handleEndTimeChange(newStartTimeUtc.clone().add(existingTimeDifference));
    }
  };

  handleEndTimeChange = time => {
    const utcTime = time && time.parseZone();
    this.setState({ endTime: utcTime });
  };

  handleShowEndTime = () => {
    // Default end time to start time plus one hour
    const defaultEndTime = this.state.startTime && this.state.startTime.clone().add(1, "hours");
    this.setState({
      showEndTime: true,
      endTime: defaultEndTime,
    });
  };

  render() {
    return (
      <Modal
        id="task-modal"
        show={this.props.showPlaceTimeModal}
        onHide={this.handleClose}
        onExited={this.handleExited}
        onEnter={this.handleEnter}
      >
        <form id="rename-form" onSubmit={this.handleSubmit}>
          <Modal.Body>
            <button
              type="button"
              className="close btn btn-round"
              onClick={this.handleClose}
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </button>
            <h3 className="modal-title mb-4">Set a time</h3>
            <TimePicker
              value={this.state.startTime}
              onChange={this.handleStartTimeChange}
              placeholder={"Start time"}
              minuteStep={5}
              showSecond={false}
              use12Hours={true}
            />
            {this.state.showEndTime || this.state.endTime ? (
              <>
                {" - "}
                <TimePicker
                  value={this.state.endTime}
                  onChange={this.handleEndTimeChange}
                  placeholder={"End time"}
                  minuteStep={5}
                  showSecond={false}
                  use12Hours={true}
                />
              </>
            ) : (
              <a href="#" className="ml-2 text-secondary" onClick={this.handleShowEndTime}>
                Add end time
              </a>
            )}
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <button className="btn btn-outline-muted" onClick={this.handleClose} type="button">
              Cancel
            </button>
            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    placeId: state.modals.placeTimeModalPlaceId,
    placesMap: state.firestore.data.places,
    showPlaceTimeModal: state.modals.showPlaceTimeModal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hidePlaceTimeModal: () => dispatch(hidePlaceTimeModal()),
    setTime: (placeId, startTime, endTime) => dispatch(setTime(placeId, startTime, endTime)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceTimeModal);
