import React from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { hideSubscribeModal } from "../store/actions/modalActions";
import { Link } from "react-router-dom";
import { SCOUT_PRO_PLACE_COUNT_THRESHOLD } from "./Constants";

const SubscribeModal = () => {
  const dispatch = useDispatch();
  const shouldShowSubscribeModal = useSelector(state => state.modals.shouldShowSubscribeModal);
  const auth = useSelector(state => state.firebase.auth);

  const handleClose = () => {
    dispatch(hideSubscribeModal());
  };

  const handleEntered = () => {
    window.analytics.page("Subscribe Modal");
  };

  const handleSubscribeClick = () => {
    window.analytics.track("Button Clicked", {
      buttonType: "Subscribe",
      pageType: "Subscribe Modal",
    });
  };

  const handleLearnMoreClick = () => {
    window.analytics.track("Button Clicked", {
      buttonType: "Learn More",
      pageType: "Subscribe Modal",
    });
  };
  return (
    <Modal
      id="subscribe-modal"
      show={shouldShowSubscribeModal}
      onHide={handleClose}
      onEntered={handleEntered}
    >
      <Modal.Body>
        <button
          type="button"
          className="close btn btn-round"
          onClick={handleClose}
          aria-label="Close"
        >
          <i className="material-icons">close</i>
        </button>
        <div className="text-center align-items-center">
          <p className="text-primary subtitle">Go Pro</p>
          <h3 className="modal-title">Upgrade to Add More</h3>
          <img
            src="/assets/img/illustrations/undraw_explore_re_8l4v.svg"
            style={{ width: "400px" }}
            className="img-fluid my-5"
            alt="Person looking at mountains, tree, and sun"
          />
          <p className="text-muted">
            Subscribe to Scout Pro to add more than {SCOUT_PRO_PLACE_COUNT_THRESHOLD} places and
            also get awesome trip planning features.{" "}
            <Link
              to="/pro"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleLearnMoreClick}
            >
              Learn More
            </Link>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer align-items-center">
        {(!auth.uid || auth.isAnonymous) && (
          <Link className="mr-auto" to={"/signin"} target="_blank" rel="noopener noreferrer">
            Sign In
          </Link>
        )}
        <Link
          className="btn btn-primary"
          to="/pro"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleSubscribeClick}
        >
          Subscribe
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default SubscribeModal;
