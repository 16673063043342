import React from "react";
import Modal from "react-bootstrap/Modal";

class RenameForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.rename(this.props.id, this.state.title);
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ title: "" });
    this.props.hideRenameForm();
  };

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleEntered = () => {
    if (this.props.title) {
      this.setState({
        title: this.props.title,
      });
    }
    // Set focus to the title field
    this.titleInput.focus();
  };

  render() {
    return (
      <Modal
        id="task-modal"
        show={this.props.showRenameModal}
        onHide={this.handleClose}
        onEntered={this.handleEntered}
      >
        <form id="rename-form" onSubmit={this.handleSubmit}>
          <Modal.Body>
            <button
              type="button"
              className="close btn btn-round"
              onClick={this.handleClose}
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </button>
            <h4 className="modal-title mb-4">{this.props.modalTitle}</h4>
            <div className="form-group">
              <input
                className="form-control col"
                type="text"
                id="listTitle"
                placeholder={this.props.placeholder}
                name="title"
                onChange={this.updateInput}
                value={this.state.title}
                autoComplete="off"
                ref={input => {
                  this.titleInput = input;
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

export default RenameForm;
