export const createList = (tripId, listDetails) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const userId = getState().firebase.auth.uid;
    var newList = {
      title: listDetails.title,
      listIndex: listDetails.listIndex,
      tripId: tripId,
      userId: userId,
    };
    firestore
      .collection("lists")
      .add(newList)
      .then(doc => {
        dispatch({ type: "CREATE_LIST", title: listDetails.title, id: doc.id });
      })
      .catch(err => {
        dispatch({ type: "CREATE_LIST_ERROR", err: err });
      });
  };
};

export const deleteList = (listId, tripId) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();

    // Delete all Places that match the current listId
    const matchingPlaces = firestore
      .collection("places")
      .where("listId", "==", listId)
      .where("tripId", "==", tripId);
    matchingPlaces
      .get()
      .then(querySnapshot => {
        let deletes = [];
        querySnapshot.forEach(doc => {
          deletes.push(
            doc.ref.delete().catch(err => {
              console.error("Error deleting place", err);
            })
          );
        });

        Promise.all(deletes)
          .then(() => {
            // All places scheduled to a day
            const itineraryPlacesQuery = firestore
              .collection("places")
              .where("tripId", "==", tripId)
              .where("dayIndex", ">=", 0);
            itineraryPlacesQuery.get().then(querySnapshot => {
              // Group the places by days
              let groupedItineraryPlaces = [];
              querySnapshot.forEach(docSnapshot => {
                const currDayIndex = docSnapshot.get("dayIndex");
                if (typeof groupedItineraryPlaces[currDayIndex] !== "undefined") {
                  groupedItineraryPlaces[currDayIndex].push(docSnapshot);
                } else {
                  groupedItineraryPlaces[currDayIndex] = [docSnapshot];
                }
              });
              // Sort places by itineraryPlaceIndex
              groupedItineraryPlaces.forEach(dayList => {
                dayList.sort(function(a, b) {
                  var x = a.get("itineraryPlaceIndex");
                  var y = b.get("itineraryPlaceIndex");
                  return x < y ? -1 : x > y ? 1 : 0;
                });
              });
              // Set the itineraryPlaceIndex of each place to the index in the sorted places map
              groupedItineraryPlaces.forEach(dayList => {
                dayList.forEach((place, index) => {
                  place.ref.update({ itineraryPlaceIndex: index });
                });
              });
            });
          })
          .catch(err => {
            console.error("Error deleting places");
          });
      })
      .catch(err => {
        console.error("Error querying places");
      });

    // Delete the list
    firestore
      .collection("lists")
      .doc(listId)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_LIST", id: listId });
      })
      .catch(err => {
        dispatch({ type: "DELETE_LIST_ERROR", err: err });
      });
  };
};

export const updateListOptions = (listId, listName, listColor) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const docRef = firestore.collection("lists").doc(listId);
    docRef
      .update({
        title: listName,
        color: listColor,
      })
      .then(() => {
        dispatch({ type: "UPDATE_LIST", id: listId, title: listName, color: listColor });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_LIST_ERROR", err });
      });
  };
};

export const setListOrder = lists => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    var batch = firestore.batch();

    lists.forEach((list, index) => {
      const docRef = firestore.collection("lists").doc(list.id);
      batch.update(docRef, { listIndex: index });
    });
    batch
      .commit()
      .then(() => {
        dispatch({ type: "SET_LIST_ORDER", numLists: lists.length });
      })
      .catch(err => {
        dispatch({ type: "SET_LIST_ORDER_ERROR", err });
      });
  };
};

export const setListChecked = listId => {
  return (dispatch, getState) => {
    dispatch({ type: "SET_LIST_CHECKED", listId: listId });
  };
};

export const setListUnchecked = listId => {
  return (dispatch, getState) => {
    dispatch({ type: "SET_LIST_UNCHECKED", listId: listId });
  };
};

export const clearUncheckedLists = () => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_UNCHECKED_LISTS" });
  };
};
