/* eslint-disable no-undef */

export const MARKER_TYPES = Object.freeze({
  FILLED: "FILLED",
  DOT: "DOT",
  FOOD: "FOOD",
  BED: "BED",
});

// Marker image from: https://www.svgrepo.com/svg/394267/map-marker
// Edited using www.editor.method.ac
export const RED_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Filled%20Markers%2Ffilled_marker_red.svg?alt=media&token=313896db-0024-42fc-8ceb-4f7ab7a47656";
export const ORANGE_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Filled%20Markers%2Ffilled_marker_orange.svg?alt=media&token=fe78da2c-d1e2-4dcc-8fd3-f781340a96ab";
export const YELLOW_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Filled%20Markers%2Ffilled_marker_yellow.svg?alt=media&token=b6055669-3a47-47b2-bec7-67f834beb33f";
export const OLIVE_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Filled%20Markers%2Ffilled_marker_olive.svg?alt=media&token=83f16bf4-ad38-4dc3-be17-bbaa01075484";
export const GREEN_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Filled%20Markers%2Ffilled_marker_green.svg?alt=media&token=018d8ba6-4eab-4f3e-bf58-ea0f43a8e27f";
export const TEAL_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Filled%20Markers%2Ffilled_marker_teal.svg?alt=media&token=2ee7591f-b270-4f03-ae1d-75884e803556";
export const BLUE_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Filled%20Markers%2Ffilled_marker_blue.svg?alt=media&token=b433d0ba-d80c-448f-a185-8480bd1811db";
export const VIOLET_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Filled%20Markers%2Ffilled_marker_violet.svg?alt=media&token=c4401d5c-1120-40f7-bcb1-7145a9209804";
export const PURPLE_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Filled%20Markers%2Ffilled_marker_purple.svg?alt=media&token=8438b646-3ac5-4cfb-9e34-06847195dfea";
export const PINK_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Filled%20Markers%2Ffilled_marker_pink.svg?alt=media&token=9c961f39-9726-4a18-8560-01896bb55ff5";
export const GRAY_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Filled%20Markers%2Ffilled_marker_gray.svg?alt=media&token=8359f1fd-7432-4e60-998b-9ecdc665d530";

// Dot markers
export const RED_DOT_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Dot%20Markers%2Fdot_marker_red.svg?alt=media&token=75ac809a-abd6-4628-b888-2f7c98bcc102";
export const ORANGE_DOT_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Dot%20Markers%2Fdot_marker_orange.svg?alt=media&token=ead740ef-64a4-45c9-9971-6e3fcbc18b32";
export const YELLOW_DOT_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Dot%20Markers%2Fdot_marker_yellow.svg?alt=media&token=6734b43e-c931-4556-97f3-5fa4e14d8207";
export const OLIVE_DOT_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Dot%20Markers%2Fdot_marker_olive.svg?alt=media&token=40707352-5de5-4512-916f-c5dff73be395";
export const GREEN_DOT_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Dot%20Markers%2Fdot_marker_green.svg?alt=media&token=aeff7fe6-9815-4f49-907a-fc9c904aaf5c";
export const TEAL_DOT_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Dot%20Markers%2Fdot_marker_teal.svg?alt=media&token=f4e2044b-ea5c-46e9-a4a0-657474f579e7";
export const BLUE_DOT_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Dot%20Markers%2Fdot_marker_blue.svg?alt=media&token=647d1c74-d45c-4926-837f-f8ef4d4e6ba5";
export const VIOLET_DOT_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Dot%20Markers%2Fdot_marker_violet.svg?alt=media&token=8a9976f2-680e-4673-bed9-82d1f76fb7b8";
export const PURPLE_DOT_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Dot%20Markers%2Fdot_marker_purple.svg?alt=media&token=7d7acdbd-0a9d-44f9-be23-73562fa98a14";
export const PINK_DOT_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Dot%20Markers%2Fdot_marker_pink.svg?alt=media&token=d700e126-6be4-4f8d-a0d6-d14f035e9598";
export const GRAY_DOT_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Dot%20Markers%2Fdot_marker_gray.svg?alt=media&token=f374766a-0ccf-4b65-8875-019d826363ae";

// Food markers
export const RED_FOOD_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Food%20Markers%2Ffood_marker_red.svg?alt=media&token=eaa9a1f4-bb30-4d54-a1a9-5d440c304ae5";
export const ORANGE_FOOD_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Food%20Markers%2Ffood_marker_orange.svg?alt=media&token=644d5b1b-41f5-4ddd-b90a-846ca333dc19";
export const YELLOW_FOOD_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Food%20Markers%2Ffood_marker_yellow.svg?alt=media&token=cc1de4d8-a0f6-4771-b014-3ab71687b862";
export const OLIVE_FOOD_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Food%20Markers%2Ffood_marker_olive.svg?alt=media&token=06b094ee-7a6c-451f-b610-c0b8b2c4935d";
export const GREEN_FOOD_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Food%20Markers%2Ffood_marker_green.svg?alt=media&token=95335b45-5f29-4cc1-89bc-1463d344d0b7";
export const TEAL_FOOD_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Food%20Markers%2Ffood_marker_teal.svg?alt=media&token=8f455869-6cfe-45df-8df8-498b7154f944";
export const BLUE_FOOD_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Food%20Markers%2Ffood_marker_blue.svg?alt=media&token=9c1b6cd1-782f-441f-b716-93cc9d168ec2";
export const VIOLET_FOOD_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Food%20Markers%2Ffood_marker_violet.svg?alt=media&token=f2453893-14f1-4089-9a12-ee3cfb51cca0";
export const PURPLE_FOOD_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Food%20Markers%2Ffood_marker_purple.svg?alt=media&token=f969a048-3d1f-4a14-8ebc-2b920e32f260";
export const PINK_FOOD_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Food%20Markers%2Ffood_marker_pink.svg?alt=media&token=4f26bd8c-355e-4224-99d1-4c55c9fd3dca";
export const GRAY_FOOD_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Food%20Markers%2Ffood_marker_gray.svg?alt=media&token=2d0eaf51-b3af-4dd4-bed2-2b336d8301fd";

// Bed markers
// Icon source: https://www.iconfinder.com/icons/352502/hotel_local_icon
export const RED_BED_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Bed%20Markers%2Fbed_marker_red.svg?alt=media&token=12d5fa6f-99fb-4210-b379-2c6160344d76";
export const ORANGE_BED_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Bed%20Markers%2Fbed_marker_orange.svg?alt=media&token=6d69ab1f-38b7-4384-b64f-8db00c375235";
export const YELLOW_BED_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Bed%20Markers%2Fbed_marker_yellow.svg?alt=media&token=0c3d6cbf-e83c-4a7e-8669-e8ca6c7fea71";
export const OLIVE_BED_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Bed%20Markers%2Fbed_marker_olive.svg?alt=media&token=2e4ce22d-0677-4829-b593-42ac72573034";
export const GREEN_BED_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Bed%20Markers%2Fbed_marker_green.svg?alt=media&token=adb3bede-fe00-4b22-8df5-c7766d76f218";
export const TEAL_BED_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Bed%20Markers%2Fbed_marker_teal.svg?alt=media&token=7ac69987-d292-421d-b850-beefca8accba";
export const BLUE_BED_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Bed%20Markers%2Fbed_marker_blue.svg?alt=media&token=b1d258f7-4cf1-4db5-9791-9c62149a1bc1";
export const VIOLET_BED_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Bed%20Markers%2Fbed_marker_violet.svg?alt=media&token=771ef971-80c8-4825-89b4-152b486d445a";
export const PURPLE_BED_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Bed%20Markers%2Fbed_marker_purple.svg?alt=media&token=1268277d-b9eb-4b00-9664-33c0d50f6b2a";
export const PINK_BED_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Bed%20Markers%2Fbed_marker_pink.svg?alt=media&token=2b8e8355-4da2-495f-9205-aba32b2416de";
export const GRAY_BED_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Bed%20Markers%2Fbed_marker_gray.svg?alt=media&token=d9f1aa1e-42ee-4fee-91e6-5870e5a4bdbe";

// Marker image from: https://www.iconfinder.com/icons/531897/explore_find_magnifier_search_seo_view_zoom_icon
export const SEARCH_MARKER_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/yellow_search_marker_128.png?alt=media&token=3ac368e2-ad77-4ad6-9cd5-2f6204247323";

// Orange dot
export const ORANGE_DOT_URL =
  "https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/orange_dot_64.png?alt=media&token=be7ddbf8-822a-4883-ba52-c6bf176c5ccf";

export const marker = {
  size: new google.maps.Size(44, 44),
  scaledSize: new google.maps.Size(44, 44),
  labelOrigin: new google.maps.Point(17, 18),
  anchor: new google.maps.Point(20, 44),
};

export const ORANGE_DOT = {
  size: new google.maps.Size(16, 16),
  scaledSize: new google.maps.Size(16, 16),
  labelOrigin: new google.maps.Point(8, 8),
  anchor: new google.maps.Point(8, 8),
  url: ORANGE_DOT_URL,
};

export const COLOR_MARKER_MAP = {
  red: {
    name: "red",
    hexColor: "#DB2828",
    filledMarker: { ...marker, url: RED_MARKER_URL },
    dotMarker: { ...marker, url: RED_DOT_MARKER_URL },
    foodMarker: { ...marker, url: RED_FOOD_MARKER_URL },
    bedMarker: { ...marker, url: RED_BED_MARKER_URL },
  },
  orange: {
    name: "orange",
    hexColor: "#F2711C",
    filledMarker: { ...marker, url: ORANGE_MARKER_URL },
    dotMarker: { ...marker, url: ORANGE_DOT_MARKER_URL },
    foodMarker: { ...marker, url: ORANGE_FOOD_MARKER_URL },
    bedMarker: { ...marker, url: ORANGE_BED_MARKER_URL },
  },
  yellow: {
    name: "yellow",
    hexColor: "#FBBD08",
    filledMarker: { ...marker, url: YELLOW_MARKER_URL },
    dotMarker: { ...marker, url: YELLOW_DOT_MARKER_URL },
    foodMarker: { ...marker, url: YELLOW_FOOD_MARKER_URL },
    bedMarker: { ...marker, url: YELLOW_BED_MARKER_URL },
  },
  olive: {
    name: "olive",
    hexColor: "#B5CC18",
    filledMarker: { ...marker, url: OLIVE_MARKER_URL },
    dotMarker: { ...marker, url: OLIVE_DOT_MARKER_URL },
    foodMarker: { ...marker, url: OLIVE_FOOD_MARKER_URL },
    bedMarker: { ...marker, url: OLIVE_BED_MARKER_URL },
  },
  green: {
    name: "green",
    hexColor: "#21BA45",
    filledMarker: { ...marker, url: GREEN_MARKER_URL },
    dotMarker: { ...marker, url: GREEN_DOT_MARKER_URL },
    foodMarker: { ...marker, url: GREEN_FOOD_MARKER_URL },
    bedMarker: { ...marker, url: GREEN_BED_MARKER_URL },
  },
  teal: {
    name: "teal",
    hexColor: "#00B5AD",
    filledMarker: { ...marker, url: TEAL_MARKER_URL },
    dotMarker: { ...marker, url: TEAL_DOT_MARKER_URL },
    foodMarker: { ...marker, url: TEAL_FOOD_MARKER_URL },
    bedMarker: { ...marker, url: TEAL_BED_MARKER_URL },
  },
  blue: {
    name: "blue",
    hexColor: "#2185D0",
    filledMarker: { ...marker, url: BLUE_MARKER_URL },
    dotMarker: { ...marker, url: BLUE_DOT_MARKER_URL },
    foodMarker: { ...marker, url: BLUE_FOOD_MARKER_URL },
    bedMarker: { ...marker, url: BLUE_BED_MARKER_URL },
  },
  violet: {
    name: "violet",
    hexColor: "#6435C9",
    filledMarker: { ...marker, url: VIOLET_MARKER_URL },
    dotMarker: { ...marker, url: VIOLET_DOT_MARKER_URL },
    foodMarker: { ...marker, url: VIOLET_FOOD_MARKER_URL },
    bedMarker: { ...marker, url: VIOLET_BED_MARKER_URL },
  },
  purple: {
    name: "purple",
    hexColor: "#A333C8",
    filledMarker: { ...marker, url: PURPLE_MARKER_URL },
    dotMarker: { ...marker, url: PURPLE_DOT_MARKER_URL },
    foodMarker: { ...marker, url: PURPLE_FOOD_MARKER_URL },
    bedMarker: { ...marker, url: PURPLE_BED_MARKER_URL },
  },
  pink: {
    name: "pink",
    hexColor: "#E03997",
    filledMarker: { ...marker, url: PINK_MARKER_URL },
    dotMarker: { ...marker, url: PINK_DOT_MARKER_URL },
    foodMarker: { ...marker, url: PINK_FOOD_MARKER_URL },
    bedMarker: { ...marker, url: PINK_BED_MARKER_URL },
  },
  gray: {
    name: "gray",
    hexColor: "#80868C",
    filledMarker: { ...marker, url: GRAY_MARKER_URL },
    dotMarker: { ...marker, url: GRAY_DOT_MARKER_URL },
    foodMarker: { ...marker, url: GRAY_FOOD_MARKER_URL },
    bedMarker: { ...marker, url: GRAY_BED_MARKER_URL },
  },
};

// Make sure the length of this stays odd so that the default list colors will be alternating
// See getMarkerFromListIndex() for more details
export const COLOR_MARKER_ARRAY = [
  COLOR_MARKER_MAP.red,
  COLOR_MARKER_MAP.orange,
  COLOR_MARKER_MAP.yellow,
  COLOR_MARKER_MAP.olive,
  COLOR_MARKER_MAP.green,
  COLOR_MARKER_MAP.teal,
  COLOR_MARKER_MAP.blue,
  COLOR_MARKER_MAP.violet,
  COLOR_MARKER_MAP.purple,
  COLOR_MARKER_MAP.pink,
  COLOR_MARKER_MAP.gray,
];

/**
 * Return a Google Map marker object with a color based on list index and desired marker type.
 * Defaults to returning red dot marker if there's a problem
 *
 * @param {number} listIndex Index of the list
 * @param {string} markerType Type of marker such as "DOT", "FOOD". Must be one of MARKER_TYPES
 * @returns {Object} Google Map marker object
 */
export const getMarkerFromListIndex = (listIndex, markerType) => {
  // Cycles through every other color for example index 0, 2, 4, 6, 1, 3, 5, 0. Make sure
  // COLOR_MARKER_ARRAY has an odd number of values so that it will cycle through
  // all of them instead of only the even indices
  let colorObj = COLOR_MARKER_ARRAY[(listIndex * 2) % Object.keys(COLOR_MARKER_ARRAY).length];

  // Default the color marker to red dot marker
  let colorMarker = COLOR_MARKER_MAP.red.dotMarker;

  switch (markerType) {
    case MARKER_TYPES.FILLED:
      colorMarker = colorObj.filledMarker;
      break;
    case MARKER_TYPES.DOT:
      colorMarker = colorObj.dotMarker;
      break;
    case MARKER_TYPES.FOOD:
      colorMarker = colorObj.foodMarker;
      break;
    case MARKER_TYPES.BED:
      colorMarker = colorObj.bedMarker;
      break;
    default:
      colorMarker = colorObj.dotMarker;
  }
  return colorMarker;
};
