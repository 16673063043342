// This file is for co-locating constants used throughout the codebase.

export const MAX_WIDTH_OF_TABLET = 991;
export const MIN_WIDTH_OF_DESKTOP = 992;

// Base URL for Scout
export const DOMAIN_BASE = "https://scoutexplore.com";

// List of trip ids that are displayed on the Explore page
export const EXPLORE_TRIP_IDS = [
  "1AmEEncvJnfVsXNpDz5i", // Jess Tran's Seattle Trip
  "kSFpssvKy1bTvKf3xa3O", // Yang's Austin
  "MwW5dPMHuxKPxs8Wz3x9", // Reggie Lo's Taiwan Trip
  "gK0o0D86o022yoKCC9EM", // Kristen's Zion Trip
  "edtoUodkmo3fI2n8DYpM", // Jess Tran's Mendocino Trip
  "H8Am25D0veTtG18Z7kGB", // Monterey and Big Sur
  "pBPoD9Xh2XaMGXywCDJX", // Berkeley Food Tour
  "upjRB8SayN47oGLxI7QB", // Eric's Sonoma/Napa trip
  "j06MootHoaN1dwTd8KvK", // Kristen's Japan+Kyoto Trip
  "UzkC8syBuDesSAjHFBmX", // New York
  "FL7SnYmN6BtskU4e37l8", // Reggie Lo's HK and Macau Trip
  "UgCPljprqONB0RPhdUBb", // Maui
  "XtTi623rE11nCoIKoEDV", // Taipei
  "9IM4JMmJUBJrlagBtnjz", // Portland
  "qyRXPgL0RzkUGazAMIta", // Kristen Lee's Vancouver
  "sh3mOzSxpWGeqLPxuxll", // Vivian Han's Santa Barbara Trip
  "lBv6EtnH5RzPczSj399O", // Colombia
  "HIzVSl0Qw28vMVFf9oj5", // Japan
  "wD96ppIhkHsNHW1ZVmm4", // Mexico City
];
Object.freeze(EXPLORE_TRIP_IDS);

// Enum that represents different options for a trip's "viewPermission" setting
// which controls who is allowed to view / discover a particular trip.
// This is meant to eventually replace the "isPublic" field currently found on
// trip documents, which was originally added in order to allow for shared public
// links to be accessible by other users.
//
// This enum will combine with the existing isPublic field that we have on trips today as so:
// TRIP_VIEW_PERMISSION.PRIVATE and isPublic=false : New trips start out like this. No one can view it but self
// TRIP_VIEW_PERMISSION.PRIVATE and isPublic=true : Self view is allowed, and isPublic gets set when share "view only" is toggled. No way to undo isPublic
// TRIP_VIEW_PERMISSION.PUBLIC and isPublic=false : Should not be allowed to get into this state today since we'll automatically toggle isPublic to true when TRIP_VIEW_PERMISSION is set to true.
// TRIP_VIEW_PERMISSION.PUBLIC and isPublic=true : Means the trip is discoverable and also share links work.
//
// In the future we could migrate the two of these into TRIP_VIEW_PERMISSION only by adding a new enum value shared
// which semantically represents that only people who have a share link should be able to view the trip.
//
// NOTE: Make sure this is in sync with the Constants.js in the cloud functions module
export const TRIP_VIEW_PERMISSION = {
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE",
};
Object.freeze(TRIP_VIEW_PERMISSION);

// Number of photos to fetch for a place from Google when it's added or clicked on as a POI
export const NUM_PHOTOS_TO_FETCH_FOR_PLACE = 5;

// Countries where Yelp is supported. We will show the Yelp link in the infoWindow if the place is in these countries
export const YELP_SUPPORTED_COUNTRIES = ["United States", "France", "Mexico", "Canada"];
Object.freeze(YELP_SUPPORTED_COUNTRIES);

// Countries where Tabelog, a popular food review site in Japan, is supported. We will show the Tabelog link
// in the infoWindow if the place is in these countries
export const TABELOG_SUPPORTED_COUNTRIES = ["Japan"];
Object.freeze(TABELOG_SUPPORTED_COUNTRIES);

// Threshold for the number of places a free user can add before needing to be subscribed to Pro
export const SCOUT_PRO_PLACE_COUNT_THRESHOLD = 30;

// Stripe subscription price IDs
export const MONTHLY_SUB_PRICE_ID = "price_1NnqsfHUlZ0Z84wzNSq5P9Pj";
export const ANNUAL_SUB_PRICE_ID = "price_1NnqKEHUlZ0Z84wz4Rvo8rhf";

// Prices for different billing periods
// NOTE: MAKE SURE THIS IS IN SYNC WITH WHAT'S SET ON STRIPE
export const MONTHLY_PRICE = 7; // Price per month in $dollars if billed monthly
export const ANNUAL_PRICE = 36; // Price per year in $dollars if billed annually
