import firebase from "firebase/compat/app";

/**
 * Calls a cloud function that will fetch photos from Google Places API
 * using the given place's googlePlaceId, and store those resolved photo
 * urls back on the place document itself.
 *
 * Error logs to console if there is an error calling the cloud function.
 */
export const fetchAndWritePlacePhotos = async (placeId, numToFetch) => {
  const fetchAndWritePlacePhotosCallable = firebase
    .functions()
    .httpsCallable("fetchAndWritePlacePhotos");
  fetchAndWritePlacePhotosCallable({
    placeId: placeId,
    numToFetch: numToFetch,
  }).catch(e => {
    console.error(`Error fetching and writing place photos: `, e);
  });
};
