import React, { Component } from "react";
import { connect } from "react-redux";
import ListView from "./ListView";
import {
  joinAsCollaborator,
  signInAnonymouslyAndJoinAsCollaborator,
  clearAddCollaboratorState,
} from "../store/actions/tripActions";
import LoadingComponent from "./LoadingComponent";
import GenericServerError from "./error_pages/GenericServerError";
import TripDoesNotExistError from "./error_pages/TripDoesNotExistError";

class CollabTripView extends Component {
  constructor(props) {
    super(props);
    const tripId = props.match.params.tripId;
    const params = new URLSearchParams(props.location.search);
    const token = params.get("t"); // get token from URL query param
    if (props.auth.uid) {
      props.joinAsCollaborator(props.auth.uid, token, tripId);
    } else {
      props.signInAnonymouslyAndJoinAsCollaborator(tripId, token);
    }
  }

  componentWillUnmount() {
    this.props.clearAddCollaboratorState();
  }

  render() {
    const { addCollaboratorResponse, signInAnonymouslyError } = this.props;

    if (signInAnonymouslyError) {
      console.error("Error signing in anonymously");
      return <GenericServerError />;
    }

    if (!addCollaboratorResponse) {
      return (
        <body>
          <LoadingComponent message="Opening Trip..." />
        </body>
      );
    }

    const { isUserAddedAsCollaborator, errorAddingCollaborator } = addCollaboratorResponse;

    if (errorAddingCollaborator) {
      console.error("Error adding as collaborator: ", errorAddingCollaborator);
      return <TripDoesNotExistError />;
    } else if (isUserAddedAsCollaborator) {
      return <ListView match={this.props.match} type="collab" isPublic={false} />;
    }

    // Render generic error page? Should not get here
    console.error("Nothing to render for collab trip view");
    return <GenericServerError />;
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    addCollaboratorResponse: state.trips.addCollaboratorResponse,
    signInAnonymouslyError: state.trips.signInAnonymouslyError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signInAnonymouslyAndJoinAsCollaborator: (tripId, token) =>
      dispatch(signInAnonymouslyAndJoinAsCollaborator(tripId, token)),
    joinAsCollaborator: (userId, token, tripId) =>
      dispatch(joinAsCollaborator(userId, token, tripId)),
    clearAddCollaboratorState: () => dispatch(clearAddCollaboratorState()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollabTripView);
