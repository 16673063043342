import React, { Component } from "react";
import { selectTab } from "../store/actions/tripActions";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";

export const TABS = {
  LISTS: "LISTS",
  MAP: "MAP",
  ITINERARY: "ITINERARY",
  HOME: "HOME",
};
Object.freeze(TABS);

class MobileNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = { activeTab: TABS.LISTS };
    this.props.selectTab(TABS.LISTS);
  }

  componentWillUnmount() {
    this.onTabSelected(null);
  }

  onTabSelected = tabName => {
    this.setState({
      activeTab: tabName,
    });
    this.props.selectTab(tabName);
  };

  onHomeSelected = () => {
    return <Redirect to="/home" />;
  };

  render() {
    /*
    TODO (emao): remove this after 4/14/20. No longer need this Instagram hack since IG might
                 fixed their browser. Leaving this in to make sure this change doesn't break anything.
                 
    const userAgent = window?.navigator?.userAgent || "";
    const isInstagramInAppBrowseriOS =
      userAgent.indexOf("Instagram") > -1 && userAgent.indexOf("iOS") > -1;
    */

    return (
      <nav
        className="navbar fixed-bottom navbar-light bg-white"
        style={{
          padding: 0,
          borderTop: "1px solid #DEE2E6",
        }}
      >
        <ul
          className="mobile-navbar navbar-nav col-12 d-flex flex-row align-items-center justify-content-around"
          role="tablist"
        >
          <li className={"nav-item"}>
            <a
              className={
                "nav-link text-center" + (this.props.activeTab === TABS.LISTS ? " active" : "")
              }
              href="#"
              onClick={() => this.onTabSelected(TABS.LISTS)}
            >
              <i className="fas fa-list-ul fa-lg" />
              <br />
              <span className="text-xs">Lists</span>
            </a>
          </li>
          {(this.props.hasPlaceInItinerary || !this.props.isPublic) && (
            <li className={"nav-item"}>
              <a
                className={
                  "nav-link text-center" +
                  (this.props.activeTab === TABS.ITINERARY ? " active" : "")
                }
                href="#"
                onClick={() => this.onTabSelected(TABS.ITINERARY)}
              >
                <i className="far fa-calendar-alt fa-lg" />
                <br />
                <span className="text-xs">Itinerary</span>
              </a>
            </li>
          )}
          <li className={"nav-item"}>
            <a
              className={
                "nav-link text-center" + (this.props.activeTab === TABS.MAP ? " active" : "")
              }
              href="#"
              onClick={() => this.onTabSelected(TABS.MAP)}
            >
              <i className="far fa-map fa-lg" />
              <br />
              <span className="text-xs">Map</span>
            </a>
          </li>
          <li className={"nav-item"}>
            <Link to={"/home"} className="nav-link align-items-center text-center">
              <i className="fas fa-home fa-lg" />
              <br />
              <span className="text-xs">Home</span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeTab: state.trips.selectedTab,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectTab: tabName => dispatch(selectTab(tabName)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileNavBar);
