const initState = {
  uncheckedLists: new Set(),
};

const listReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_LIST":
      return state;
    case "CREATE_LIST_ERROR":
      console.error("Error creating list:", action.err);
      return state;
    case "UPDATE_LIST":
      return state;
    case "UPDATE_LIST_ERROR":
      console.error("Error renaming list:", action.err);
      return state;
    case "DELETE_LIST":
      return state;
    case "DELETE_LIST_ERROR":
      console.error("Error deleting list:", action.err);
      return state;
    case "SET_LIST_ORDER":
      return state;
    case "SET_LIST_ORDER_ERROR":
      console.error("Error setting new list order:", action.err);
      return state;
    case "SET_LIST_CHECKED":
      var uncheckedListSetCopy = new Set(state.uncheckedLists);
      uncheckedListSetCopy.delete(action.listId);
      return {
        ...state,
        uncheckedLists: uncheckedListSetCopy,
      };
    case "SET_LIST_UNCHECKED":
      var uncheckedListSetCopy = new Set(state.uncheckedLists);
      uncheckedListSetCopy.add(action.listId);
      return {
        ...state,
        uncheckedLists: uncheckedListSetCopy,
      };
    case "CLEAR_UNCHECKED_LISTS":
      return {
        ...state,
        uncheckedLists: new Set(),
      };
    default:
      return state;
  }
};
export default listReducer;
