const initState = {
  showHighlightMarker: false,
  highlightMarkerId: null,
  placeSearchResults: [],
  poiPlaceDetails: {},
  temporaryPlacePhotos: [],
};

const placeReducer = (state = initState, action) => {
  switch (action.type) {
    case "ADD_PLACE":
      return state;
    case "ADD_PLACE_ERROR":
      console.error("Add place error", action.err);
      return state;
    case "UPDATE_PLACE":
      return state;
    case "UPDATE_PLACE_ERROR":
      console.error("Error updating place", action.err);
      return state;
    case "DELETE_PLACE":
      return state;
    case "DELETE_PLACE_ERROR":
      console.error("Error deleting place", action.err);
      return state;
    case "SET_TIME":
      return state;
    case "SET_TIME_ERROR":
      console.error("Error setting time for place", action.err);
      return state;
    case "DECREMENT_PLACE_ERROR":
      console.error("Error decrementing place", action.err);
      return state;
    case "REMOVE_PLACE_FROM_ITINERARY":
      return state;
    case "REMOVE_PLACE_FROM_ITINERARY_ERROR":
      console.error("Error removing place from itinerary (setting dayIndex to -1)", action.err);
      return state;
    case "UPDATE_PLACE_LIST":
      return state;
    case "UPDATE_PLACE_LIST_ERROR":
      console.error("Error updating place list - listType invalid:", action.listType);
      return state;
    case "SET_POI_INFO":
      return {
        ...state,
        poiPlaceDetails: action.poiPlaceDetails,
      };
    case "CLEAR_POI_INFO":
      return {
        ...state,
        poiPlaceDetails: {},
      };
    case "SHOW_HIGHLIGHT_MARKER":
      return {
        ...state,
        showHighlightMarker: true,
        highlightMarkerId: action.id,
      };
    case "HIDE_HIGHLIGHT_MARKER":
      return {
        ...state,
        showHighlightMarker: false,
        highlightMarkerId: null,
      };
    case "SHOW_PLACE_SEARCH_RESULTS":
      return {
        ...state,
        placeSearchResults: action.placeSearchResults,
      };
    case "HIDE_PLACE_SEARCH_RESULTS":
      return {
        ...state,
        placeSearchResults: [],
      };
    case "HIDE_SPECIFIC_PLACE_SEARCH_RESULT":
      return {
        ...state,
        placeSearchResults: state.placeSearchResults.filter(
          place => place.googlePlaceId !== action.googlePlaceId
        ),
      };
    case "TEMPORARY_PHOTOS_AVAILABLE":
      return {
        ...state,
        temporaryPlacePhotos: action.temporaryPlacePhotos,
      };
    case "TEMPORARY_PHOTOS_ERROR":
      console.error("Error fetching temporary photos from Google Places:", action.err);
      return {
        ...state,
      };
    case "CLEAR_TEMPORARY_PHOTOS":
      return {
        ...state,
        temporaryPlacePhotos: [],
      };
    case "STAR_PLACE":
      return state;
    case "STAR_PLACE_ERROR":
      console.error("Error starring place", action.err);
      return state;
    case "UNSTAR_PLACE":
      return state;
    case "UNSTAR_PLACE_ERROR":
      console.error("Error starring place", action.err);
      return state;
    default:
      return state;
  }
};

export default placeReducer;
