export const showAddListForm = () => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_ADD_LIST_FORM" });
  };
};

export const hideAddListForm = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_ADD_LIST_FORM" });
  };
};

export const showRenameListForm = (renameListId, renameListTitle, renameListColor) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SHOW_RENAME_LIST_FORM",
      renameListId: renameListId,
      renameListTitle: renameListTitle,
      renameListColor: renameListColor,
    });
  };
};

export const hideRenameListForm = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_RENAME_LIST_FORM" });
  };
};

export const showRenameTripForm = () => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_RENAME_TRIP_FORM" });
  };
};

export const hideRenameTripForm = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_RENAME_TRIP_FORM" });
  };
};

export const showDeleteTripConfirmationModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_DELETE_TRIP_CONFIRMATION_MODAL" });
  };
};

export const hideDeleteTripConfirmationModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_DELETE_TRIP_CONFIRMATION_MODAL" });
  };
};

export const showDeleteListConfirmationModal = listId => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_DELETE_LIST_CONFIRMATION_MODAL", listId: listId });
  };
};

export const hideDeleteListConfirmationModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_DELETE_LIST_CONFIRMATION_MODAL" });
  };
};

export const showComingSoonModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_COMING_SOON_MODAL" });
  };
};

export const hideComingSoonModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_COMING_SOON_MODAL" });
  };
};

export const showShareTripModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_SHARE_TRIP_MODAL" });
  };
};

export const hideShareTripModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_SHARE_TRIP_MODAL" });
  };
};

export const showReorderListsModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_REORDER_LIST_MODAL" });
  };
};

export const hideReorderListsModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_REORDER_LIST_MODAL" });
  };
};

export const showAddToItineraryModal = place => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_ADD_TO_ITINERARY_MODAL", place: place });
  };
};

export const hideAddToItineraryModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_ADD_TO_ITINERARY_MODAL" });
  };
};

export const showDayInfoModal = dayIndex => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_DAY_INFO_MODAL", dayIndex: dayIndex });
  };
};

export const hideDayInfoModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_DAY_INFO_MODAL" });
  };
};

export const showGetViewOnlyLink = () => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_GET_VIEW_ONLY_LINK" });
  };
};

export const hideGetViewOnlyLink = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_GET_VIEW_ONLY_LINK" });
  };
};

export const showTripSettingsModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_TRIP_SETTINGS_MODAL" });
  };
};

export const hideTripSettingsModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_TRIP_SETTINGS_MODAL" });
  };
};

export const showPublishingSettingsModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_PUBLISH_SETTINGS_MODAL" });
  };
};

export const hidePublishingSettingsModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_PUBLISH_SETTINGS_MODAL" });
  };
};

export const showPublishTripModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_PUBLISH_TRIP_MODAL" });
  };
};

export const hidePublishTripModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_PUBLISH_TRIP_MODAL" });
  };
};

export const showAddLodgingModal = placeId => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_ADD_LODGING_MODAL", placeId: placeId });
  };
};

export const hideAddLodgingModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_ADD_LODGING_MODAL" });
  };
};

export const showPlaceTimeModal = placeId => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_PLACE_TIME_MODAL", placeId: placeId });
  };
};

export const hidePlaceTimeModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_PLACE_TIME_MODAL" });
  };
};

export const showPlaceDetailsModal = placeId => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_PLACE_DETAILS_MODAL", placeId: placeId });
  };
};

export const hidePlaceDetailsModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_PLACE_DETAILS_MODAL" });
  };
};

export const showGalleryModal = placeId => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_GALLERY_MODAL", placeId: placeId });
  };
};

export const hideGalleryModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_GALLERY_MODAL" });
  };
};

export const showListPickerModal = placeId => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_LIST_PICKER_MODAL", placeId: placeId });
  };
};

export const hideListPickerModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_LIST_PICKER_MODAL" });
  };
};

export const showAuthenticatedModal = (authAction, authActionProps) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SHOW_AUTHENTICATED_MODAL",
      authAction: authAction,
      authActionProps: authActionProps,
    });
  };
};

export const hideAuthenticatedModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_AUTHENTICATED_MODAL" });
  };
};

export const showSubscribeModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_SUBSCRIBE_MODAL" });
  };
};

export const hideSubscribeModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_SUBSCRIBE_MODAL" });
  };
};
