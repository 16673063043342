import React from "react";
import { TRIP_VIEW_PERMISSION } from "./Constants";

const TripPermissionIcon = ({ viewPermission }) => {
  if (viewPermission === TRIP_VIEW_PERMISSION.PUBLIC) {
    return (
      <span className="text-sm text-muted">
        <i className="fas fa-globe-americas mr-2" />
        Public
      </span>
    );
  } else if (viewPermission === TRIP_VIEW_PERMISSION.PRIVATE) {
    return (
      <span className="text-sm text-muted">
        <i className="fas fa-lock mr-2" />
        Private
      </span>
    );
  } else if (!viewPermission) {
    // If no viewPermission is set, show "Private"
    return (
      <span className="text-sm text-muted">
        <i className="fas fa-lock mr-2" />
        Private
      </span>
    );
  }
  return null;
};

export default TripPermissionIcon;
