import React, { Component } from "react";
import { connect } from "react-redux";
import {
  signIn,
  signUpWithGoogle,
  signUpWithFacebook,
  onSigninUnmounted,
} from "../../store/actions/authActions";
import { Link } from "react-router-dom";

class SignIn extends Component {
  state = {
    email: "",
    password: "",
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.signIn(this.state);
  };

  componentWillUnmount() {
    this.props.onSigninUnmounted();
  }

  render() {
    const { authError, isModalContent, handleCloseModal, onSignupClick } = this.props;

    return (
      <div className="text-center">
        {isModalContent ? (
          <>
            <div className="mb-5">
              <div className="text-left justify-content-between d-flex">
                <h2>Welcome back</h2>
                <button
                  type="button"
                  className="close btn btn-round"
                  onClick={handleCloseModal}
                  aria-label="Close"
                >
                  <i className="material-icons">close</i>
                </button>
              </div>
              {authError ? (
                <div className="alert alert-danger" role="alert">
                  {authError}
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <>
            <h2 className="mb-5">Welcome Back</h2>
            {authError ? (
              <div className="alert alert-danger" role="alert">
                {authError}
              </div>
            ) : null}
          </>
        )}
        <button
          className="btn text-white btn-block d-flex align-items-center mb-3"
          onClick={this.props.signUpWithFacebook}
          style={{ backgroundColor: "#3975EA", letterSpacing: ".15em" }}
        >
          <img
            alt="Facebook logo"
            src="/assets/icons/logo-fb.png"
            className="rounded mr-2"
            style={{ width: "26px" }}
          />
          <span className="flex-grow-1">Continue with Facebook</span>
        </button>
        <button
          className="btn btn-outline-muted btn-block d-flex align-items-center"
          onClick={this.props.signUpWithGoogle}
          style={{ letterSpacing: ".15em" }}
        >
          <img alt="Google logo" src="/assets/icons/logo-google.svg" className="rounded mr-2" />
          <span className="flex-grow-1">Continue with Google</span>
        </button>
        <hr data-content="OR" className="my-3 hr-text letter-spacing-2" />
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <input
              className="form-control"
              id="email"
              type="email"
              placeholder="Email Address"
              name="login-email"
              autoComplete="off"
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              id="password"
              type="password"
              placeholder="Password"
              name="login-password"
              autoComplete="off"
              onChange={this.handleChange}
            />
            <div className="text-right">
              <small>
                <Link to="/forgot_password">Forgot password?</Link>
              </small>
            </div>
          </div>
          <button className="btn btn-lg btn-block btn-primary mb-3" role="button" type="submit">
            Sign in
          </button>
        </form>

        <p className="text-center text-muted text-sm">
          Don't have an account yet?{" "}
          <Link to="/signup" onClick={onSignupClick}>
            Create one
          </Link>
        </p>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds)),
    signUpWithGoogle: () => dispatch(signUpWithGoogle()),
    signUpWithFacebook: () => dispatch(signUpWithFacebook()),
    onSigninUnmounted: () => dispatch(onSigninUnmounted()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
