import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteList } from "../store/actions/listActions";
import { hideDeleteListConfirmationModal } from "../store/actions/modalActions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

export class DeleteListConfirmationModal extends Component {
  handleDelete = () => {
    this.handleClose();
    this.props.deleteList(this.props.deleteListId, this.props.tripId);
  };

  handleClose = () => {
    this.props.hideDeleteListConfirmationModal();
  };

  render() {
    return (
      <DeleteConfirmationModal
        handleDelete={this.handleDelete}
        handleClose={this.handleClose}
        deleteButtonMessage={"Delete List"}
        body={"All places in the list will be deleted."}
        title={"Delete List?"}
        showDeleteConfirmation={this.props.showDeleteListConfirmationModal}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteList: (listId, tripId) => dispatch(deleteList(listId, tripId)),
    hideDeleteListConfirmationModal: () => dispatch(hideDeleteListConfirmationModal()),
  };
};

const mapStateToProps = state => {
  return {
    showDeleteListConfirmationModal: state.modals.showDeleteListConfirmationModal,
    deleteListId: state.modals.deleteListId,
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DeleteListConfirmationModal);
