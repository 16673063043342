import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { actionTypes } from "redux-firestore";
import moment from "moment";
import { Link } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";
import MobileNavBar from "./MobileNavBar";
import { TABS } from "./MobileNavBar";
import TabletOrSmallerOnly from "./media_queries/TabletOrSmallerOnly";
import BiggerThanTabletOnly from "./media_queries/BiggerThanTabletOnly";
import {
  showDeleteTripConfirmationModal,
  showRenameListForm,
  showRenameTripForm,
  showAuthenticatedModal,
  showComingSoonModal,
  showShareTripModal,
  showTripSettingsModal,
  showAddLodgingModal,
  showAddListForm,
} from "../store/actions/modalActions";
import { signOut, fetchAndSetSubscriptionStatus } from "../store/actions/authActions";
import { updatePlaceList } from "../store/actions/placeActions";
import { clearUncheckedLists } from "../store/actions/listActions";
import { showOverlayRoute, hideOverlayRoute } from "../store/actions/mapActions";
import {
  setPublicView,
  setNonPublicView,
  showItineraryPane,
  hideItineraryPane,
  hideItineraryMapView,
  fetchAndSetTripCreatorProStatus,
  resetTripCreatorProStatus,
} from "../store/actions/tripActions";
import Map from "./Map";
import AuthenticatedModal, { AUTH_ACTIONS } from "./AuthenticatedModal";
import DeleteTripConfirmationModal from "./DeleteTripConfirmationModal";
import DeleteListConfirmationModal from "./DeleteListConfirmationModal";
import ShareTripModal from "./ShareTripModal";
import DayInfoModal from "./DayInfoModal";
import AddToItineraryModal from "./AddToItineraryModal";
import AddLodgingModal from "./AddLodgingModal";
import PlaceTimeModal from "./PlaceTimeModal";
import ReorderListsModal from "./ReorderListsModal";
import GalleryModal from "./GalleryModal";
import ListPickerModal from "./ListPickerModal";
import AddListForm from "./AddListForm";
import RenameListForm from "./RenameListForm";
import RenameTripForm from "./RenameTripForm";
import TripSettings from "./TripSettings";
import PublishingSettingsModal from "./PublishingSettingsModal";
import PublishTripModal from "./PublishTripModal";
import Itinerary from "./Itinerary.js";
import TripNotes from "./TripNotes";
import PlaceList from "./PlaceList";
import SignUpBanner from "./SignUpBanner";
import { fetchPublicUserData } from "../api/UsersAPI";
import MiniProfileCard from "./MiniProfileCard";
import { incrementTripViews } from "../api/TripsAPI";
import TripPermissionIcon from "./TripPermissionIcon";
import PublishTripButton from "./PublishTripButton";
import PlaceDetailsModal from "./PlaceDetailsModal";
import SubscribeModal from "./SubscribeModal";
import { chooseAuthorPhotoToShow, chooseAuthorToShow } from "../utils/TripsUtil";

class ListView extends Component {
  constructor(props) {
    super(props);
    this.state = { isPlaceListDropDisabled: false, tripOwnerUserData: null };
    if (props.isPublic) {
      props.setPublicView();
    }
  }

  componentWillUnmount() {
    // Need to clear Firestore Redux state because <Map> is constructed when Places are loaded.
    // If we don't clear and you switch trips, places will be loaded and <Map> constructed prematurely.
    this.props.clearFirestoreExceptTrips();
    this.props.setNonPublicView();
    this.props.hideItineraryPane();
    this.props.hideItineraryMapView();
    this.props.hideOverlayRoute();
    this.props.clearUncheckedLists();
    this.props.resetTripCreatorProStatus();
    window.removeEventListener("error", this.handleWindowResizeObserverError);
  }

  componentDidMount() {
    window.analytics.identify(this.props.auth.uid);
    this.props.fetchAndSetSubscriptionStatus();
    this.props.fetchAndSetTripCreatorProStatus(this.props.match.params.tripId);
    window.addEventListener("error", this.handleWindowResizeObserverError);
  }

  // ResizeObserver loop error caused when opening the place photo gallery can
  // safely be ignored according to:
  // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
  // This suppresses the React error dialog in dev. It won't appear in Prod either

  handleWindowResizeObserverError = e => {
    if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
      const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
      const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
      if (resizeObserverErr) {
        resizeObserverErr.setAttribute("style", "display: none");
      }
      if (resizeObserverErrDiv) {
        resizeObserverErrDiv.setAttribute("style", "display: none");
      }
    }
  };

  getPageType = (isPublic, auth, trip) => {
    if (isPublic) {
      return "view";
    } else if (!(auth && auth.uid)) {
      // Track how often we don't have auth prop available
      return "unknown";
    } else if (auth.uid === trip.userId) {
      return "owner";
    } else {
      return "collab";
    }
  };

  isCurrentTimeDuringTripDates = trip => {
    // We need to subtract / add 12 hours to the start and end dates respectively since
    // they are stored with noon (12:00) UTC as the time of date, so in order to properly capture
    // that 8am on start date is "during" that trip, we need the extra 12 hours on each end.
    const tripStartDateMinusTwelveHours =
      trip?.startDate && moment.unix(trip?.startDate.seconds).subtract(12, "hours");
    const tripEndDatePlusTwelveHours =
      trip?.endDate && moment.unix(trip?.endDate.seconds).add(12, "hours");
    let now = moment();
    // We need to add the offset to the current time in order to compare properly, since
    // the start/end dates are in UTC time so in order to do the comparison properly,
    // we need to go back/forward in time to get the current time as UTC. For example
    // if it's 1PM PST right now, we need to use 1PM UTC to do the comparison so adding
    // the offset gives us 5AM PST (which is 1PM UTC).
    const nowWithOffset = now.add(now.utcOffset(), "m");
    return (
      tripStartDateMinusTwelveHours &&
      tripEndDatePlusTwelveHours &&
      nowWithOffset.isBetween(tripStartDateMinusTwelveHours, tripEndDatePlusTwelveHours)
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.trip) {
      if (this.props.trip?.userId !== prevProps.trip?.userId) {
        // Fetch the user data of the trip owner once the trip data is available
        fetchPublicUserData(this.props.trip.userId).then(userObj => {
          this.setState({ tripOwnerUserData: userObj });
        });
      }
      // Only set window title, do page analytics logging, and trip view counting the first time
      // the trip is defined and it previously was undefined
      if (!prevProps.trip) {
        const { trip, isPublic, auth } = this.props;
        document.title = trip && trip.title ? `${trip.title} - Scout` : "Scout";
        let customPageProps = {
          type: this.getPageType(isPublic, auth, trip),
          tripTitle: trip.title,
          tripLocation: trip.location,
          tripId: trip.id,
          isViewedDuringTrip: this.isCurrentTimeDuringTripDates(trip),
        };
        window.analytics.page("Trip", customPageProps);
        if (isPublic) {
          // Only count trip views for public page views
          incrementTripViews(this.props.trip.id);
        }
      }
    }
  }

  handleShowDeleteTripConfirmation = () => {
    this.props.showDeleteTripConfirmationModal();
  };

  toggleItineraryPane = () => {
    if (this.props.isItineraryPaneVisible) {
      this.props.hideItineraryPane();
    } else {
      this.props.showItineraryPane();
    }
  };

  handleDragStart = start => {
    const dragObjectType = isNaN(+start.source.droppableId) ? "LIST" : "DAY";
    if (dragObjectType === "DAY") {
      this.setState({ isPlaceListDropDisabled: true });
    } else {
      this.setState({ isPlaceListDropDisabled: false });
    }
  };

  shouldShowItinerary = (selectedTab, isItineraryPaneVisible) => {
    if (selectedTab) {
      return selectedTab === TABS.ITINERARY;
    }
    return isItineraryPaneVisible;
  };

  shouldShowMap = selectedTab => {
    if (selectedTab) {
      return selectedTab === TABS.MAP;
    }
    return true;
  };

  shouldShowLists = selectedTab => {
    if (selectedTab) {
      return selectedTab === TABS.LISTS;
    }
    return true;
  };

  handleDragEnd = result => {
    // For reordering drag-n-drop
    const { destination, source, draggableId } = result;
    const { trip, places, updatePlaceList, listsMap } = this.props;

    this.setState({ isPlaceListDropDisabled: false });

    if (!destination) {
      return;
    }

    const sourceListType = isNaN(+source.droppableId) ? "LIST" : "DAY";
    const destinationListType = isNaN(+destination.droppableId) ? "LIST" : "DAY";

    const startColumn = source.droppableId;
    const endColumn = destination.droppableId;

    let mutatedList = [];

    const notMove =
      destination.index === source.index && destination.droppableId === source.droppableId;

    if (notMove) {
      return;
    }

    // Moving a place within a day or list
    if (startColumn === endColumn) {
      let filteredPlaces = [];
      const moveTop = destination.index < source.index;
      const moveDown = destination.index > source.index;

      if (destinationListType === "LIST") {
        filteredPlaces = places.filter(place => place.listId === startColumn);
      } else if (destinationListType === "DAY") {
        filteredPlaces = places.filter(place => String(place.dayIndex) === startColumn);
      } else {
        console.error("Unexpected Droppable destinationListType:", destinationListType);
      }
      filteredPlaces.map((place, i) => {
        const placeIndex =
          destinationListType === "DAY" ? place.itineraryPlaceIndex : place.placeIndex;

        if (placeIndex === source.index) {
          mutatedList.push({ id: place.id, placeIndex: destination.index });
        }
        if (moveDown && placeIndex <= destination.index && placeIndex > source.index) {
          mutatedList.push({ id: place.id, placeIndex: placeIndex - 1 });
        }
        if (moveTop && placeIndex >= destination.index && placeIndex < source.index) {
          mutatedList.push({ id: place.id, placeIndex: placeIndex + 1 });
        }
      });

      updatePlaceList(destinationListType, "WITHIN", mutatedList);
      return;
    } else if (sourceListType === destinationListType) {
      // Moving a place between days or lists
      let sourceFilteredPlaces = [];
      let movedPlaceId = null;
      let movedPlaceDestinationListOrDay = null;

      if (destinationListType === "LIST") {
        sourceFilteredPlaces = places.filter(place => place.listId === startColumn);
      } else if (destinationListType === "DAY") {
        sourceFilteredPlaces = places.filter(place => String(place.dayIndex) === startColumn);
      } else {
        console.error("Unexpected Droppable destinationListType:", destinationListType);
      }

      // Update place index for source day index
      sourceFilteredPlaces.map((place, i) => {
        const placeIndex =
          destinationListType === "DAY" ? place.itineraryPlaceIndex : place.placeIndex;
        if (placeIndex === source.index) {
          mutatedList.push({ id: place.id, placeIndex: destination.index });
          movedPlaceId = place.id;

          if (destinationListType === "DAY") {
            // New day index
            movedPlaceDestinationListOrDay = parseInt(endColumn);
          } else {
            // New listId
            movedPlaceDestinationListOrDay = endColumn;
          }
        }

        if (placeIndex > source.index) {
          mutatedList.push({ id: place.id, placeIndex: placeIndex - 1 });
        }
      });

      // Update place index for destination day index
      let destinationFilteredPlaces = [];

      if (destinationListType === "LIST") {
        destinationFilteredPlaces = places.filter(place => place.listId === endColumn);
      } else if (destinationListType === "DAY") {
        destinationFilteredPlaces = places.filter(place => {
          return String(place.dayIndex) === endColumn;
        });
      } else {
        console.error("Unexpected Droppable destinationListType");
      }
      destinationFilteredPlaces.map((place, i) => {
        const placeIndex =
          destinationListType === "DAY" ? place.itineraryPlaceIndex : place.placeIndex;

        if (placeIndex >= destination.index) {
          mutatedList.push({ id: place.id, placeIndex: placeIndex + 1 });
        }
      });
      const context = {
        movedPlaceId: movedPlaceId,
        movedPlaceDestinationListOrDay: movedPlaceDestinationListOrDay,
      };
      updatePlaceList(destinationListType, "BETWEEN", mutatedList, context);
      return;
    } else if (sourceListType === "LIST" && destinationListType === "DAY") {
      // Moving from List to Day

      const sourcePlace = places.filter(place => place.id === draggableId)[0];

      window.analytics.track("Place Added to Itinerary", {
        placeName: sourcePlace.name,
        placeId: sourcePlace.id,
        tripId: sourcePlace.tripId,
        listId: sourcePlace.listId,
        googlePlaceId: sourcePlace.googlePlaceId,
      });

      if (
        // Only show add lodging modal if there's more than one day in the trip
        trip?.numDays > 1 &&
        ((sourcePlace.types && sourcePlace.types.includes("lodging")) ||
          (sourcePlace.notes && sourcePlace.notes.toLowerCase().includes("airbnb")) ||
          (sourcePlace.title && sourcePlace.title.toLowerCase().includes("airbnb")) ||
          (listsMap &&
            sourcePlace.listId &&
            listsMap[sourcePlace.listId] &&
            listsMap[sourcePlace.listId].title &&
            listsMap[sourcePlace.listId].title.toLowerCase().includes("accommodations")))
      ) {
        this.props.showAddLodgingModal(sourcePlace.id);
      }

      // Dragged place is already scheduled to a day
      if (sourcePlace.dayIndex !== null && sourcePlace.dayIndex >= 0) {
        const sourceFilteredPlaces = places.filter(
          place => place.dayIndex === sourcePlace.dayIndex
        );

        // Moving a list item that is already scheduled to the same day
        if (sourcePlace.dayIndex === Number(destination.droppableId)) {
          sourceFilteredPlaces.map((place, i) => {
            const placeIndex = place.itineraryPlaceIndex;

            if (placeIndex === sourcePlace.itineraryPlaceIndex) {
              if (destination.index > sourcePlace.itineraryPlaceIndex) {
                // Moving the place after the original location in the same day
                // Need to -1 to the index to cancel out the original location's index
                mutatedList.push({ id: place.id, placeIndex: destination.index - 1 });
              } else {
                mutatedList.push({ id: place.id, placeIndex: destination.index });
              }
            }
            // Moving place down
            if (
              destination.index > sourcePlace.itineraryPlaceIndex &&
              placeIndex < destination.index &&
              placeIndex > sourcePlace.itineraryPlaceIndex
            ) {
              mutatedList.push({ id: place.id, placeIndex: placeIndex - 1 });
            }
            // Moving place up
            if (
              destination.index < sourcePlace.itineraryPlaceIndex &&
              placeIndex >= destination.index &&
              placeIndex < sourcePlace.itineraryPlaceIndex
            ) {
              mutatedList.push({ id: place.id, placeIndex: placeIndex + 1 });
            }
          });
          updatePlaceList(destinationListType, "WITHIN", mutatedList);
          return;
        } else {
          // Decrement itineraryPlaceIndex of all places with higher itineraryPlaceIndex
          sourceFilteredPlaces.map(place => {
            if (place.itineraryPlaceIndex > sourcePlace.itineraryPlaceIndex) {
              mutatedList.push({ id: place.id, placeIndex: place.itineraryPlaceIndex - 1 });
            }
          });
        }
      }

      let destinationFilteredPlaces = [];

      destinationFilteredPlaces = places.filter(place => {
        return String(place.dayIndex) === endColumn;
      });

      destinationFilteredPlaces.map((place, i) => {
        const placeIndex = place.itineraryPlaceIndex;

        if (placeIndex >= destination.index) {
          mutatedList.push({ id: place.id, placeIndex: placeIndex + 1 });
        }
      });

      mutatedList.push({ id: draggableId, placeIndex: destination.index });

      const context = {
        movedPlaceId: draggableId,
        movedPlaceDestinationListOrDay: parseInt(endColumn),
      };
      updatePlaceList(destinationListType, "BETWEEN", mutatedList, context);
      this.props.showOverlayRoute(parseInt(endColumn));
      return;
    } else {
      return;
    }
  };

  render() {
    const {
      match,
      trip,
      places,
      lists,
      isPublic,
      isItineraryPaneVisible,
      selectedTab,
      auth,
    } = this.props;
    const tripId = match.params.tripId;
    var mapCenter = trip && { lat: trip.lat, lng: trip.lng };

    let listPlaceMap = {};
    let listPlaceArray = [];
    let hasPlaceInItinerary = false;

    const authorName = chooseAuthorToShow(trip, this.state.tripOwnerUserData);
    const authorPhotoSrc = chooseAuthorPhotoToShow(trip, this.state.tripOwnerUserData);

    // Sort of a hack below to get the right viewport units to use on mobile web for rendering the map properly
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    if (lists && places) {
      // Sort the lists by listIndex if all lists have a listIndex
      //   Note: originally lists didn't have a listIndex hence this check is necessary
      let listCopy = [...lists];
      let allHaveListIndex = true;
      listCopy.forEach(list => (allHaveListIndex = allHaveListIndex && "listIndex" in list));
      if (allHaveListIndex) {
        listCopy.sort((a, b) => (a.listIndex > b.listIndex ? 1 : -1));
      }

      listCopy.forEach(list => {
        listPlaceArray.push({
          title: list.title,
          color: list.color,
          id: list.id,
          listIndex: list.listIndex,
          places: [],
        });
        listPlaceMap[list.id] = [];
      });

      places.forEach(place => {
        if (place.listId && place.listId in listPlaceMap) {
          listPlaceMap[place.listId].push(place);
        }
        hasPlaceInItinerary = place.dayIndex >= 0 || hasPlaceInItinerary;
      });

      listPlaceArray.forEach(list => {
        list.places = listPlaceMap[list.id];
      });
    }

    return (
      <div className="container-fluid">
        <div className="row tablet-mobile-container">
          <DragDropContext onDragEnd={this.handleDragEnd} onDragStart={this.handleDragStart}>
            {this.shouldShowLists(selectedTab) && (
              <div
                className={
                  "py-4 overflow-auto vh-100 " + (isItineraryPaneVisible ? "col-lg-3" : "col-lg-4")
                }
              >
                <TabletOrSmallerOnly>
                  <SignUpBanner isPublic={isPublic} />
                </TabletOrSmallerOnly>
                <div className="d-flex flex-row mb-n1">
                  <BiggerThanTabletOnly>
                    <Link to={`/home`} className="mt-2 mr-3 align-self-start" id="back_button">
                      <i className="fas fa-arrow-left fa-lg text-dark" />
                    </Link>
                  </BiggerThanTabletOnly>
                  {isPublic ? (
                    <div>
                      <h2 style={{ wordBreak: "break-word" }}>{trip && trip.title}</h2>
                      {authorName && trip && trip.createdAt && (
                        <div className="mt-3">
                          <MiniProfileCard
                            photoSrc={authorPhotoSrc}
                            primaryText={authorName}
                            secondaryText={
                              <>
                                {moment.unix(trip.createdAt.seconds).format("MMMM Do, YYYY")}
                                {trip.numViews && (
                                  <>
                                    <span className="mx-1">|</span>
                                    {trip.numViews} {trip.numViews === 1 ? "view" : "views"}
                                  </>
                                )}
                              </>
                            }
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <div className="d-flex flex-column pr-1">
                        <h2
                          className="d-inline"
                          style={{ cursor: "pointer", wordBreak: "break-word" }}
                          onClick={this.props.showRenameTripForm}
                        >
                          {trip && trip.title}
                        </h2>
                        <TripPermissionIcon viewPermission={trip && trip.viewPermission} />
                      </div>
                      <div className="dropdown d-inline ml-auto mr-2 mt-2">
                        <a
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fas fa-cog fa-lg text-muted" />
                        </a>
                        <div className="dropdown-menu mt-3" aria-labelledby="dropdownMenuLink">
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={this.props.showTripSettingsModal}
                          >
                            Change Dates
                          </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() =>
                              this.props.showAuthenticatedModal(
                                AUTH_ACTIONS.DISPLAY_COPY_TRIP_CONTENT,
                                {
                                  tripToCopyId: tripId,
                                  tripToCopyTitle: trip.title,
                                }
                              )
                            }
                          >
                            Make a Copy
                          </a>
                          <a
                            className={
                              trip && auth.uid === trip.userId
                                ? "dropdown-item text-danger"
                                : "dropdown-item disabled"
                            }
                            href="#"
                            onClick={this.handleShowDeleteTripConfirmation}
                          >
                            Delete Trip
                          </a>
                          <div className="dropdown-divider" />
                          {!this.props.isProUser && (
                            <Link to="/pro" className="dropdown-item text-primary">
                              Go Pro ⚡️
                            </Link>
                          )}
                          <a
                            className="dropdown-item"
                            href="https://forms.gle/miBpopVvQ88ACdjh7"
                            target="_blank"
                          >
                            Give Feedback 💡
                          </a>
                          <a
                            href="https://www.buymeacoffee.com/ericmao"
                            target="_blank"
                            className="dropdown-item"
                          >
                            Support Scout ☕️
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <hr />
                <div className="d-flex flex-row">
                  {(hasPlaceInItinerary || !isPublic) && (
                    <BiggerThanTabletOnly>
                      <button
                        className={"btn btn-primary btn-sm mr-3"}
                        onClick={this.toggleItineraryPane}
                      >
                        <i className="fas fa-calendar-alt mr-2" />
                        Itinerary
                      </button>
                    </BiggerThanTabletOnly>
                  )}
                  {isPublic ? (
                    <button
                      className="btn btn-outline-primary btn-sm"
                      onClick={() =>
                        this.props.showAuthenticatedModal(AUTH_ACTIONS.DISPLAY_COPY_TRIP_CONTENT, {
                          tripToCopyId: tripId,
                          tripToCopyTitle: trip.title,
                        })
                      }
                    >
                      <i className="fas fa-copy mr-2" />
                      Make a Copy
                    </button>
                  ) : (
                    <>
                      <button
                        className="btn btn-outline-primary btn-sm mr-3"
                        onClick={this.props.showShareTripModal}
                      >
                        <i className="fas fa-share-square mr-2" />
                        Share
                      </button>
                      {trip && <PublishTripButton trip={trip} />}
                    </>
                  )}
                </div>
                {process.env.NODE_ENV === "development" && (
                  <>
                    <hr />
                    <span>Total Places: {places?.length}</span>
                  </>
                )}
                {trip && <TripNotes notes={trip.notes} tripId={trip.id} />}
                {listPlaceArray &&
                  listPlaceArray.map(list => (
                    <PlaceList
                      places={list.places}
                      key={list.id}
                      title={list.title}
                      color={list.color}
                      listId={list.id}
                      listIndex={list.listIndex}
                      trip={trip}
                      isPlaceListDropDisabled={this.state.isPlaceListDropDisabled}
                    />
                  ))}

                {isPublic ? null : (
                  <div className="col-sm-12 mt-4">
                    <a onClick={this.props.showAddListForm} href="#" className="text-muted">
                      + Add a section
                    </a>
                  </div>
                )}
              </div>
            )}
            {this.shouldShowItinerary(selectedTab, isItineraryPaneVisible) && (
              <Itinerary places={places} trip={trip} />
            )}
          </DragDropContext>

          {this.shouldShowMap(selectedTab) && (
            <div
              className={"px-0 map-container " + (isItineraryPaneVisible ? "col-lg-6" : "col-lg-8")}
            >
              {places && isLoaded(places) && (
                <Map mapCenter={mapCenter} places={places} isPublic={isPublic} />
              )}
            </div>
          )}
          <TabletOrSmallerOnly>
            <MobileNavBar isPublic={isPublic} hasPlaceInItinerary={hasPlaceInItinerary} />
          </TabletOrSmallerOnly>
        </div>
        <DeleteTripConfirmationModal tripId={tripId} />
        <RenameTripForm title={trip && trip.title} tripId={tripId} />
        <DeleteListConfirmationModal tripId={tripId} />
        <RenameListForm />
        <AddListForm trip={trip} />
        {auth.uid && <ShareTripModal trip={trip} tripId={tripId} auth={auth} />}
        <AddToItineraryModal trip={trip} />
        <PlaceTimeModal tripId={tripId} />
        <GalleryModal />
        <PlaceDetailsModal />
        <ListPickerModal lists={lists} />
        {lists && <ReorderListsModal lists={lists} />}
        {trip && <AddLodgingModal trip={trip} tripId={tripId} />}
        {trip && (
          <TripSettings
            tripId={tripId}
            tripNotes={trip.notes}
            numDays={trip.numDays}
            initialStartDate={moment.unix(trip.startDate.seconds)}
            initialEndDate={moment.unix(trip.endDate.seconds)}
          />
        )}
        {trip && auth.uid === trip.userId && <PublishingSettingsModal trip={trip} />}
        {trip && auth.uid === trip.userId && <PublishTripModal tripId={tripId} />}
        {trip && <SubscribeModal />}
        {trip && <DayInfoModal tripId={tripId} dayDescriptions={trip.dayDescriptions} />}
        {trip && <AuthenticatedModal />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const tripId = ownProps.match.params.tripId;
  const currentTrip = state.firestore.data.currentTrip
    ? { id: tripId, ...state.firestore.data.currentTrip }
    : null;
  return {
    auth: state.firebase.auth,
    trip: currentTrip,
    places: state.firestore.ordered.places,
    lists: state.firestore.ordered.lists,
    listsMap: state.firestore.data.lists,
    isItineraryPaneVisible: state.trips.isItineraryPaneVisible,
    selectedTab: state.trips.selectedTab,
    isProUser: state.auth.isProUser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
    showDeleteTripConfirmationModal: () => dispatch(showDeleteTripConfirmationModal()),
    showComingSoonModal: () => dispatch(showComingSoonModal()),
    showShareTripModal: () => dispatch(showShareTripModal()),
    showRenameListForm: (listId, listTitle, listColor) =>
      dispatch(showRenameListForm(listId, listTitle, listColor)),
    showRenameTripForm: () => dispatch(showRenameTripForm()),
    showAddListForm: () => dispatch(showAddListForm()),
    showTripSettingsModal: () => dispatch(showTripSettingsModal()),
    updatePlaceList: (listType, moveType, places, context) =>
      dispatch(updatePlaceList(listType, moveType, places, context)),
    setPublicView: () => dispatch(setPublicView()),
    setNonPublicView: () => dispatch(setNonPublicView()),
    showItineraryPane: () => dispatch(showItineraryPane()),
    hideItineraryPane: () => dispatch(hideItineraryPane()),
    showOverlayRoute: dayIndex => dispatch(showOverlayRoute(dayIndex)),
    hideOverlayRoute: () => dispatch(hideOverlayRoute()),
    hideItineraryMapView: () => dispatch(hideItineraryMapView()),
    showAddLodgingModal: placeId => dispatch(showAddLodgingModal(placeId)),
    clearUncheckedLists: () => dispatch(clearUncheckedLists()),
    clearFirestoreExceptTrips: () =>
      dispatch({
        type: actionTypes.CLEAR_DATA,
        preserve: { data: ["trips"], ordered: ["trips"] },
      }),
    showAuthenticatedModal: (authAction, authActionProps) =>
      dispatch(showAuthenticatedModal(authAction, authActionProps)),
    fetchAndSetSubscriptionStatus: () => dispatch(fetchAndSetSubscriptionStatus()),
    fetchAndSetTripCreatorProStatus: tripId => dispatch(fetchAndSetTripCreatorProStatus(tripId)),
    resetTripCreatorProStatus: () => dispatch(resetTripCreatorProStatus()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    return [
      {
        collection: "trips",
        doc: props.match.params.tripId,
        storeAs: "currentTrip",
      },
      {
        collection: "places",
        where: [["tripId", "==", props.match.params.tripId]],
        storeAs: "places",
      },
      {
        collection: "lists",
        where: [["tripId", "==", props.match.params.tripId]],
        storeAs: "lists",
      },
    ];
  })
)(ListView);
