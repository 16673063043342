import firebase from "firebase/compat/app";
import { EXPLORE_TRIP_IDS } from "../components/Constants";
import { shuffleArray } from "../utils/CommonUtils";

/**
 * Clears the provided placeId from the provided trip's lodgingList field,
 * replacing the placeId with an empty string "" if found in the lodgingList.
 * Does nothing if there's no lodgingList for the trip.
 *
 * @param {string} tripId ID of the trip to clear lodging for
 * @param {string} placeId ID of the place that is to be cleared from the lodgingList of the provided {tripId}.
 * @throws Error if there is an error fetching the trip document, or updating the lodgingList.
 */
export const clearTripLodgingForPlace = async (tripId, placeId) => {
  let tripDoc;
  try {
    tripDoc = await firebase
      .firestore()
      .collection("trips")
      .doc(tripId)
      .get();
  } catch (err) {
    throw new Error("Error fetching trip when clearing lodging for place: " + err);
  }
  if (!tripDoc.exists) {
    throw new Error("Trip does not exist when clearing lodging for place");
  }
  const oldLodgingList = tripDoc.get("lodgingList");
  if (!oldLodgingList) {
    // Don't need to do anything if there's no lodgingList for the trip.
    return;
  }
  const lodgingListWithoutDeletedPlace = oldLodgingList.map(lodgingPlaceId => {
    // Replace the placeId in the lodgingList with "", effectively clearing it
    return lodgingPlaceId === placeId ? "" : lodgingPlaceId;
  });
  try {
    await tripDoc.ref.update({ lodgingList: lodgingListWithoutDeletedPlace });
  } catch (err) {
    throw new Error("Error updating lodgingList when clearing lodging for place: " + err);
  }
};

/**
 * Fetches an array of Trip objects (trip data + id) given an array of tripIds.
 * If there's an error fetching a trip it will skip over that result in the returned array of trips.
 *
 * @param {[]} tripIds Array of tripIds to fetch
 * @returns {Promise.<Trip[]>} An array of trip objects
 */
export const fetchListOfTripsFromTripIds = async tripIds => {
  const exploreTripDocumentSnapshotAwaitables = tripIds.map(tripId => {
    return {
      tripId: tripId,
      tripPromise: firebase
        .firestore()
        .collection("trips")
        .doc(tripId)
        .get(),
    };
  });
  return (
    await Promise.all(
      exploreTripDocumentSnapshotAwaitables.map(({ tripId, tripPromise }) =>
        tripPromise.catch(err => {
          console.error(`Error fetching trip (${tripId}) from list of trip IDs : ${err}`);
          return null;
        })
      )
    )
  )
    .filter(tripSnap => !!tripSnap)
    .map(tripSnap => {
      return { id: tripSnap.id, ...tripSnap.data() };
    });
};

/**
 * Fetches a random array of Explore trip objects (trip data + id) based on hardcoded explore trips found in EXPLORE_TRIP_IDS.
 * If there's an error fetching a trip it will skip over that result in the returned array of trips.
 *
 * @param {number} numTrip Number of random trips to fetch
 * @returns {Promise.<Trip[]>} A random array of explore trip objects
 */
export const fetchRandomExploreTrips = async numTrips =>
  fetchListOfTripsFromTripIds(shuffleArray(EXPLORE_TRIP_IDS.slice()).slice(0, numTrips));

/**
 * Calls a cloud function to increment the numViews field on the document keyed by the given tripId by 1.
 * Error logs to console if there is an error calling the cloud function.
 */
export const incrementTripViews = async tripId => {
  const incrementTripNumViews = firebase.functions().httpsCallable("incrementTripNumViews");
  incrementTripNumViews({
    tripId: tripId,
  }).catch(e => {
    console.error(`Error trying to increment trip views client-side for trip ${tripId}:`, e);
  });
};
