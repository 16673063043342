import React, { useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { hideRenameListForm } from "../store/actions/modalActions";
import { updateListOptions } from "../store/actions/listActions";
import Modal from "react-bootstrap/Modal";
import { COLOR_MARKER_ARRAY } from "./markerColors";

const RenameListForm = () => {
  const showRenameListModal = useSelector(state => state.modals.showRenameListModal);
  const renameListId = useSelector(state => state.modals.renameListId);
  const renameListTitle = useSelector(state => state.modals.renameListTitle);
  const renameListColor = useSelector(state => state.modals.renameListColor);
  const [listName, setListName] = useState(renameListTitle || "");
  const [color, setColor] = useState(renameListColor);
  const dispatch = useDispatch();

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(updateListOptions(renameListId, listName, color));
    handleClose();
  };

  const handleClose = () => {
    setListName("");
    setColor(null);
    dispatch(hideRenameListForm());
  };

  const handleEntered = () => {
    if (renameListTitle) {
      setListName(renameListTitle);
    }
    if (renameListColor) {
      setColor(renameListColor);
    }
  };

  return (
    <Modal
      id="task-modal"
      show={showRenameListModal}
      onHide={handleClose}
      onEntered={handleEntered}
    >
      <form id="rename-form" onSubmit={handleSubmit}>
        <Modal.Body>
          <button
            type="button"
            className="close btn btn-round"
            onClick={handleClose}
            aria-label="Close"
          >
            <i className="material-icons">close</i>
          </button>
          <h4 className="modal-title mb-4">List Options</h4>
          <div className="form-group">
            <label className="form-label">Name</label>
            <input
              className="form-control col"
              type="text"
              id="listName"
              placeholder="Name of list"
              name="title"
              onChange={e => setListName(e.target.value)}
              value={listName}
              autoComplete="off"
              data-1p-ignore // Prevent 1Pass icon from showing
            />
          </div>
          <div className="form-group">
            <label className="form-label">Color</label>
            <div className="list-color">
              {COLOR_MARKER_ARRAY.map(colorObj => (
                <>
                  <input
                    type="radio"
                    name="color"
                    id={colorObj.name}
                    value={colorObj.name}
                    checked={color === colorObj.name}
                    onChange={e => setColor(e.target.value)}
                  />
                  <label
                    htmlFor={colorObj.name}
                    title={colorObj.name.charAt(0).toUpperCase() + colorObj.name.slice(1)}
                  >
                    <span style={{ background: colorObj.hexColor, textAlign: "center" }}>
                      {color === colorObj.name && (
                        <i className="fas fa-check fa-xs" style={{ color: "#fff" }} />
                      )}
                    </span>
                  </label>
                </>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <button className="btn btn-primary" type="submit">
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    updateListOptions: (listId, listTitle) => dispatch(updateListOptions(listId, listTitle)),
    hideRenameListForm: () => dispatch(hideRenameListForm()),
  };
};

const mapStateToProps = state => {
  return {
    showRenameListModal: state.modals.showRenameListModal,
    renameListId: state.modals.renameListId,
    renameListTitle: state.modals.renameListTitle,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RenameListForm);
