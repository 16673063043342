import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { hideListPickerModal } from "../store/actions/modalActions";
import { updatePlaceList } from "../store/actions/placeActions";

class ListPickerModal extends Component {
  handleSelectList = selectedListId => {
    if (!this.props.placesMap[this.props.placeId]) {
      return;
    }
    const place = this.props.placesMap[this.props.placeId];

    let mutatedList = [];
    let movedPlaceId = null;
    let movedPlaceDestinationList = null;
    const sourceListId = place.listId;
    const destinationListId = selectedListId;
    const sourcePlaceIndex = place.placeIndex;
    const destinationPlaceIndex = this.props.places.filter(
      place => place.listId === destinationListId
    ).length;

    // Place has not changed lists
    if (sourceListId === destinationListId) {
      this.handleClose();
      return;
    }

    //Decrement placeIndex of places in source list
    this.props.places
      .filter(place => place.listId === sourceListId)
      .map((currPlace, i) => {
        const currPlaceIndex = currPlace.placeIndex;
        if (currPlaceIndex === sourcePlaceIndex) {
          mutatedList.push({
            id: currPlace.id,
            placeIndex: destinationPlaceIndex,
          });
          movedPlaceId = currPlace.id;
          movedPlaceDestinationList = destinationListId;
        }
        if (currPlaceIndex > sourcePlaceIndex) {
          mutatedList.push({
            id: currPlace.id,
            placeIndex: currPlaceIndex - 1,
          });
        }
      });

    // Increment placeIndex of places in destination list
    this.props.places
      .filter(place => place.listId === destinationListId)
      .map((currPlace, i) => {
        const currPlaceIndex = currPlace.placeIndex;
        if (currPlaceIndex >= destinationPlaceIndex) {
          mutatedList.push({
            id: currPlace.id,
            placeIndex: currPlaceIndex + 1,
          });
        }
      });
    const context = {
      movedPlaceId: movedPlaceId,
      movedPlaceDestinationListOrDay: movedPlaceDestinationList,
    };
    this.props.updatePlaceList("LIST", "BETWEEN", mutatedList, context);

    this.handleClose();
  };

  handleClose = () => {
    this.props.hideListPickerModal();
  };

  render() {
    const { placeId, placesMap } = this.props;
    if (!this.props.lists) {
      return null;
    }
    let currentlySelectedListId = null;
    if (placeId && placesMap && placesMap[placeId]) {
      currentlySelectedListId = placesMap[placeId].listId;
    }
    // Sort the lists by listIndex if all lists have a listIndex
    //   Note: originally lists didn't have a listIndex hence this check is necessary
    let listCopy = [...this.props.lists];
    let allHaveListIndex = true;
    listCopy.forEach(list => (allHaveListIndex = allHaveListIndex && "listIndex" in list));
    if (allHaveListIndex) {
      listCopy.sort((a, b) => (a.listIndex > b.listIndex ? 1 : -1));
    }

    return (
      <div>
        <Modal show={this.props.showListPickerModal} onHide={this.handleClose}>
          <Modal.Body>
            <button type="button" className="close btn btn-round" onClick={this.handleClose}>
              <i className="material-icons">close</i>
            </button>
            <h3 className="modal-title mb-3">Choose a list</h3>
            <div className="d-flex flex-column align-items-center">
              {listCopy &&
                listCopy.map(list => {
                  return (
                    <button
                      type="button"
                      key={list.id}
                      className={"btn mb-2 w-75 btn-outline-primary"}
                      onClick={() => this.handleSelectList(list.id)}
                    >
                      {currentlySelectedListId === list.id && <i className="fas fa-check mr-1" />}
                      {list.title}
                    </button>
                  );
                })}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showListPickerModal: state.modals.showListPickerModal,
    placeId: state.modals.listPickerModalPlaceId,
    places: state.firestore.ordered.places,
    placesMap: state.firestore.data.places,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideListPickerModal: () => dispatch(hideListPickerModal()),
    updatePlaceList: (listType, moveType, places, context) =>
      dispatch(updatePlaceList(listType, moveType, places, context)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListPickerModal);
