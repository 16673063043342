const initState = {
  infoWindowIsOpen: false,
  showInfoWindowIndex: null,
  notesInputIsOpen: false,
  searchBounds: null,
  isOverlayRouteVisible: false,
  overlayRouteDayIndex: null,
  shouldShowCurrentLocationMarker: false,
  currentLatLng: null,
};

const mapReducer = (state = initState, action) => {
  switch (action.type) {
    case "SHOW_INFO_WINDOW":
      return {
        ...state,
        infoWindowIsOpen: true,
        showInfoWindowIndex: action.infoIndex,
      };
    case "HIDE_INFO_WINDOW":
      return {
        ...state,
        infoWindowIsOpen: false,
        showInfoWindowIndex: null,
      };
    case "SET_SEARCH_BOUNDS":
      return {
        ...state,
        searchBounds: action.latLngBounds,
      };
    case "SHOW_OVERLAY_ROUTE":
      return {
        ...state,
        isOverlayRouteVisible: true,
        overlayRouteDayIndex: action.overlayRouteDayIndex,
      };
    case "HIDE_OVERLAY_ROUTE":
      return {
        ...state,
        isOverlayRouteVisible: false,
        overlayRouteDayIndex: null,
      };
    case "SHOW_MAP_CURRENT_LOCATION_MARKER":
      return {
        ...state,
        shouldShowCurrentLocationMarker: true,
        currentLatLng: action.currentLatLng,
      };
    case "HIDE_MAP_CURRENT_LOCATION_MARKER":
      return {
        ...state,
        shouldShowCurrentLocationMarker: false,
        currentLatLng: null,
      };
    default:
      return state;
  }
};

export default mapReducer;
