import React from "react";
import { SingleDatePicker } from "react-dates";
import MediaQuery from "react-responsive";
import { MAX_WIDTH_OF_TABLET } from "./Constants";

const CustomSingleDatePicker = props => (
  <MediaQuery maxWidth={MAX_WIDTH_OF_TABLET}>
    {isTabletOrSmaller => (
      <SingleDatePicker numberOfMonths={isTabletOrSmaller ? 1 : 2} readOnly={true} {...props} />
    )}
  </MediaQuery>
);

export default CustomSingleDatePicker;
