import { Component } from "react";
import { createPortal } from "react-dom";
import { MAP } from "react-google-maps/lib/constants";
import PropTypes from "prop-types";

// https://gist.github.com/jgimbel/6a36d60e28aaf453d0093ddc47f36533
export default class CurrentLocationMapControl extends Component {
  static contextTypes = { [MAP]: PropTypes.object };

  static defaultProps = {
    // eslint-disable-next-line no-undef
    position: google.maps.ControlPosition.RIGHT_BOTTOM,
  };

  componentWillMount() {
    // The commented one below is the one referenced by the link above,
    // but instead right now we're passing the mapNode directly and accessing the context that way.
    this.map = this.context[MAP];
    if (!this.map) {
      return;
    }
    this.controlDiv = document.createElement("div");
    this.divIndex = this.map.controls[this.props.position].length;
    this.map.controls[this.props.position].push(this.controlDiv);
  }
  componentWillUnmount() {
    if (!this.map) {
      return;
    }
    this.map.controls[this.props.position].removeAt(this.divIndex);
  }
  render() {
    if (!this.map) {
      return null;
    }
    return createPortal(this.props.children, this.controlDiv);
  }
}
