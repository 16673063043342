import { getApp } from "@firebase/app";
import { getStripePayments, createCheckoutSession } from "@stripe/firestore-stripe-payments";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import LoadingComponent from "./LoadingComponent";
import { setSignInCompleteRedirectRoute } from "../store/actions/authActions";
import {
  SCOUT_PRO_PLACE_COUNT_THRESHOLD,
  MONTHLY_SUB_PRICE_ID,
  ANNUAL_SUB_PRICE_ID,
  MONTHLY_PRICE,
  ANNUAL_PRICE,
} from "./Constants";

const ScoutProSignup = () => {
  const dispatch = useDispatch();
  const app = getApp();
  const history = useHistory();
  const [isBillingAnnually, setIsBillingAnnually] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useSelector(state => state.firebase.auth);
  const isProUser = useSelector(state => state.auth.isProUser);
  const payments = getStripePayments(app, {
    productsCollection: "products",
    customersCollection: "users",
  });

  useEffect(() => {
    document.title = "Scout Pro - Scout";
    window.analytics.page("Scout Pro Signup");
  }, []);

  const handleSubscribeClicked = async () => {
    if (!auth.uid || auth.isAnonymous) {
      dispatch(setSignInCompleteRedirectRoute("/pro"));
      history.push("/signup");
    } else {
      setIsLoading(true);

      window.analytics.track("Button Clicked", {
        buttonType: "Subscribe",
        pageType: "Subscribe Page",
        billingType: isBillingAnnually ? "Annual" : "Monthly",
      });

      let priceId = isBillingAnnually ? ANNUAL_SUB_PRICE_ID : MONTHLY_SUB_PRICE_ID;
      const session = await createCheckoutSession(payments, {
        price: priceId,
        success_url: window.location.origin + "/home",
        allow_promotion_codes: true,
        metadata: { firebaseUserId: auth.uid },
      });
      window.location.assign(session.url);
    }
  };

  if (isLoading) {
    return (
      <body>
        <LoadingComponent message="Loading checkout..." />
      </body>
    );
  }

  return (
    <div>
      <Header />
      <div
        className="jumbotron jumbotron-fluid mb-0 min-vh-100"
        style={{
          backgroundSize: "cover",
          backgroundImage:
            "linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0)), url(https://images.unsplash.com/photo-1533760881669-80db4d7b4c15?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&q=80)",
        }}
      >
        <div className="container py-4">
          <div className="row justify-content-end">
            <div className="col-lg-4">
              <h1 className="display-4 text-white pr-2">Scout like a pro</h1>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card mb-5 mb-lg-0 border-0 shadow">
                    <div className="card-body">
                      <h2 className="text-base subtitle text-center text-primary pt-5 pb-3">
                        Free
                      </h2>
                      <p className="text-muted text-center">
                        <span className="h1 text-dark">$0</span>
                        <span className="ml-2">/month</span>
                      </p>
                      <p className="text-muted text-center text-sm">Great for small, quick trips</p>

                      <hr />
                      <ul className="fa-ul my-4">
                        <li className="mb-3">
                          <span className="fa-li text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          Add up to {SCOUT_PRO_PLACE_COUNT_THRESHOLD} places
                        </li>
                        <li className="mb-3">
                          <span className="fa-li text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          Build an itinerary
                        </li>
                        <li className="mb-3">
                          <span className="fa-li text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          Collaborate with others
                        </li>
                        <li className="mb-3">
                          <span className="fa-li text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          Unlimited trips
                        </li>
                      </ul>
                      <div className="text-center">
                        {!isProUser && (
                          <Link
                            className="btn btn-outline-primary"
                            to={auth?.uid ? "/home" : "/signup"}
                          >
                            Get Started
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card mb-5 mb-lg-0 border-0 shadow-lg pb-3">
                    <div className="card-status bg-primary"></div>
                    <div className="card-body">
                      <div className="custom-control custom-switch d-flex justify-content-end mb-4">
                        <input
                          className="custom-control-input"
                          id="instantBook"
                          type="checkbox"
                          checked={isBillingAnnually}
                          onChange={() => setIsBillingAnnually(!isBillingAnnually)}
                        />
                        <label className="custom-control-label" htmlFor="instantBook">
                          <span className="text-sm">Billed annually</span>
                        </label>
                      </div>
                      <h2 className="text-base subtitle text-center text-primary pb-3">
                        Scout Pro
                      </h2>
                      <p className="text-muted text-center">
                        {isBillingAnnually && (
                          <span className="h1 text-muted font-weight-normal mr-2">
                            <del>${MONTHLY_PRICE}</del>
                          </span>
                        )}
                        <span className="h1 text-dark">
                          ${isBillingAnnually ? ANNUAL_PRICE / 12 : MONTHLY_PRICE}
                        </span>
                        <span className="ml-2">/month</span>
                      </p>
                      {isBillingAnnually && (
                        <p className="text-sm text-muted text-center">(${ANNUAL_PRICE} /year)</p>
                      )}
                      <hr />
                      <ul className="fa-ul my-4">
                        <li className="mb-3">
                          <span className="fa-li text-secondary">
                            <i className="fas fa-plus"></i>
                          </span>
                          Add <strong>unlimited</strong> places 🚀
                        </li>
                        <li className="mb-3">
                          <span className="fa-li text-secondary">
                            <i className="fas fa-plus"></i>
                          </span>
                          Friends can add unlimited places to trips you create 👯
                        </li>
                        <li className="mb-3">
                          <span className="fa-li text-secondary">
                            <i className="fas fa-plus"></i>
                          </span>
                          See images of places 🌆
                        </li>
                        <li className="mb-3">
                          <span className="fa-li text-secondary">
                            <i className="fas fa-plus"></i>
                          </span>
                          No ads 🧹
                        </li>
                        <li className="mb-3">
                          <span className="fa-li text-secondary">
                            <i className="fas fa-plus"></i>
                          </span>
                          Premium support 🤵‍♂️
                        </li>
                        <li className="mb-3">
                          <span className="fa-li text-secondary">
                            <i className="fas fa-plus"></i>
                          </span>
                          Fight climate change{" "}
                          <a
                            href="https://climate.stripe.com/Mufq5D"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            🌎*
                          </a>
                        </li>
                        <li className="mb-3">
                          <span className="fa-li text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          Build an itinerary
                        </li>
                        <li className="mb-3">
                          <span className="fa-li text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          Collaborate with others
                        </li>
                        <li className="mb-3">
                          <span className="fa-li text-primary">
                            <i className="fas fa-check"></i>
                          </span>
                          Unlimited trips
                        </li>
                      </ul>
                      <div className="text-center">
                        {isProUser ? (
                          <button
                            className="btn btn-primary"
                            type="button"
                            disabled={true}
                            onClick={handleSubscribeClicked}
                            title="To change subscription, click your profile picture and go to Manage Subscription"
                          >
                            Already subscribed!
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleSubscribeClicked}
                          >
                            Subscribe
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ScoutProSignup;
