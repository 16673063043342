import React from "react";
import Modal from "react-bootstrap/Modal";
import { updateDayDescription } from "../store/actions/tripActions";
import { hideDayInfoModal } from "../store/actions/modalActions";
import { connect } from "react-redux";

class DayInfoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dayDescription: "",
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.updateDayDescription(
      this.props.tripId,
      this.props.dayInfoIndex,
      this.state.dayDescription
    );
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ dayDescription: "" });
    this.props.hideDayInfoModal();
  };

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleEntered = () => {
    if (this.props.dayDescriptions && this.props.dayInfoIndex !== undefined) {
      this.setState({
        dayDescription: this.props.dayDescriptions[this.props.dayInfoIndex],
      });
    }
    // Set focus to the title field
    this.descriptionInput.focus();
  };

  render() {
    return (
      <Modal
        id="task-modal"
        show={this.props.showDayInfoModal}
        onHide={this.handleClose}
        onEntered={this.handleEntered}
      >
        <form id="rename-form" onSubmit={this.handleSubmit}>
          <Modal.Body>
            <button
              type="button"
              className="close btn btn-round"
              onClick={this.handleClose}
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </button>
            <h4 className="modal-title mb-4">Day Notes</h4>
            <div className="form-group">
              <label htmlFor="dayDescription" className="form-label">
                Headline
              </label>
              <input
                id="dayDescription"
                name="dayDescription"
                type="text"
                aria-describedby="dayDescription"
                onChange={this.updateInput}
                value={this.state.dayDescription || ""}
                placeholder="For example, a certain neighborhood"
                autoComplete="off"
                className="form-control"
                ref={input => {
                  this.descriptionInput = input;
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    showDayInfoModal: state.modals.showDayInfoModal,
    dayInfoIndex: state.modals.dayInfoIndex,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDayDescription: (tripId, dayIndex, dayDescription) =>
      dispatch(updateDayDescription(tripId, dayIndex, dayDescription)),
    hideDayInfoModal: () => dispatch(hideDayInfoModal()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DayInfoModal);
