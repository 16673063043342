import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

/**
 *
 * @param {errorComponent} ReactComponent that gets rendered when there is an error doing the loading redirect.
 *                                        This component gets rendered exactly as it is provided full screen.
 */
const LoadingPage = ({
  imgSrc,
  imgAlt,
  shouldShowSpinner,
  spinnerText,
  errorComponent: ErrorComponent,
}) => {
  const dispatch = useDispatch();
  const loadingRedirectRoute = useSelector(state => state.trips.loadingRedirectRoute);
  const loadingRedirectError = useSelector(state => state.trips.loadingRedirectError);

  useEffect(() => {
    return () => {
      dispatch({ type: "LOADING_PAGE_UNMOUNTED" });
    };
  }, [dispatch]);

  if (loadingRedirectRoute) {
    return <Redirect to={loadingRedirectRoute} />;
  } else if (loadingRedirectError) {
    return <ErrorComponent />;
  }
  return (
    <body className="text-center">
      <div className="container-fluid vh-100">
        <div className="row justify-content-center h-100">
          <div className="col-md-6 my-auto">
            <div>
              <img
                src={imgSrc}
                alt={imgAlt}
                style={{ width: "300px" }}
                className="mb-5 img-fluid"
              />
            </div>
            {shouldShowSpinner && (
              <>
                <div className="spinner-border mb-4" role="status">
                  <span className="sr-only">{spinnerText}</span>
                </div>
                <h2>{spinnerText}</h2>
              </>
            )}
          </div>
        </div>
      </div>
    </body>
  );
};

export default LoadingPage;
