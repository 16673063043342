import React from "react";
import { TRIP_VIEW_PERMISSION } from "./Constants";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { hidePublishTripModal } from "../store/actions/modalActions";
import { updateTripViewPermission } from "../store/actions/tripActions";
import { Link } from "react-router-dom";

const PublishTripModal = ({ tripId }) => {
  const shouldShowPublishTripModal = useSelector(state => state.modals.shouldShowPublishTripModal);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hidePublishTripModal());
  };

  const handleEntered = () => {
    window.analytics.page("Publish Trip Modal");
  };

  const handlePublishTrip = e => {
    e.preventDefault();
    window.analytics.track("Button Clicked", {
      buttonType: "Publish",
      pageType: "Publish Modal",
    });
    dispatch(updateTripViewPermission(tripId, TRIP_VIEW_PERMISSION.PUBLIC));
    handleClose();
  };

  const handlePreviewClick = () => {
    window.analytics.track("Button Clicked", {
      buttonType: "Publish Preview",
      pageType: "Publish Modal",
    });
  };

  return (
    <Modal
      id="publish-trip-modal"
      show={shouldShowPublishTripModal}
      onHide={handleClose}
      onEntered={handleEntered}
    >
      <form id="publish-trip-form" onSubmit={handlePublishTrip}>
        <Modal.Body>
          <button
            type="button"
            className="close btn btn-round"
            onClick={handleClose}
            aria-label="Close"
          >
            <i className="material-icons">close</i>
          </button>
          <div className="text-center align-items-center">
            <p className="text-primary subtitle">Publish trip</p>
            <h3 className="modal-title">Help others plan</h3>
            <img
              src="/assets/img/illustrations/undraw_around_the_world_v9nu.svg"
              style={{ width: "400px" }}
              className="img-fluid my-5"
              alt="Person looking at a planet with location markers"
            />
            <p className="text-muted">
              People will be able to discover and view this trip. Featured trips will show up in{" "}
              <Link to="/explore">Explore</Link>.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer align-items-center">
          <Link
            to={`/t/${tripId}/public`}
            target="_blank"
            className="mr-auto"
            onClick={handlePreviewClick}
          >
            Preview
          </Link>
          <button className="btn btn-outline-muted" type="button" onClick={handleClose}>
            Cancel
          </button>
          <button className="btn btn-primary" type="submit">
            Publish
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default PublishTripModal;
