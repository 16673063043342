import React, { Component } from "react";

class LoadingComponent extends Component {
  render() {
    return (
      <div className="container">
        <div className="row vh-100">
          <div className="col-12 my-auto text-center">
            <div className="spinner-border mb-5" role="status">
              <span className="sr-only">{this.props.message}</span>
            </div>
            <h2>{this.props.message}</h2>
          </div>
        </div>
      </div>
    );
  }
}

export default LoadingComponent;
