import React from "react";
import ErrorPage from "./ErrorPage";

function TripDoesNotExistError() {
  return (
    <ErrorPage
      errorTitle="Trip not found"
      errorSubtitle="The trip you were looking for doesn't exist"
      imgSrc="/assets/img/illustrations/undraw_blank_canvas_3rbb.svg"
      alt="Person staring at blank canvas confused"
    />
  );
}

export default TripDoesNotExistError;
