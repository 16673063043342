import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Link, Redirect } from "react-router-dom";
import {
  signOut,
  fetchAndSetSubscriptionStatus,
  clearSignInCompleteRedirectRoute,
} from "../store/actions/authActions";
import NewTripCard from "./NewTripCard";
import LoadingComponent from "./LoadingComponent";
import TripCard from "./TripCard";
import Header from "./Header";
import Footer from "./Footer";
import { fetchTripsCollaboratingOn } from "../api/CollaboratorsAPI";
import { fetchRandomExploreTrips } from "../api/TripsAPI";
import { fetchPublicUserData } from "../api/UsersAPI";
import ExploreTripCard from "./ExploreTripCard";
import { SCOUT_PRO_PLACE_COUNT_THRESHOLD } from "./Constants";

// Number of sample trips to show
const NUM_SAMPLE_TRIPS = 3;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { collaboratorTrips: [], sampleTrips: [], userIdToUserData: {} };
  }

  componentDidMount() {
    const auth = this.props.auth;
    const userId = auth && auth.uid;

    document.title = "Home - Scout";
    window.analytics.page("Home");
    window.analytics.identify(userId);

    // Fetch collaborator trips only if the user is not anonymous this is to prevent
    // Firebase permission errors when it tries to fetch collaborator trips of an
    // anonymous user.
    if (auth && !auth.isAnonymous) {
      fetchTripsCollaboratingOn(userId).then(collaboratorTrips => {
        this.setState({
          collaboratorTrips: collaboratorTrips.map(trip => ({
            ...trip,
            type: "shared_with_you",
          })),
        });
      });

      fetchRandomExploreTrips(NUM_SAMPLE_TRIPS).then(sampleTrips => {
        this.setState({
          sampleTrips: sampleTrips,
        });
        const tripAuthorPromises = sampleTrips.map(trip => fetchPublicUserData(trip.userId));
        Promise.all(tripAuthorPromises).then(tripAuthors => {
          let userIdToUserData = {};
          tripAuthors.forEach(tripAuthor => {
            if (tripAuthor) {
              userIdToUserData[tripAuthor.id] = tripAuthor;
            } else {
              console.error(
                "Null trip author found while fetching sample trip authors in Home page"
              );
            }
          });
          this.setState({ userIdToUserData: userIdToUserData });
        });
      });

      this.props.fetchAndSetSubscriptionStatus();
    }
  }

  render() {
    const { trips, signInCompleteRedirectRoute } = this.props;
    if (this.props && this.props.auth && this.props.auth.isAnonymous && this.props.signOut) {
      // Sign the user out if they are signed in as an anonymous user here.
      // This is to make sure we don't show the "home" experience for an anonymous user.
      // Eventually we'll support converting anonymous user into an actual user.
      this.props.signOut();
      return <LoadingComponent message="Loading..." />;
    }

    // Redirect the user to a certain page after they've signed-in so they can complete the
    // action as an authenticated user (e.g., go to Scout Pro signup page after signing in).
    if (signInCompleteRedirectRoute) {
      this.props.clearSignInCompleteRedirectRoute();
      return <Redirect to={signInCompleteRedirectRoute} />;
    }

    const sampleTrips = this.state.sampleTrips;
    const collabTrips = this.state.collaboratorTrips;
    let userAndCollabTrips = trips ? collabTrips.concat(trips) : collabTrips;

    if (userAndCollabTrips && userAndCollabTrips.length > 0) {
      // Sort the trips by startDate (instead of relying on orderBy in connect() query)
      // Need this until redux-firestore bug is fixed (https://github.com/prescottprue/redux-firestore/issues/121)
      userAndCollabTrips.sort(function(a, b) {
        var x = a["startDate"];
        var y = b["startDate"];
        return x < y ? 1 : x > y ? -1 : 0;
      });
    }

    return (
      <>
        <Header />
        <section className="py-5">
          <div className="container">
            <div className="d-flex">
              <h1 className="mb-2">Welcome to Scout!</h1>
            </div>
            <p className="text-muted mb-4">Start planning your next adventure</p>
            <div className="d-flex flex-row align-items-center mb-4 mt-5">
              <h3 className="mb-4 my-auto mb-4">My Trips</h3>
              {userAndCollabTrips && userAndCollabTrips.length > 0 && (
                <Link to="/new" className="btn btn-primary ml-auto">
                  New Trip
                </Link>
              )}
            </div>
            <div className="row">
              {userAndCollabTrips && userAndCollabTrips.length > 0 ? (
                userAndCollabTrips.map(trip => <TripCard key={trip.id} trip={trip} />)
              ) : (
                <NewTripCard />
              )}
            </div>
            {!this.props.isProUser && userAndCollabTrips && userAndCollabTrips.length > 1 && (
              <div className="row mt-4">
                <div className="col-md-8">
                  <div className="card border-0 shadow">
                    <div className="card-header bg-secondary-light py-4 border-0">
                      <div className="media-body">
                        <h4 className="h6 subtitle text-sm text-secondary">Upgrade to Scout Pro</h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <p className="text-muted text-sm card-text">
                        Sign up for Scout Pro to <strong>add unlimited places</strong> (instead of
                        30 per trip), see images of places before you go, access new,
                        highly-requested features in the future, and not get any ads.
                      </p>
                      <Link to="/pro" className="btn btn-secondary">
                        Go Pro
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {sampleTrips && sampleTrips.length > 0 && (
              <div className="mt-5">
                <h3 className="mb-4">
                  Popular Trips
                  <Link to="/explore" className="text-secondary ml-3 text-sm">
                    See more
                    <i className="fas fa-angle-double-right ml-2" />
                  </Link>
                </h3>
                <div className="row">
                  {sampleTrips.map(sampleTrip => (
                    <ExploreTripCard
                      key={sampleTrip.id}
                      trip={sampleTrip}
                      tripAuthor={this.state.userIdToUserData[sampleTrip.userId]}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    trips: state.firestore.ordered.trips,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    signInCompleteRedirectRoute: state.auth.signInCompleteRedirectRoute,
    isProUser: state.auth.isProUser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
    fetchAndSetSubscriptionStatus: () => dispatch(fetchAndSetSubscriptionStatus()),
    clearSignInCompleteRedirectRoute: () => dispatch(clearSignInCompleteRedirectRoute()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    const userId = props.auth ? props.auth.uid : null;
    return [
      {
        collection: "trips",
        where: [["userId", "==", userId]],
      },
    ];
  })
)(Home);
