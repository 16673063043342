import React from "react";
import Notes from "./Notes";
import { useDispatch, useSelector } from "react-redux";
import { updatePlace } from "../store/actions/placeActions";

const PlaceNotes = ({ notes, placeId }) => {
  const isPublic = useSelector(state => state.trips.isPublic);
  const dispatch = useDispatch();

  const savePlaceNotes = notes => {
    dispatch(updatePlace(placeId, { notes: notes }));
  };

  if (isPublic && !notes) {
    return null;
  } else {
    return (
      <div className="mt-3">
        <Notes existingNotes={notes} saveNotes={savePlaceNotes} />
      </div>
    );
  }
};

export default PlaceNotes;
