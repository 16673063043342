import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="py-4 bg-gray-100">
      <div className="text-sm container">
        <div className="row">
          <div className="col-md-4">
            <span className="text-muted">© 2024 Scout Explore LLC</span>
          </div>
          <div className="col-md-4 text-md-center mt-2 mt-md-0">
            <a
              href="https://twitter.com/get_scout_app"
              target="_blank"
              className="text-muted text-hover-primary mr-4"
            >
              <i className="fab fa-twitter fa-lg" />
            </a>
            <a
              href="https://www.instagram.com/get.scout/"
              target="_blank"
              className="text-muted text-hover-primary mr-4"
            >
              <i className="fab fa-instagram fa-lg" />
            </a>
            <a
              href="https://www.facebook.com/getscoutapp"
              target="_blank"
              className="text-muted text-hover-primary"
            >
              <i className="fab fa-facebook fa-lg" />
            </a>
          </div>
          <div className="col-md-4 text-md-right mt-2 mt-md-0">
            <Link to="/about" className="text-muted mr-4">
              About
            </Link>
            <a href="https://climate.stripe.com/Mufq5D" className="text-muted mr-4">
              Climate 🌎
            </a>
            <Link to="/privacy" className="text-muted mr-4">
              Privacy
            </Link>
            <Link to="/terms" className="text-muted">
              Terms
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
