import React, { Component } from "react";
import { connect } from "react-redux";
import { sendForgotPasswordEmail } from "../../store/actions/authActions";
import { compose } from "redux";
import { withRouter, Redirect } from "react-router-dom";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
    };
  }

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.sendForgotPasswordEmail(this.state.email);
  };

  componentDidMount() {
    document.title = "Forgot Password - Scout";
    window.analytics.page("Forgot Password");
  }

  render() {
    const { auth, passwordResetError } = this.props;
    if (auth.uid) return <Redirect to="/home" />;

    return (
      <div className="main-container fullscreen mt-7">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-7">
              <div className="text-center">
                <h2>Forgot password</h2>
                <p className="lead">Enter your email address to reset</p>
                {passwordResetError ? (
                  <div className="alert alert-danger" role="alert">
                    {" "}
                    {passwordResetError}{" "}
                  </div>
                ) : null}
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email Address"
                      name="email"
                      onChange={this.updateInput}
                      value={this.state.email}
                    />
                  </div>
                  <button className="btn btn-lg btn-block btn-primary" role="button" type="submit">
                    Send reset link
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    passwordResetError: state.auth.passwordResetError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendForgotPasswordEmail: email => dispatch(sendForgotPasswordEmail(email)),
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ForgotPassword);
