import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import history from "./Router/history";
import { ScrollToTop } from "./hooks/CustomHooks";
import "./App.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./custom_css/react_dates_overrides.css";
import AddTripForm from "./components/AddTripForm";
import SignInPage from "./components/auth/SignInPage";
import SignUpPage from "./components/auth/SignUpPage";
import Home from "./components/Home";
import ExplorePage from "./components/ExplorePage";
import LandingPage from "./components/LandingPage";
import CollabTripView from "./components/CollabTripView";
import AboutPage from "./components/AboutPage";
import AuthenticatedRoute from "./components/layout/AuthenticatedRoute";
import ForgotPassword from "./components/auth/ForgotPassword";
import PasswordResetEmailSent from "./components/auth/PasswordResetEmailSent";
import TripLoadingView from "./components/TripLoadingView";
import PrivacyPolicy from "./components/miscellaneous/PrivacyPolicy";
import TermsOfService from "./components/miscellaneous/TermsOfService";
import CopyingTripLoadingPage from "./components/CopyingTripLoadingPage";
import ScoutProSignup from "./components/ScoutProSignup";

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <ScrollToTop />
        <div>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/new" component={AddTripForm} />
            <AuthenticatedRoute
              path="/home"
              component={Home}
              isAuthenticated={this.props.auth.uid}
            />
            <Route exact path="/t/:tripId" component={TripLoadingView} />
            <Route
              exact
              path="/t/:tripId/public"
              render={props => <TripLoadingView {...props} showPublicView />}
            />
            <Route exact path="/t/:tripId/edit" component={CollabTripView} />
            <Route path="/explore" component={ExplorePage} />
            <Route path="/about" component={AboutPage} />
            <Route path="/signin" component={SignInPage} />
            <Route path="/signup" component={SignUpPage} />
            <Route path="/forgot_password" component={ForgotPassword} />
            <Route path="/reset_email_sent" component={PasswordResetEmailSent} />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/terms" component={TermsOfService} />
            <Route path="/copyingTrip" component={CopyingTripLoadingPage} />
            <Route path="/pro" component={ScoutProSignup} />
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
  };
};

export default connect(mapStateToProps)(App);
