import { useRef, useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useFocus = () => {
  const htmlElRef = useRef();
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

// Hook to that returns [hoverRef, isHovered] to determine if
// DOM node hoverRef is getting hovered over (isHovered)
export const useHover = () => {
  const [value, setValue] = useState(false);

  // Wrap in useCallback so we can use in dependencies below
  const handleMouseOver = useCallback(() => setValue(true), []);
  const handleMouseOut = useCallback(() => setValue(false), []);

  // Keep track of the last node passed to callbackRef so we can remove its event listeners.
  const ref = useRef();

  // Use a callback ref instead of useEffect so that event listeners
  // get changed in the case that the returned ref gets added to
  // a different element later.
  const callbackRef = useCallback(
    node => {
      if (ref.current) {
        ref.current.removeEventListener("mouseover", handleMouseOver);
        ref.current.removeEventListener("mouseout", handleMouseOut);
        // Reset value to false so it doesn't stay in hover state after listener
        // is removed
        setValue(false);
      }

      ref.current = node;

      if (ref.current) {
        ref.current.addEventListener("mouseover", handleMouseOver);
        ref.current.addEventListener("mouseout", handleMouseOut);
      }
    },
    [handleMouseOver, handleMouseOut]
  );

  return [callbackRef, value];
};
// Usage
// function App() {
//   const [hoverRef, isHovered] = useHover();

//   return (
//     <div ref={hoverRef}>
//       {isHovered ? 'hovering yay' : 'not hovering'}
//     </div>
//   );
// }

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
