import { clearTripLodgingForPlace } from "../../api/TripsAPI";
import * as Sentry from "@sentry/browser";
import { trackGoogleAnalyticsCounter } from "../../tracking/util";
import { NUM_PHOTOS_TO_FETCH_FOR_PLACE } from "../../components/Constants";

export const addPlace = place => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const userId = getState().firebase.auth.uid;

    firestore
      .collection("places")
      .add({
        ...place,
        createdAt: new Date(),
        userId: userId,
      })
      .then(() => {
        dispatch({ type: "ADD_PLACE", place: place });
      })
      .catch(err => {
        dispatch({ type: "ADD_PLACE_ERROR", err });
      });
  };
};

export const updatePlace = (id, place) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const docRef = firestore.collection("places").doc(id);
    docRef
      .update({
        ...place,
      })
      .then(() => {
        dispatch({ type: "UPDATE_PLACE", place: place });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_PLACE_ERROR", err });
      });
  };
};

export const setTime = (placeId, startTime, endTime) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    const docRef = firestore.collection("places").doc(placeId);
    docRef
      .update({
        startTime: startTime ? firebase.firestore.Timestamp.fromMillis(startTime.valueOf()) : null,
        // Only set endTime if there's both a start time and end time, otherwise set it to null.
        endTime:
          endTime && startTime ? firebase.firestore.Timestamp.fromMillis(endTime.valueOf()) : null,
      })
      .then(() => {
        dispatch({ type: "SET_TIME", startTime, endTime });
      })
      .catch(err => {
        dispatch({ type: "SET_TIME_ERROR", err });
      });
  };
};

export const deletePlace = (id, listId) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    firestore
      .collection("places")
      .doc(id)
      .get()
      .then(doc => {
        const tripId = doc.get("tripId");
        // Decrement placeIndex of all places with higher placeIndex
        const higherIndexedPlaces = firestore
          .collection("places")
          .where("tripId", "==", tripId)
          .where("listId", "==", listId)
          .where("placeIndex", ">", doc.get("placeIndex"));
        higherIndexedPlaces
          .get()
          .then(querySnapshot => {
            let placesBatch = firestore.batch();
            querySnapshot.forEach(doc => {
              placesBatch.update(doc.ref, {
                placeIndex: firebase.firestore.FieldValue.increment(-1),
              });
            });
            placesBatch.commit().catch(err => {
              dispatch({ type: "DECREMENT_PLACE_ERROR", err: err });
            });
          })
          .catch(err => {
            dispatch({ type: "DECREMENT_PLACE_ERROR", err: err });
          });

        //Decrement itineraryPlaceIndex of all places with higher itineraryPlaceIndex
        const itineraryPlaceIndex = doc.get("itineraryPlaceIndex");
        if (
          itineraryPlaceIndex !== undefined &&
          itineraryPlaceIndex !== null &&
          itineraryPlaceIndex !== ""
        ) {
          const higherIndexedItineraryPlaces = firestore
            .collection("places")
            .where("tripId", "==", tripId)
            .where("dayIndex", "==", doc.get("dayIndex"))
            .where("itineraryPlaceIndex", ">", itineraryPlaceIndex);
          higherIndexedItineraryPlaces
            .get()
            .then(querySnapshot => {
              let itineraryBatch = firestore.batch();
              querySnapshot.forEach(doc => {
                itineraryBatch.update(doc.ref, {
                  itineraryPlaceIndex: firebase.firestore.FieldValue.increment(-1),
                });
              });
              itineraryBatch.commit().catch(err => {
                dispatch({ type: "DECREMENT_PLACE_ERROR", err: err });
              });
            })
            .catch(err => {
              dispatch({ type: "DECREMENT_PLACE_ERROR", err: err });
            });
        }

        // Delete place from the trip's lodging list
        try {
          clearTripLodgingForPlace(tripId, doc.id);
        } catch (err) {
          Sentry.withScope(function(scope) {
            scope.setExtra("tripId", tripId).setExtra("placeId", doc.id);
            Sentry.captureException(err);
          });
        }

        firestore
          .collection("places")
          .doc(id)
          .delete()
          .then(() => {
            dispatch({ type: "DELETE_PLACE", id: id });
          })
          .catch(err => {
            dispatch({ type: "DELETE_PLACE_ERROR", err: err });
          });
      })
      .catch(err => {
        dispatch({ type: "DELETE_PLACE_ERROR", err: err });
      });
  };
};

export const removePlaceFromItinerary = id => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    firestore
      .collection("places")
      .doc(id)
      .get()
      .then(doc => {
        // Decrement placeIndex of all places with higher placeIndex
        const query = firestore
          .collection("places")
          .where("tripId", "==", doc.get("tripId"))
          .where("dayIndex", "==", doc.get("dayIndex"))
          .where("itineraryPlaceIndex", ">", doc.get("itineraryPlaceIndex"));
        query
          .get()
          .then(querySnapshot => {
            let itineraryBatch = firestore.batch();
            // Decrement the itineraryPlaceIndex for all places after the deleted place
            querySnapshot.forEach(doc => {
              itineraryBatch.update(doc.ref, {
                itineraryPlaceIndex: firebase.firestore.FieldValue.increment(-1),
              });
            });
            // Remove the selected place from the itinerary
            let deletedPlaceRef = firestore.collection("places").doc(id);
            itineraryBatch.update(deletedPlaceRef, {
              dayIndex: -1,
              itineraryPlaceIndex: null,
            });
            itineraryBatch.commit().catch(err => {
              dispatch({ type: "DECREMENT_PLACE_ERROR", err: err });
            });
          })
          .then(() => {
            dispatch({ type: "REMOVE_PLACE_FROM_ITINERARY", id: id });
          })
          .catch(err => {
            dispatch({ type: "REMOVE_PLACE_FROM_ITINERARY_ERROR", err: err });
          });
      })
      .catch(err => {
        dispatch({ type: "REMOVE_PLACE_FROM_ITINERARY_ERROR", err: err });
      });
  };
};

// moveType can be either WITHIN or BETWEEN meaning within a list or between lists
// context is only supplied if the moveType is BETWEEN and contains
//   movedPlaceId and movedPlaceDestinationListOrDay
export const updatePlaceList = (listType, moveType, places, context) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    var placesCollection = firestore.collection("places");
    var batch = firestore.batch();

    if (listType === "DAY") {
      places.map(place => {
        batch.update(placesCollection.doc(place["id"]), {
          itineraryPlaceIndex: place["placeIndex"],
        });
      });
      if (moveType === "BETWEEN") {
        batch.update(placesCollection.doc(context["movedPlaceId"]), {
          dayIndex: context["movedPlaceDestinationListOrDay"],
        });
      }
      batch.commit().then(() => {
        dispatch({ type: "UPDATE_PLACE_LIST", placesCount: places.length });
      });
    } else if (listType === "LIST") {
      places.map(place => {
        batch.update(placesCollection.doc(place["id"]), {
          placeIndex: place["placeIndex"],
        });
      });
      if (moveType === "BETWEEN") {
        batch.update(placesCollection.doc(context["movedPlaceId"]), {
          listId: context["movedPlaceDestinationListOrDay"],
        });
      }
      batch.commit().then(() => {
        dispatch({ type: "UPDATE_PLACE_LIST", placesCount: places.length });
      });
    } else {
      dispatch({ type: "UPDATE_PLACE_LIST_ERROR", listType: listType });
    }
  };
};

export const showHighlightMarker = id => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_HIGHLIGHT_MARKER", id: id });
  };
};

export const hideHighlightMarker = id => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_HIGHLIGHT_MARKER", id: id });
  };
};

export const showPlaceSearchResults = searchResults => {
  return (dispatch, getState) => {
    dispatch({
      type: "SHOW_PLACE_SEARCH_RESULTS",
      placeSearchResults: searchResults,
    });
  };
};

export const hidePlaceSearchResults = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_PLACE_SEARCH_RESULTS" });
  };
};

export const hideSpecificPlaceSearchResult = googlePlaceId => {
  return (dispatch, getState) => {
    dispatch({
      type: "HIDE_SPECIFIC_PLACE_SEARCH_RESULT",
      googlePlaceId: googlePlaceId,
    });
  };
};

export const showPoiInfoWindow = placeId => {
  return dispatch => {
    /*global google*/
    const placesService = new google.maps.places.PlacesService(document.createElement("div"));
    const detailsRequest = {
      placeId,
      fields: [
        "address_components",
        "formatted_address",
        "formatted_phone_number",
        "geometry",
        "icon",
        "international_phone_number",
        "name",
        "opening_hours",
        // #cost-experiment: don't get photos
        // "photos",
        "place_id",
        "price_level",
        "rating",
        "reviews",
        "types",
        "url",
        "user_ratings_total",
        "vicinity",
        "website",
      ],
    };
    placesService.getDetails(detailsRequest, (placeDetails, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        let thumbnailUrl = "";

        let extractedAddressComponents = extractAddressComponents(placeDetails.address_components);
        trackGoogleAnalyticsCounter({
          action: "POI Clicked",
          category: "Counters",
          value: 1,
          label: "POI on map clicked",
        });
        // #cost-experiment: don't call Google Maps Photos API
        // if (placeDetails.photos && placeDetails.photos.length > 0) {
        //   thumbnailUrl = placeDetails.photos[0].getUrl({ maxWidth: 300, maxHeight: 300 });
        //   console.log("Get POI thumbnail")
        //   trackGoogleAnalyticsCounter({
        //     action: "Google Photo URL Requested",
        //     category: "Counters",
        //     value: 1,
        //     label: "Get POI thumbnail image from Google Maps",
        //   });
        // }
        let fullPlaceDetails = {
          addressComponents: extractedAddressComponents,
          name: placeDetails.name,
          address: placeDetails.formatted_address || "",
          googlePlaceId: placeDetails.place_id || "",
          lat: (placeDetails.geometry && placeDetails.geometry.location.lat()) || "",
          lng: (placeDetails.geometry && placeDetails.geometry.location.lng()) || "",
          title: placeDetails.name,
          formattedPhone: placeDetails.formatted_phone_number || "",
          phone: placeDetails.international_phone_number || "",
          rating: placeDetails.rating || "",
          totalRatings: placeDetails.user_ratings_total || "",
          priceLevel: placeDetails.price_level || "",
          openingHours:
            (placeDetails.opening_hours && placeDetails.opening_hours.weekday_text) || [],
          website: placeDetails.website || "",
          googleMapsUrl: placeDetails.url || "",
          dayIndex: -1,
          types: placeDetails.types || "",
          thumbnailImgUrl: thumbnailUrl,
        };
        dispatch({
          type: "SET_POI_INFO",
          poiPlaceDetails: fullPlaceDetails,
        });
        dispatch({ type: "SHOW_INFO_WINDOW", infoIndex: -1 });
      }
    });
  };
};

export const clearPoiInfo = () => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_POI_INFO" });
  };
};

export const fetchTemporaryGooglePhotos = (googlePlaceId, type) => {
  return (dispatch, getState, { getFirebase }) => {
    const placesService = new google.maps.places.PlacesService(document.createElement("div"));

    const detailsRequest = {
      placeId: googlePlaceId,
      fields: ["photos"],
    };
    placesService.getDetails(detailsRequest, (placeDetails, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const temporaryPlacePhotos = placeDetails?.photos
          .slice(0, NUM_PHOTOS_TO_FETCH_FOR_PLACE)
          .map(placeDetailPhoto => {
            return placeDetailPhoto.getUrl();
          });
        trackGoogleAnalyticsCounter({
          action: "Google Photo URL Requested",
          category: "Counters",
          value: NUM_PHOTOS_TO_FETCH_FOR_PLACE,
          label: "Fetch temp place photos from " + type,
        });
        dispatch({ type: "TEMPORARY_PHOTOS_AVAILABLE", temporaryPlacePhotos });
      } else {
        dispatch({
          type: "TEMPORARY_PHOTOS_ERROR",
          err: "Error getting placeDetails from Google Places API: " + status,
        });
      }
    });
  };
};

export const clearTemporaryPhotos = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: "CLEAR_TEMPORARY_PHOTOS" });
  };
};

export const addPlaceFromGooglePlaces = (
  placeId,
  placeIndex,
  listId,
  tripId,
  shouldFetchPlacePhoto,
  autocompleteSessionToken
) => {
  return (dispatch, getState, { getFirebase }) => {
    /*global google*/
    const placesService = new google.maps.places.PlacesService(document.createElement("div"));
    const detailsRequest = {
      placeId,
      sessionToken: autocompleteSessionToken,
      fields: [
        "address_components",
        "formatted_address",
        "formatted_phone_number",
        "geometry",
        "icon",
        "international_phone_number",
        "name",
        "opening_hours",
        "place_id",
        "price_level",
        "rating",
        "reviews",
        "types",
        "url",
        "user_ratings_total",
        "vicinity",
        "website",
      ],
    };
    if (shouldFetchPlacePhoto) {
      detailsRequest.fields.push("photos");
    }
    placesService.getDetails(detailsRequest, (placeDetails, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const firestore = getFirebase().firestore();
        const userId = getState().firebase.auth.uid;
        let thumbnailUrl = "";

        if (placeDetails.photos && placeDetails.photos.length > 0) {
          thumbnailUrl = placeDetails.photos[0].getUrl({ maxWidth: 300, maxHeight: 300 });
          trackGoogleAnalyticsCounter({
            action: "Google Photo URL Requested",
            category: "Counters",
            value: 1,
            label: "Get thumbnail image for added place from Google Maps",
          });
        }

        let extractedAddressComponents = extractAddressComponents(placeDetails.address_components);
        let fullPlaceDetails = {
          addressComponents: extractedAddressComponents,
          name: placeDetails.name,
          address: placeDetails.formatted_address || "",
          googlePlaceId: placeDetails.place_id || "",
          lat: (placeDetails.geometry && placeDetails.geometry.location.lat()) || "",
          lng: (placeDetails.geometry && placeDetails.geometry.location.lng()) || "",
          title: placeDetails.name,
          formattedPhone: placeDetails.formatted_phone_number || "",
          phone: placeDetails.international_phone_number || "",
          rating: placeDetails.rating || "",
          totalRatings: placeDetails.user_ratings_total || "",
          priceLevel: placeDetails.price_level || "",
          openingHours:
            (placeDetails.opening_hours && placeDetails.opening_hours.weekday_text) || [],
          website: placeDetails.website || "",
          googleMapsUrl: placeDetails.url || "",
          placeIndex: placeIndex,
          tripId: tripId,
          listId: listId,
          dayIndex: -1,
          thumbnailImgUrl: thumbnailUrl,
          types: placeDetails.types || "",
        };

        firestore
          .collection("places")
          .add({
            ...fullPlaceDetails,
            createdAt: new Date(),
            userId: userId,
          })
          .then(docRef => {
            window.analytics.track("Place Added", {
              placeName: placeDetails.name,
              placeId: docRef.id,
              tripId: tripId,
              listId: listId,
              googlePlaceId: placeId,
            });
            dispatch({
              type: "ADD_PLACE",
            });
            dispatch({ type: "SHOW_INFO_WINDOW", infoIndex: docRef.id });
          })
          .catch(err => {
            dispatch({
              type: "ADD_PLACE_ERROR",
              err,
            });
          });
      } else {
        dispatch({
          type: "ADD_PLACE_ERROR",
          err: "Error getting placeDetails from Google Maps API",
        });
      }
    });
  };
};

export const addCustomPlace = (title, listId, tripId, placeIndex) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const userId = getState().firebase.auth.uid;

    firestore
      .collection("places")
      .add({
        title,
        listId,
        tripId,
        placeIndex,
        createdAt: new Date(),
        userId: userId,
        dayIndex: -1,
      })
      .then(docRef => {
        window.analytics.track("Place Added", {
          placeName: title,
          placeId: docRef.id,
          tripId: tripId,
          listId: listId,
          googlePlaceId: null,
        });
        dispatch({
          type: "ADD_PLACE",
        });
        dispatch({ type: "SHOW_INFO_WINDOW", infoIndex: docRef.id });
      })
      .catch(err => {
        err.message += " from adding customPlace";
        dispatch({
          type: "ADD_PLACE_ERROR",
          err,
        });
      });
  };
};

const extractAddressComponents = addressArray => {
  if (!addressArray) {
    return null;
  }

  let extractedAddress = {
    streetNumber: "",
    streetName: "",
    city: "",
    state: "",
    country: "",
  };

  addressArray.map(component => {
    component.types.includes("street_number") &&
      (extractedAddress.streetNumber = component.long_name);
    component.types.includes("route") && (extractedAddress.streetName = component.long_name);
    component.types.includes("locality") && (extractedAddress.city = component.long_name);
    component.types.includes("administrative_area_level_1") &&
      (extractedAddress.state = component.long_name);
    component.types.includes("country") && (extractedAddress.country = component.long_name);
  });

  return extractedAddress;
};

export const starPlace = id => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const docRef = firestore.collection("places").doc(id);
    docRef
      .update({
        isStarred: true,
      })
      .then(() => {
        dispatch({ type: "STAR_PLACE" });
      })
      .catch(err => {
        dispatch({ type: "STAR_PLACE_ERROR", err });
      });
  };
};

export const unstarPlace = id => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const docRef = firestore.collection("places").doc(id);
    docRef
      .update({
        isStarred: false,
      })
      .then(() => {
        dispatch({ type: "UNSTAR_PLACE" });
      })
      .catch(err => {
        dispatch({ type: "UNSTAR_PLACE_ERROR", err });
      });
  };
};
