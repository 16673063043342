import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PlaceSearch from "./PlaceSearch";
import PlaceMarker from "./PlaceMarker";
import { withGoogleMap, GoogleMap, Polyline } from "react-google-maps";
import { showPoiInfoWindow, clearPoiInfo } from "../store/actions/placeActions";
import BiggerThanTabletOnly from "./media_queries/BiggerThanTabletOnly";
import { hidePlaceSearchResults } from "../store/actions/placeActions";
import CustomPlaceInfoWindow from "./CustomPlaceInfoWindow";
import MediaQuery from "react-responsive";
import { MAX_WIDTH_OF_TABLET } from "./Constants";
import { showItineraryMapView, hideItineraryMapView } from "../store/actions/tripActions";
import CurrentLocationMarker from "./CurrentLocationMarker";
import CurrentLocationMapControl from "./CurrentLocationMapControl";
import { showCurrentLocationMarker, hideCurrentLocationMarker } from "../store/actions/mapActions";

class MyMapComponent extends Component {
  handleClearResultsClick = () => {
    this.props.hidePlaceSearchResults();
  };

  toggleItineraryMapView = () => {
    if (this.props.isItineraryMapViewVisible) {
      this.props.hideItineraryMapView();
    } else {
      this.props.showItineraryMapView();
    }
  };

  onCurrentPositionAcquired = position => {
    if (this.props.mapNode) {
      const currentLatLng = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      // TODO: Zoom into the current position as well
      this.props.showCurrentLocationMarker(currentLatLng);
      this.props.mapNode.panTo(currentLatLng);
    }
  };

  onCurrentPositionError = err => {
    // Browser supports geolocation but error fetching current position.
    if (err.code === err.PERMISSION_DENIED) {
      alert("Couldn’t get your location. Check your settings or try again later");
    } else {
      // Error is NOT from user disallowing location access, we'll just log it
      console.error("Error fetching current location (not user disallowing it):", err);
    }
    this.props.hideCurrentLocationMarker();
  };

  onCurrentLocationButtonClick = e => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.onCurrentPositionAcquired,
        this.onCurrentPositionError
      );
    } else {
      // Browser doesn't support geolocation.
      alert("Your browser doesn't support location services.");
      this.props.hideCurrentLocationMarker();
    }
  };

  componentWillUnmount() {
    this.props.hidePlaceSearchResults();
    this.props.hideCurrentLocationMarker();
  }

  render() {
    const {
      overlayRouteDayIndex,
      isOverlayRouteVisible,
      places,
      poiPlaceDetails,
      isPublic,
      infoWindowIsOpen,
      showInfoWindowIndex,
      placeSearchResults,
    } = this.props;
    let routeLatLngList = [];
    if (isOverlayRouteVisible) {
      // Exclude places without a lat and log (like manually added custom places) for overlay route
      let placesForDay = places.filter(
        place => place.lat && place.lng && place.dayIndex === overlayRouteDayIndex
      );
      placesForDay.sort((a, b) => (a.itineraryPlaceIndex > b.itineraryPlaceIndex ? 1 : -1));
      placesForDay.forEach(place => routeLatLngList.push({ lat: place.lat, lng: place.lng }));
    }
    const hasPlaceInItinerary =
      places &&
      places.some(place => {
        return place.dayIndex >= 0;
      });
    return (
      <MediaQuery maxDeviceWidth={MAX_WIDTH_OF_TABLET}>
        {isTabletOrSmaller => (
          <GoogleMap
            defaultCenter={this.props.defaultCenter}
            defaultZoom={this.props.defaultZoom}
            onClick={e => {
              this.props.hideInfoWindow();
              // Disable showing info window when clicking POIs on the map
              // this.props.clearPoiInfo();
              // if (e.placeId) {
              //   // Calling e.stop() on the event prevents the default info window from showing.
              //   // https://stackoverflow.com/a/25747867/7381647
              //   e.stop();
              //   this.props.showPoiInfoWindow(e.placeId);
              // }
            }}
            options={{
              mapTypeControl: false,
              fullscreenControl: false,
              gestureHandling: "greedy",
              streetViewControl: !isTabletOrSmaller,
              zoomControl: !isTabletOrSmaller,
              styles: myMapStyles,
            }}
            clickableIcons={false}
            onBoundsChanged={this.props.handleBoundsChanged}
            ref={this.props.mapRef}
          >
            {!isPublic && (
              <BiggerThanTabletOnly>
                <PlaceSearch panMapToPlaces={this.props.panMapToPlaces} />
              </BiggerThanTabletOnly>
            )}
            {this.props.mapNode && isTabletOrSmaller && (
              <CurrentLocationMapControl>
                <div>
                  <button
                    className="mr-3 mb-3"
                    onClick={this.onCurrentLocationButtonClick}
                    // Style copied from google maps mobile web current location button
                    style={{
                      backgroundColor: "rgba(255,255,255,1)",
                      borderRadius: "8px",
                      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.3)",
                      display: "block",
                      width: "36px",
                      height: "36px",
                      overflow: "hidden",
                      cursor: "pointer",
                      transition: "background-color 0.16s ease-out",
                    }}
                    onMouseDown={e => e.preventDefault()}
                  >
                    <i className="fas fa-location-arrow" />
                  </button>
                </div>
              </CurrentLocationMapControl>
            )}
            {isOverlayRouteVisible && (
              <>
                <Polyline
                  path={routeLatLngList}
                  options={{
                    strokeColor: "#3266CB",
                    strokeOpacity: 1,
                    strokeWeight: 8,
                  }}
                />
                <Polyline
                  path={routeLatLngList}
                  options={{
                    strokeColor: "#729BEF",
                    strokeOpacity: 1,
                    strokeWeight: 5,
                  }}
                />
              </>
            )}
            {placeSearchResults && placeSearchResults.length > 0 && (
              <div
                style={{
                  position: "absolute",
                  left: "480px",
                  top: "20px",
                  zIndex: 3,
                }}
              >
                <button
                  type="button"
                  onClick={this.handleClearResultsClick}
                  className="btn btn-secondary"
                >
                  Clear Results
                </button>
              </div>
            )}
            {poiPlaceDetails && (
              <PlaceMarker
                place={poiPlaceDetails}
                key={poiPlaceDetails.googlePlaceId}
                index={-1}
                type="POI"
              />
            )}
            {places &&
              places.map((place, index) => {
                return <PlaceMarker place={place} key={place.id} index={place.id} type="LIST" />;
              })}
            {this.props.placeSearchResults &&
              this.props.placeSearchResults.map((place, index) => {
                return (
                  <PlaceMarker
                    place={place}
                    key={place.googlePlaceId}
                    index={index + places.length}
                    type="SEARCH_RESULT"
                  />
                );
              })}
            <BiggerThanTabletOnly>
              {hasPlaceInItinerary && (
                <div
                  style={{
                    position: "absolute",
                    left: "32px",
                    bottom: "36px",
                    zIndex: 1,
                    backgroundColor: "white",
                    borderRadius: ".4rem",
                    padding: "7px 8px 10px 8px",
                    border: "1px solid #ced4da",
                  }}
                >
                  <div className="custom-control custom-switch pb-1">
                    <input
                      id="itineraryMapView"
                      type="checkbox"
                      className="custom-control-input"
                      style={{
                        outline: "none",
                        borderStyle: "none",
                      }}
                      checked={this.props.isItineraryMapViewVisible}
                      onChange={this.toggleItineraryMapView}
                    />
                    <label htmlFor="itineraryMapView" className="custom-control-label">
                      <span className="text-sm" style={{ userSelect: "none" }}>
                        Show only itinerary places
                      </span>
                    </label>
                  </div>
                </div>
              )}
            </BiggerThanTabletOnly>
            {this.props.shouldShowCurrentLocationMarker && isTabletOrSmaller && (
              <CurrentLocationMarker />
            )}
            {infoWindowIsOpen && showInfoWindowIndex && (
              // showInfoWindowIndex is the placeId, we use it as the key to force React to
              // render a new instance when the placeId changes instead of reusing another place's instance.
              <CustomPlaceInfoWindow placeId={showInfoWindowIndex} key={showInfoWindowIndex} />
            )}
          </GoogleMap>
        )}
      </MediaQuery>
    );
  }
}

const myMapStyles = [
  {
    featureType: "poi.business",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
];

const mapStateToProps = state => {
  return {
    isOverlayRouteVisible: state.map.isOverlayRouteVisible,
    overlayRouteDayIndex: state.map.overlayRouteDayIndex,
    poiPlaceDetails: state.places.poiPlaceDetails,
    isPublic: state.trips.isPublic,
    infoWindowIsOpen: state.map.infoWindowIsOpen,
    showInfoWindowIndex: state.map.showInfoWindowIndex,
    isItineraryMapViewVisible: state.trips.isItineraryMapViewVisible,
    shouldShowCurrentLocationMarker: state.map.shouldShowCurrentLocationMarker,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showPoiInfoWindow: placeId => dispatch(showPoiInfoWindow(placeId)),
    clearPoiInfo: () => dispatch(clearPoiInfo()),
    hidePlaceSearchResults: () => dispatch(hidePlaceSearchResults()),
    showItineraryMapView: () => dispatch(showItineraryMapView()),
    hideItineraryMapView: () => dispatch(hideItineraryMapView()),
    showCurrentLocationMarker: currentLatLng => dispatch(showCurrentLocationMarker(currentLatLng)),
    hideCurrentLocationMarker: () => dispatch(hideCurrentLocationMarker()),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withGoogleMap)(MyMapComponent);
