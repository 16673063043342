const initState = {
  isPublic: false,
  isItineraryPaneVisible: false,
  isItineraryMapViewVisible: false,
  accessLookupResponse: null,
  addCollaboratorResponse: null,
  selectedTab: null,
  loadingRedirectRoute: null,
  loadingRedirectError: null,
  isTripCreatorProUser: false,
};

const tripReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_TRIP":
      return state;
    case "CREATE_TRIP_ERROR":
      console.error("Error creating trip:", action.err);
      return state;
    case "DELETE_TRIP":
      return state;
    case "DELETE_TRIP_ERROR":
      console.error("Error deleting trip:", action.err);
      return state;
    case "UPDATE_TRIP_DATES":
      return state;
    case "UPDATE_TRIP_DATES_ERROR":
      console.error("Error updating trip dates", action.err);
      return state;
    case "ADD_DAY":
      return state;
    case "ADD_DAY_ERROR":
      console.error("Error adding day to trip:", action.err);
      return state;
    case "DELETE_DAY":
      return state;
    case "DELETE_DAY_ERROR":
      console.error("Error deleting day from trip:", action.err);
      return state;
    case "RENAME_TRIP":
      return state;
    case "RENAME_TRIP_ERROR":
      console.error("Error renaming list:", action.err);
      return state;
    case "UPDATE_DAY_DESCRIPTION":
      return state;
    case "UPDATE_DAY_DESCRIPTION_ERROR":
      console.error("Error updating day description:", action.err);
      return state;
    case "CLEAR_LODGING_FOR_DAY":
      return state;
    case "CLEAR_LODGING_FOR_DAY_ERROR":
      console.error("Error clearing lodging info:", action.err);
      return state;
    case "ADD_LODGING_TO_DATES":
      return state;
    case "ADD_LODGING_TO_DATES_ERROR":
      console.error("Error adding lodging:", action.err);
      return state;
    case "UPDATE_TRIP_NOTES":
      return state;
    case "UPDATE_TRIP_NOTES_ERROR":
      console.error("Error updating trip notes:", action.err);
      return state;
    case "UPDATE_TRIP_VIEW_PERMISSION":
      return state;
    case "UPDATE_TRIP_VIEW_PERMISSION_ERROR":
      console.error("Error updating trip view permission:", action.err);
      return state;
    case "SHOW_ITINERARY_PANE":
      return {
        ...state,
        isItineraryPaneVisible: true,
      };
    case "HIDE_ITINERARY_PANE":
      return {
        ...state,
        isItineraryPaneVisible: false,
      };
    case "SHOW_ITINERARY_MAP_VIEW":
      return {
        ...state,
        isItineraryMapViewVisible: true,
      };
    case "HIDE_ITINERARY_MAP_VIEW":
      return {
        ...state,
        isItineraryMapViewVisible: false,
      };
    case "SET_PUBLIC":
      return {
        ...state,
        isPublic: true,
      };
    case "SET_NON_PUBLIC":
      return {
        ...state,
        isPublic: false,
      };
    case "SEND_INVITE_TO_TRIP_EMAIL":
      return state;
    case "ERROR_SIGNING_IN_ANONYMOUSLY":
      console.error("Error signing in anonymously: ", action.err);
      return {
        ...state,
        signInAnonymouslyError: action.err,
      };
    case "ADD_COLLABORATOR_TO_TRIP_SUCCESS":
      return {
        ...state,
        addCollaboratorResponse: { isUserAddedAsCollaborator: true, errorAddingCollaborator: null },
      };
    case "ADD_COLLABORATOR_TO_TRIP_ERROR":
      console.error("Error adding collaborator to trip: ", action.err);
      return {
        ...state,
        addCollaboratorResponse: {
          isUserAddedAsCollaborator: false,
          errorAddingCollaborator: action.err,
        },
      };
    case "ACCESS_ALLOWED":
      return {
        ...state,
        accessLookupResponse: { accessLookupError: null, isTripViewable: true },
      };
    case "TRIP_LOADING_COMPLETED":
      return {
        ...state,
        accessLookupResponse: null,
      };
    case "CHECK_TRIP_ACCESS_ERROR":
      console.error("Error checking trip access", action.err);
      return {
        ...state,
        accessLookupResponse: { accessLookupError: action.err, isTripViewable: false },
      };
    case "SET_TRIP_PUBLIC":
      return state;
    case "SET_TRIP_PUBLIC_ERROR":
      console.error("Error setting trip to public", action.err);
      return state;
    case "CLEAR_ADD_COLLABORATOR_STATE":
      return {
        ...state,
        accessLookupResponse: null,
        addCollaboratorResponse: null,
        signInAnonymouslyError: null,
      };
    case "SELECT_TAB":
      return {
        ...state,
        selectedTab: action.tabName,
      };
    case "LOADING_PAGE_UNMOUNTED":
      return {
        ...state,
        loadingRedirectRoute: null,
        loadingRedirectError: null,
      };
    case "LOADING_REDIRECT_DONE":
      return {
        ...state,
        loadingRedirectRoute: action.loadingRedirectRoute,
      };
    case "LOADING_REDIRECT_ERROR":
      return {
        ...state,
        loadingRedirectError: action.loadingRedirectError,
      };

    case "FETCH_AND_SET_TRIP_CREATOR_PRO_STATUS":
      return {
        ...state,
        isTripCreatorProUser: action.status,
      };
    case "RESET_TRIP_CREATOR_PRO_STATUS":
      return {
        ...state,
        isTripCreatorProUser: false,
      };
    default:
      return state;
  }
};
export default tripReducer;
