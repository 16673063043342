import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { hideReorderListsModal } from "../store/actions/modalActions";
import { setListOrder } from "../store/actions/listActions";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class ReorderListsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: this.props.lists,
    };
  }

  handleSave = e => {
    this.props.setListOrder(this.state.lists);
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ lists: [] });
    this.props.hideReorderListsModal();
  };

  handleEntered = () => {
    if (this.props.lists) {
      // Sort the lists by listIndex if all lists have a listIndex
      //   Note: originally lists didn't have a listIndex hence this check is necessary
      let listCopy = [...this.props.lists];
      let allHaveListIndex = true;
      listCopy.forEach(list => (allHaveListIndex = allHaveListIndex && "listIndex" in list));
      if (allHaveListIndex) {
        listCopy.sort((a, b) => (a.listIndex > b.listIndex ? 1 : -1));
      }
      this.setState({
        lists: listCopy,
      });
    }
  };

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const lists = reorder(this.state.lists, result.source.index, result.destination.index);
    this.setState({
      lists,
    });
  };

  render() {
    return (
      <div>
        <Modal
          show={this.props.showReorderListsModal}
          onHide={this.handleClose}
          onEntered={this.handleEntered}
        >
          <Modal.Body>
            <div className="container">
              <button type="button" className="close btn btn-round" onClick={this.handleClose}>
                <i className="material-icons">close</i>
              </button>
              <h3 className="modal-title mb-3">Reorder Lists</h3>
              <p className="text-muted">Drag the list name to reorder, then click "Save"</p>
              <div className="d-flex flex-column mb-3 mt-2">
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable" className="row">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {this.state.lists.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="mb-2"
                              >
                                <div className="card border-0 shadow">
                                  <div
                                    className="d-flex align-items-center w-100"
                                    style={{ padding: ".9rem" }}
                                  >
                                    <h6
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        marginBottom: "0rem",
                                      }}
                                      title={item.title}
                                    >
                                      {item.title}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-primary" onClick={this.handleSave}>
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showReorderListsModal: state.modals.showReorderListsModal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideReorderListsModal: () => dispatch(hideReorderListsModal()),
    setListOrder: lists => dispatch(setListOrder(lists)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReorderListsModal);
