import React from "react";
import history from "../../Router/history";

export const BUTTON_OPTIONS = {
  HOME: "HOME",
  BACK: "BACK",
};
Object.freeze(BUTTON_OPTIONS);

/**
 *
 * @param {buttonOption} BUTTON_OPTIONS that determines what button is shown on the error page (e.g. go home, go back, etc.).
 *                                      If not specified, the default is BUTTON_OPTIONS.HOME (shows a go home button)
 */
function ErrorPage({
  errorTitle,
  errorSubtitle,
  imgSrc,
  altText,
  buttonOption = BUTTON_OPTIONS.HOME,
}) {
  const btn =
    buttonOption === BUTTON_OPTIONS.BACK ? (
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => {
          history.goBack();
        }}
      >
        Go Back
      </button>
    ) : (
      // Home is default button behavior
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => {
          history.push("/");
        }}
      >
        Go Home
      </button>
    );
  return (
    <body className="text-center">
      <div className="container-fluid vh-100">
        <div className="row justify-content-center h-100">
          <div className="col-md-6 my-auto">
            <img src={imgSrc} alt={altText} style={{ width: "300px" }} className="img-fluid mb-4" />
            <h2 className="mb-2">{errorTitle}</h2>
            <p className="mb-4 text-muted">{errorSubtitle}</p>
            {btn}
          </div>
        </div>
      </div>
    </body>
  );
}

export default ErrorPage;
