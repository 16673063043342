import React, { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";

const AboutPage = () => {
  useEffect(() => {
    document.title = "About Scout - Plan trips with ease";
    window.analytics.page("About");
  }, []);

  return (
    <div>
      <Header />
      <section className="position-relative py-6">
        <picture>
          <source
            type="image/webp"
            alt="Patagonia mountains"
            srcSet="https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Landing%20Page%2Fpatagonia-webp.webp?alt=media&token=0e6e8955-1bd7-4f25-ba19-79e9867ae100"
          />
          <img
            alt="Patagonia mountains"
            className="bg-image"
            src="https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Landing%20Page%2Fpatagonia.jpg?alt=media&token=ff065394-05a7-4a39-91bd-a9d52aa12d15"
          />
        </picture>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="bg-white rounded-lg shadow p-5">
                <strong className="text-uppercase text-secondary d-inline-block mb-2 text-sm">
                  How it started
                </strong>
                <h2 className="mb-3">Wanting a better way to plan</h2>
                <p className="text-muted">
                  The hardest part of traveling is planning the trip. I've spent many late nights
                  staring at the computer with 20 tabs open -- so many I couldn't even read the tab
                  title. At the same time I found myself constantly switching between Google Maps
                  and Docs/Sheets, seeing where things were on a map, then copying it into my
                  itinerary. I looked at other travel planners, but nothing had the simplicity I
                  wanted -- a list of places to visit, a <i>big</i> map, and an itinerary.
                </p>
                <p className="text-muted">
                  So I decided to build Scout. One place, easy to use, and less tab switching.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-6">
        <div className="container">
          <div className="py-4">
            <h2 className="mb-6 text-primary text-center">Creator</h2>
            <div className="row mb-5 d-flex justify-content-around">
              <div className="mb-2 mb-lg-0 col-sm-4">
                <div className="card border-0">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Landing%20Page%2Feric.jpg?alt=media&token=f407a1a5-9df5-419f-9493-a460c0f93611"
                    alt="Picture of Eric"
                    style={{ height: "auto", width: "auto" }}
                    className="avatar mb-4 mx-auto img-fluid"
                  />
                  <div className="text-center">
                    <h4 className="card-title">Eric Mao</h4>
                    <p className="text-muted">
                      Former product manager at Microsoft and an AI startup. Left my job to backpack
                      for 7 months across 14 countries in Southeast Asia, Africa, and South America.
                    </p>
                    <p className="text-muted">
                      Top of the bucket list: W Route in Torres del Paine, Patagonia.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-6 bg-gray-100">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-8">
              <p className="subtitle text-secondary">Come talk to us</p>
              <h2>Travel memes, #wanderlust, new features</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 text-center text-md-left mb-4 mb-md-0">
              <a href="https://twitter.com/get_scout_app" target="_blank">
                <div className="icon-rounded mb-4 bg-primary-light">
                  <i className="fab fa-twitter fa-2x" />
                </div>
              </a>
              <p className="text-muted">Tweet us your trips</p>
            </div>
            <div className="col-md-3 text-center text-md-left mb-4 mb-md-0">
              <a href="https://www.instagram.com/get.scout/" target="_blank">
                <div className="icon-rounded mb-4 bg-primary-light">
                  <i className="fab fa-instagram fa-2x" />
                </div>
              </a>
              <p className="text-muted">Tag #scoutexplore in your travel pics</p>
            </div>
            <div className="col-md-3 text-center text-md-left mb-4 mb-md-0">
              <a href="https://www.facebook.com/getscoutapp" target="_blank">
                <div className="icon-rounded mb-4 bg-primary-light">
                  <i className="fab fa-facebook-f fa-2x" />
                </div>
              </a>
              <p className="text-muted">See the latest and greatest Scout trips</p>
            </div>
            <div className="col-md-3 text-center text-md-left mb-4 mb-md-0">
              <a href="mailto:hello@scoutexplore.com" target="_blank">
                <div className="icon-rounded mb-4 bg-primary-light">
                  <i className="fas fa-envelope fa-2x" />
                </div>
              </a>
              <p className="text-muted">Feedback, investment, general inquiries</p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-3">
        <div className="container">
          <div className="py-3">
            <p className="text-center text-muted my-0">Made with ♥️ in San Francisco</p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AboutPage;
