import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

class TripCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
    };
  }
  handleMouseEnter = () => {
    this.setState({ isHovering: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovering: false });
  };

  render() {
    const { trip, shouldLinkToPublicView } = this.props;
    // Default picture: https://unsplash.com/photos/rf6ywHVkrlY
    let tripCardPhotoUrl =
      "https://images.unsplash.com/photo-1436491865332-7a61a109cc05?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&q=90&crop=entropy&cs=tinysrgb&h=400&w=1000&fit=min";

    if (trip.photo && trip.photo.url) {
      if (trip.photo.url.includes("images.unsplash")) {
        tripCardPhotoUrl =
          trip.photo.url + "q=90&crop=entropy&cs=tinysrgb&h=400&w=1000&fit=min&auto=format";
      } else {
        tripCardPhotoUrl = trip.photo.url;
      }
      // Legacy data model had trip.placePhotoUrl, have since moved to trip.photo object.
      //   Leaving this here so old trips continue to load the picture.
    } else if (trip.placePhotoUrl && !trip.placePhotoUrl.includes("maps.googleapis")) {
      if (trip.placePhotoUrl.includes("images.unsplash")) {
        tripCardPhotoUrl =
          trip.placePhotoUrl + "q=90&crop=entropy&cs=tinysrgb&h=400&w=1000&fit=min&auto=format";
      } else if (trip.placePhotoUrl.includes("firebasestorage")) {
        tripCardPhotoUrl = trip.placePhotoUrl;
      }
    }

    const startDate = moment.unix(trip.startDate.seconds).utc();
    const endDate = moment.unix(trip.endDate.seconds).utc();
    let displayedDate;
    if (startDate.isSame(endDate, "day")) {
      displayedDate = startDate.format("MMM Do, YYYY");
    } else {
      displayedDate = startDate.format("MMM D, YYYY") + " - " + endDate.format("MMM D, YYYY");
    }
    return (
      <div
        className="col-md-6 mb-4"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Link
          to={`/t/${trip.id}` + (shouldLinkToPublicView ? "/public" : "")}
          className="text-decoration-none"
        >
          <div
            className="card card-poster gradient-overlay"
            style={{
              minHeight: "200px",
            }}
          >
            <img
              src={tripCardPhotoUrl}
              className="bg-image"
              alt={trip.photo && trip.photo.altText}
            />
            <div className="card-body overlay-content d-flex">
              <div>
                <h6 className="card-title text-shadow text-uppercase">
                  {trip.title}
                  {trip.type && trip.type === "shared_with_you" && (
                    <OverlayTrigger
                      delay={{ show: 500 }}
                      overlay={<Tooltip id="shared-tooltip">Shared with you</Tooltip>}
                    >
                      <i className="fas fa-users ml-2" />
                    </OverlayTrigger>
                  )}
                </h6>
                <p className="card-text text-sm">{displayedDate}</p>
              </div>
              {this.state.isHovering && trip.photo && (
                <div className="ml-auto mt-auto">
                  <OverlayTrigger
                    delay={{ show: 0, hide: 3000 }}
                    overlay={
                      <Tooltip id="attribution-tooltip">
                        Photo by{" "}
                        <a
                          href={trip.photo.userProfileUrl + "?utm_source=Scout&utm_medium=referral"}
                          target="_blank"
                          className="text-light"
                          style={{ textDecoration: "underline" }}
                          onClick={e => {
                            e.stopPropagation();
                          }}
                        >
                          {trip.photo.userFirstName + " " + trip.photo.userLastName}
                        </a>{" "}
                        on{" "}
                        <a
                          href="https://unsplash.com/?utm_source=Scout&utm_medium=referral"
                          target="_blank"
                          className="text-light"
                          style={{ textDecoration: "underline" }}
                          onClick={e => {
                            e.stopPropagation();
                          }}
                        >
                          Unsplash
                        </a>
                      </Tooltip>
                    }
                  >
                    <i className="fas fa-info-circle" />
                  </OverlayTrigger>
                </div>
              )}
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default TripCard;
