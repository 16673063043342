import React from "react";
import Modal from "react-bootstrap/Modal";
import CustomDateRangePicker from "./CustomDateRangePicker";
import { hideAddLodgingModal } from "../store/actions/modalActions";
import { connect } from "react-redux";
import moment from "moment";
import { addLodgingToDates } from "../store/actions/tripActions";
import { removePlaceFromItinerary } from "../store/actions/placeActions";

class AddLodgingModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
    };
  }

  handleDateChange = ({ startDate, endDate }) => {
    // startDate and endDate are Moment objects in local timezones so we need
    // to convert it to UTC while maintaining the same time by converting to UTC
    // then adding the offset in
    const utcStartDate =
      startDate &&
      moment(startDate)
        .utc()
        .add(startDate.utcOffset(), "m");
    const utcEndDate =
      endDate &&
      moment(endDate)
        .utc()
        .add(endDate.utcOffset(), "m");
    this.setState({
      startDate: utcStartDate,
      endDate: utcEndDate,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.startDate && this.state.endDate) {
      this.props.addLodgingToDates(
        this.props.tripId,
        this.props.placeId,
        this.state.startDate,
        this.state.endDate
      );
      this.props.removePlaceFromItinerary(this.props.placeId);
    }
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ startDate: null, endDate: null });
    this.props.hideAddLodgingModal();
  };

  render() {
    return (
      <Modal
        id="task-modal"
        show={this.props.showAddLodgingModal}
        onHide={this.handleClose}
        onEntered={this.handleEntered}
      >
        <form id="rename-form" onSubmit={this.handleSubmit}>
          <Modal.Body>
            <button
              type="button"
              className="close btn btn-round"
              onClick={this.handleClose}
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </button>
            <h3 className="modal-title mb-4">When are you staying here?</h3>
            <p className="text-muted">
              It looks like this place is a type of lodging. Choose the dates you're staying here:
            </p>
            <div className="form-group">
              <CustomDateRangePicker
                startDate={this.state.startDate && this.state.startDate.utc()} // momentPropTypes.momentObj or null,
                startDatePlaceholderText={"Check-in"}
                startDateId="startDate" // PropTypes.string.isRequired,
                endDate={this.state.endDate && this.state.endDate.utc()} // momentPropTypes.momentObj or null,
                endDateId="endDate" // PropTypes.string.isRequired,
                endDatePlaceholderText={"Check-out"}
                initialVisibleMonth={() => moment.unix(this.props.trip.startDate.seconds).utc()}
                numberOfMonths={1}
                onDatesChange={this.handleDateChange} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                required={true}
                block={true}
                hideKeyboardShortcutsPanel={true}
                isOutsideRange={day =>
                  day.isBefore(moment.unix(this.props.trip.startDate.seconds).utc()) ||
                  day.isAfter(moment.unix(this.props.trip.endDate.seconds).utc())
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <a href="#" onClick={this.handleClose} className="text-secondary mr-2">
              I'm not staying here
            </a>
            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    placeId: state.modals.addLodgingPlaceId,
    showAddLodgingModal: state.modals.showAddLodgingModal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideAddLodgingModal: () => dispatch(hideAddLodgingModal()),
    addLodgingToDates: (tripId, placeId, startDate, endDate) =>
      dispatch(addLodgingToDates(tripId, placeId, startDate, endDate)),
    removePlaceFromItinerary: id => dispatch(removePlaceFromItinerary(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddLodgingModal);
