import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updatePlace, starPlace, unstarPlace } from "../store/actions/placeActions";
import * as Sentry from "@sentry/browser";

const PlaceInfoWindowTitle = ({ place, isEditAllowed = false }) => {
  // Default place title to place name if no place title.
  // Some place types like SEARCH_RESULT and POI have no place title, but we want to
  // display the non-clickable "title" design using the place "name" in these cases.
  const existingPlaceTitle = place.title || place.name;
  const [placeTitle, setPlaceTitle] = useState(existingPlaceTitle);
  const [isEditingPlaceTitle, setIsEditingPlaceTitle] = useState(false);
  const dispatch = useDispatch();

  const updateInput = e => {
    setPlaceTitle(e.target.value);
  };

  const handleUnstarPlace = e => {
    e.stopPropagation();
    dispatch(unstarPlace(place.id));
  };

  const handleStarPlace = e => {
    e.stopPropagation();
    dispatch(starPlace(place.id));
    window.analytics.track("Place Starred", {
      placeName: place.name,
      placeId: place.id,
      googlePlaceId: place.googlePlaceId,
    });
  };

  const savePlaceTitle = () => {
    const trimmedPlaceTitle = placeTitle && placeTitle.trim();
    if (trimmedPlaceTitle) {
      // Only update the place in the database if there is some non-empty title
      if (place.id) {
        dispatch(
          updatePlace(place.id, {
            title: trimmedPlaceTitle,
          })
        );
      } else {
        Sentry.withScope(function(scope) {
          scope
            .setExtra("placeId", place.id)
            .setExtra("placeTitle", place.title)
            .setExtra("placeName", place.name)
            .setExtra("isEditAllowed", isEditAllowed)
            .setLevel("warning");
          Sentry.captureMessage("Undefined placeId found when trying to save place title");
        });
      }
      setPlaceTitle(trimmedPlaceTitle);
    } else {
      // Otherwise set the title back to the old title to not let the user wipe the title
      setPlaceTitle(existingPlaceTitle);
    }
    setIsEditingPlaceTitle(false);
  };

  const onEditPlaceTitleBlur = e => {
    savePlaceTitle();
  };

  const onEditPlaceTitleKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      savePlaceTitle();
    }
  };

  const clickableTitle = (
    <div className="d-flex flex-row align-items-start">
      <h6
        className="text-dark mb-2"
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setIsEditingPlaceTitle(true);
        }}
      >
        {placeTitle}
      </h6>
      {place.isStarred ? (
        <a
          title="Starred"
          onClick={e => handleUnstarPlace(e)}
          style={{ cursor: "pointer" }}
          className="text-warning ml-2"
        >
          <i className="fas fa-star fa-lg" />
        </a>
      ) : (
        <a
          title="Star place"
          onClick={e => handleStarPlace(e)}
          style={{ cursor: "pointer" }}
          className="text-muted ml-2"
        >
          <i className="far fa-star fa-lg" />
        </a>
      )}
    </div>
  );

  const nonClickableTitle = <h6 className="text-dark mb-2">{placeTitle}</h6>;

  if (isEditingPlaceTitle) {
    return (
      <input
        autoFocus
        className="form-control form-control-sm mb-2 mt-1"
        id="editPlaceTitle"
        type="text"
        placeholder="Add a title"
        onChange={updateInput}
        onBlur={onEditPlaceTitleBlur}
        value={placeTitle}
        onKeyDown={onEditPlaceTitleKeyDown}
      />
    );
  } else {
    return isEditAllowed ? clickableTitle : nonClickableTitle;
  }
};

export default PlaceInfoWindowTitle;
