const initState = {
  authError: null,
  passwordResetError: null,
  isProUser: false,
  signInCompleteRedirectRoute: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_ERROR":
      console.error(`Login error (${action.err.code}): ${action.err.message}`);
      return {
        ...state,
        authError: action.err.message,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        authError: null,
      };
    case "SIGNOUT_SUCCESS":
      return state;
    case "SIGNUP_SUCCESS":
      return {
        ...state,
        authError: null,
      };
    case "SIGNUP_ERROR":
      console.error(`Signup error (${action.err.code}): ${action.err.message}.`);
      return {
        ...state,
        authError: action.err.message,
      };
    case "PASSWORD_RESET_SUCCESS":
      return {
        ...state,
        passwordResetError: null,
      };
    case "PASSWORD_RESET_ERROR":
      console.error("Error resetting password:", action.email, action.err);
      return {
        ...state,
        passwordResetError: action.err.message,
      };
    case "SIGNIN_UNMOUNTED":
      return {
        ...state,
        authError: null,
      };
    case "SIGNUP_UNMOUNTED":
      return {
        ...state,
        authError: null,
      };
    case "FETCH_AND_SET_SUBSCRIPTION_STATUS":
      return {
        ...state,
        isProUser: action.status,
      };
    case "CLEAR_SUBSCRIPTION_STATUS":
      return {
        ...state,
        isProUser: false,
      };
    case "SET_SIGNIN_COMPLETE_REDIRECT_ROUTE":
      return {
        ...state,
        signInCompleteRedirectRoute: action.signInCompleteRedirectRoute,
      };
    case "CLEAR_SIGNIN_COMPLETE_REDIRECT_ROUTE":
      return {
        ...state,
        signInCompleteRedirectRoute: null,
      };
    default:
      return state;
  }
};

export default authReducer;
