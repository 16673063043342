import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import copy from "clipboard-copy";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  hideShareTripModal,
  showGetViewOnlyLink,
  hideGetViewOnlyLink,
} from "../store/actions/modalActions";
import { inviteUserToTrip, setTripPublic } from "../store/actions/tripActions";
import { withRouter } from "react-router";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { DOMAIN_BASE } from "./Constants";

class ShareTripModal extends Component {
  generateShareUrl = () => {
    return DOMAIN_BASE + "/t/" + this.props.tripId + "/public"; // TODO: Make this more robust
  };

  constructor(props) {
    super(props);
    this.copyShareUrlRef = React.createRef();
    this.copyEditUrlRef = React.createRef();
    this.addCollaboratorRef = React.createRef();
    this.state = {
      shareUrl: this.generateShareUrl(),
      currentCollaboratorEmail: "",
      showInviteSentPopover: false,
    };
  }

  handleClose = () => {
    this.props.hideShareTripModal();
    this.setState({ currentCollaboratorEmail: "" });
    this.props.hideGetViewOnlyLink();
  };

  handleGetViewOnlyLink = () => {
    if (this.props.isGetViewOnlyLinkShown) {
      this.props.hideGetViewOnlyLink();
    } else {
      this.props.showGetViewOnlyLink();
      if (!this.props.trip.isPublic) {
        this.props.setTripPublic(this.props.tripId);
      }
    }
  };

  handleAddCollaborator = e => {
    e.preventDefault();
    const email = this.state.currentCollaboratorEmail;
    const trip = this.props.trip;
    if (email !== "") {
      window.analytics.track("Trip Invite Sent", {
        inviteeEmail: email,
        tripId: trip && trip.id,
        location: trip && trip.location,
        tripTitle: trip && trip.title,
      });
      this.props.inviteUserToTrip(
        this.state.currentCollaboratorEmail,
        this.props.editUrl,
        (trip.photo && trip.photo.url) || trip.placePhotoUrl,
        trip.title,
        this.props.auth.email || "Your friend"
      );
    }
    this.setState({
      currentCollaboratorEmail: "",
    });
  };

  updateCollaboratorInput = e => {
    this.setState({
      currentCollaboratorEmail: e.target.value,
    });
  };

  handleCopyClick = (dataToCopy, reactRef, version) => {
    if (dataToCopy) {
      copy(dataToCopy);

      const trip = this.props.trip;
      window.analytics.track("Trip Link Copied", {
        linkType: version,
        tripId: this.props.tripId,
        location: trip && trip.location,
        tripTitle: trip && trip.title,
      });
    }
    setTimeout(() => {
      if (reactRef && reactRef.current) {
        reactRef.current.hide();
      }
    }, 3000);
  };

  handleShowInviteSentPopover = () => {
    this.setState({ showInviteSentPopover: true });
    setTimeout(() => {
      this.setState({ showInviteSentPopover: false });
    }, 3000);
  };

  render() {
    const { editUrl } = this.props;
    return (
      <div>
        <Modal
          show={this.props.showShareTripModal}
          onHide={this.handleClose}
          onEntered={this.handleEntered}
        >
          <Modal.Body>
            <div className="container">
              <button type="button" className="close btn btn-round" onClick={this.handleClose}>
                <i className="material-icons">close</i>
              </button>
              <h3 className="modal-title mb-3">Share with others</h3>
              {editUrl && (
                <>
                  <form id="add-collaborators-form" onSubmit={this.handleAddCollaborator}>
                    <div className="form-group">
                      <label htmlFor="inviteEmail">Invite to edit</label>
                      <div className="input-group mb-3">
                        <input
                          type="email"
                          className="form-control"
                          id="inviteEmail"
                          placeholder="example@scoutexplore.com"
                          value={this.state.currentCollaboratorEmail}
                          onChange={this.updateCollaboratorInput}
                        />
                        <div className="input-group-append">
                          <OverlayTrigger
                            placement="top"
                            trigger="click"
                            show={this.state.showInviteSentPopover}
                            onHide={() => this.setState({ showInviteSentPopover: false })}
                            overlay={<Tooltip>Invite sent</Tooltip>}
                          >
                            <button
                              className={
                                this.state.currentCollaboratorEmail
                                  ? "btn btn-primary btn-small"
                                  : "btn btn-outline-muted btn-small"
                              }
                              disabled={!this.state.currentCollaboratorEmail}
                              onClick={this.handleShowInviteSentPopover}
                              type="submit"
                              id="button-add-collaborator"
                            >
                              Invite
                            </button>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  </form>
                  <hr />
                </>
              )}
              {editUrl &&
                CopyAndDisplayUrlComponent(
                  "edit",
                  editUrl,
                  this.copyEditUrlRef,
                  this.handleCopyClick
                )}
              <a href="#" className="text-primary mt-2" onClick={this.handleGetViewOnlyLink}>
                <p>
                  <i className="fas fa-link mr-2" />
                  Get view only link
                </p>
              </a>
              {this.props.isGetViewOnlyLinkShown &&
                CopyAndDisplayUrlComponent(
                  "share",
                  this.state.shareUrl,
                  this.copyShareUrlRef,
                  this.handleCopyClick
                )}
            </div>
          </Modal.Body>
          {!this.state.currentCollaboratorEmail && (
            <Modal.Footer>
              <button type="button" className="btn btn-primary" onClick={this.handleClose}>
                Done
              </button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    );
  }
}

const CopyAndDisplayUrlComponent = (version, url, urlRef, onCopyClick) => {
  const getDescription = () => {
    switch (version) {
      case "edit":
        return (
          <small>
            <strong>Can Edit</strong> - People with this link can edit your trip. No sign up
            required.
          </small>
        );
      case "share":
        return (
          <small>
            <strong>Can View</strong> - People with this link can't edit your trip •{" "}
            <a href={url} rel="noopener noreferrer" target="_blank">
              Preview
            </a>
          </small>
        );
    }
  };
  return (
    <div>
      <div className="form-group">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            aria-label="Link to edit"
            value={url}
            readOnly
          />
          <div className="input-group-append">
            <OverlayTrigger
              placement="top"
              trigger="click"
              ref={urlRef}
              overlay={<Tooltip>Link copied</Tooltip>}
            >
              <button
                className="btn btn-outline-muted btn-small"
                onClick={() => onCopyClick(url, urlRef, version)}
                type="button"
              >
                Copy
              </button>
            </OverlayTrigger>
          </div>
        </div>
        {getDescription(version)}
      </div>
    </div>
  );
};

const generateEditUrl = (tokenDocs, tripId) => {
  if (tokenDocs && tokenDocs.length > 0) {
    if (tokenDocs.length > 1) {
      throw "Should only have one token for the trip!";
    }
    const tokenDoc = tokenDocs[0];
    return DOMAIN_BASE + "/t/" + tripId + "/edit?t=" + tokenDoc.token;
  }
  return "";
};

const mapStateToProps = (state, ownProps) => {
  return {
    showShareTripModal: state.modals.showShareTripModal,
    isGetViewOnlyLinkShown: state.modals.isGetViewOnlyLinkShown,
    editUrl: generateEditUrl(state.firestore.ordered.tripCollaboratorTokens, ownProps.tripId),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideShareTripModal: () => dispatch(hideShareTripModal()),
    showGetViewOnlyLink: () => dispatch(showGetViewOnlyLink()),
    hideGetViewOnlyLink: () => dispatch(hideGetViewOnlyLink()),
    inviteUserToTrip: (email, tripUrl, tripPhotoUrl, tripTitle, senderEmail) =>
      dispatch(inviteUserToTrip(email, tripUrl, tripPhotoUrl, tripTitle, senderEmail)),
    setTripPublic: tripId => dispatch(setTripPublic(tripId)),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    return [
      {
        collection: "trips",
        doc: props.tripId,
        subcollections: [{ collection: "collaboratorTokens", doc: props.tripId }],
        storeAs: "tripCollaboratorTokens",
      },
    ];
  })
)(ShareTripModal);
