import React, { Component } from "react";
import { connect } from "react-redux";
import PlaceNotes from "./PlaceNotes";
import PlaceInfoListSelect from "./PlaceInfoListSelect";
import PlaceInfoWindowTitle from "./PlaceInfoWindowTitle";
import {
  deletePlace,
  updatePlace,
  fetchTemporaryGooglePhotos,
} from "../store/actions/placeActions";
import { hideInfoWindow } from "../store/actions/mapActions";
import {
  showAddToItineraryModal,
  showGalleryModal,
  showListPickerModal,
} from "../store/actions/modalActions";
import moment from "moment";
import { YELP_SUPPORTED_COUNTRIES, TABELOG_SUPPORTED_COUNTRIES } from "./Constants";

class PlaceInfoWindow extends Component {
  handleDelete = () => {
    this.props.hideInfoWindow();
    this.props.deletePlace(this.props.place.id, this.props.place.listId);
  };

  handleShowAddToItinerary = () => {
    this.props.showAddToItineraryModal(this.props.place);
  };

  handleAddToItinerary = () => {
    const { place, overlayRouteDayIndex } = this.props;
    if (place) {
      const itineraryPlaceIndex = this.props.orderedPlaces.filter(
        place => place.dayIndex === overlayRouteDayIndex
      ).length;

      this.props.updatePlace(place.id, {
        dayIndex: overlayRouteDayIndex,
        itineraryPlaceIndex: itineraryPlaceIndex,
      });

      this.props.hideInfoWindow();
    }
  };

  render() {
    const {
      place,
      type,
      isPublic,
      isOverlayRouteVisible,
      overlayRouteDayIndex,
      trip,
      lists,
    } = this.props;
    if (!lists) {
      return;
    }

    const starRating = calculateStarRating(place.rating);
    const priceLevel = place.priceLevel && calculatePriceLevel(place.priceLevel);
    const placeAdded = type === "LIST";
    let formatted_website = "";
    let usa_adapted_phone = "";
    let yelpLink = "";
    let tripAdvisorLink = "";
    let tabelogLink = "";

    if (place.website) {
      // Only display the domain of the url (e.g., www.lacolombe.com)
      var a = document.createElement("a");
      a.setAttribute("href", place.website);
      formatted_website = a.hostname;
    }

    // Show the international phone number if it's not a US number (+1)
    if (place.phone && place.formattedPhone) {
      const phone_reg = /\+1\s.+/;
      usa_adapted_phone = phone_reg.test(place.phone) ? place.formattedPhone : place.phone;
    }

    // Show links to the Yelp and TripAdvisor page
    if (place.addressComponents && place.addressComponents.city !== "" && place.name) {
      const searchBaseUrl =
        "https://www.google.com/search?source=hp&hl=en&btnI=I%27m%20Feeling%20Lucky&q=";
      tripAdvisorLink =
        searchBaseUrl +
        encodeURIComponent(
          "site:tripadvisor.com" + " " + place.name + " " + place.addressComponents.city
        );
      if (YELP_SUPPORTED_COUNTRIES.includes(place.addressComponents.country)) {
        yelpLink =
          searchBaseUrl +
          encodeURIComponent(
            "site:yelp.com" +
              " " +
              place.name +
              " " +
              place.addressComponents.streetNumber +
              " " +
              place.addressComponents.streetName +
              " " +
              place.addressComponents.city
          );
      }
      if (
        TABELOG_SUPPORTED_COUNTRIES.includes(place.addressComponents.country) &&
        place.types.some(item => ["food", "restaurant", "meal_takeaway"].includes(item))
      ) {
        tabelogLink =
          searchBaseUrl +
          encodeURIComponent(
            "site:tabelog.com" +
              " " +
              place.name +
              " " +
              place.addressComponents.streetNumber +
              " " +
              place.addressComponents.streetName +
              " " +
              place.addressComponents.city
          );
      }
    }

    let placeDateString = "";
    const startDate = moment.unix(trip.startDate.seconds);
    if (place.dayIndex >= 0) {
      placeDateString = moment(startDate)
        .utc()
        .add(place.dayIndex, "days")
        .format("ddd M/D");
    }

    let listName = "";
    let matchingList = lists.find(list => {
      return list.id === place.listId;
    });
    if (matchingList) {
      listName = matchingList.title;
    }

    return (
      <>
        <div className="d-flex">
          <div className="flex-grow-1">
            <PlaceInfoWindowTitle place={place} isEditAllowed={!isPublic && type === "LIST"} />
            {place.title && place.name !== place.title && (
              // Show the place name only if the title has been renamed manually to differ from the name,
              // and the place title actually exists. For some types like SEARCH_RESULT, place title won't exist.
              <div className="text-sm text-muted" style={{ fontWeight: 500 }}>
                {place.name}
              </div>
            )}
            {(place.rating || place.totalRatings) && (
              <div className="d-flex">
                {place.rating && (
                  <div className="text-muted text-sm">
                    {parseFloat(Math.round(place.rating * 10) / 10).toFixed(1) + " "}
                    &nbsp;&nbsp;
                  </div>
                )}
                {place.rating && (
                  <div className="text-xs" style={{ marginTop: "2px" }}>
                    {starRating}
                  </div>
                )}
                &nbsp;&nbsp;
                {place.totalRatings && (
                  <div className="text-muted text-sm">
                    {"(" + place.totalRatings.toLocaleString() + ")"}
                  </div>
                )}
                {priceLevel && (
                  <div className="text-xs" style={{ marginTop: "2px" }}>
                    &nbsp;&nbsp;{"·"}&nbsp;&nbsp;{priceLevel}
                  </div>
                )}
              </div>
            )}
            <div>
              <a
                className="text-sm text-muted"
                href={
                  place.googleMapsUrl ||
                  "https://www.google.com/maps/search/?api=1&query=" +
                    encodeURIComponent(place.address)
                }
                target="_blank"
              >
                {place.address}
              </a>
            </div>
            {place.phone && (
              <a className="text-sm text-muted" href={`tel:${place.phone}`}>
                {usa_adapted_phone}
              </a>
            )}
            {place.phone && place.website && `  ·  `}
            {place.website && (
              <a className="text-sm text-muted" href={place.website} target="_blank">
                {formatted_website}
              </a>
            )}
            {(place.phone || place.website) &&
              (yelpLink || tripAdvisorLink || place.googleMapsUrl) &&
              `  ·  `}
            {yelpLink !== "" && (
              <a className="text-sm mr-2" href={yelpLink} target="_blank">
                <i className="fab fa-yelp" style={{ color: "#d32323" }} />
              </a>
            )}
            {tabelogLink !== "" && (
              <a
                className="text-sm mr-2"
                href={tabelogLink}
                target="_blank"
                style={{ color: "#F19D42" }}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/tabelog_logo.png?alt=media&token=f1305278-a888-49d2-9322-060f55a25aac"
                  height="20"
                  title="Tabelog"
                  alt="Tabelog logo"
                />
              </a>
            )}
            {tripAdvisorLink !== "" && (
              <a className="text-sm text-muted mr-2" href={tripAdvisorLink} target="_blank">
                <i
                  className="fab fa-tripadvisor"
                  style={{ color: "#00a680" }}
                  title="Tripadvisor"
                />
              </a>
            )}
            {place.googleMapsUrl && (
              <a className="text-sm text-muted" href={place.googleMapsUrl} target="_blank">
                <i className="fab fa-google" style={{ color: "#4285F4" }} title="Google Maps" />
              </a>
            )}
            {place.openingHours && place.openingHours.length > 0 && (
              <div>
                <details>
                  <summary style={{ outline: "none" }} className="text-sm text-muted">
                    Hours
                  </summary>
                  <table>
                    <tbody>
                      {place.openingHours.map(hour => (
                        <tr key={hour.split(/:(.+)/)[0]}>
                          <td>
                            <small className="text-sm form-text text-muted ml-3">
                              {hour.split(/:(.+)/)[0]}
                            </small>
                          </td>
                          <td>
                            <small className="text-sm form-text text-muted ml-3">
                              {hour.split(/:(.+)/)[1]}
                            </small>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </details>
              </div>
            )}
          </div>
          {place.thumbnailImgUrl && (
            <div
              style={{ cursor: "pointer" }}
              className="ml-auto pl-3"
              onClick={() => {
                if (!place.placePhotos) {
                  this.props.fetchTemporaryGooglePhotos(place.googlePlaceId, type);
                }
                this.props.showGalleryModal(place.id);
              }}
            >
              <img
                src={place.thumbnailImgUrl}
                className="rounded"
                alt="Picture of the place"
                style={{ width: 80, height: 80, objectFit: "cover" }}
              />
            </div>
          )}
        </div>
        {listName && (
          <div className="mt-2 d-flex">
            <a
              href={isPublic ? undefined : "#"}
              onClick={isPublic ? undefined : () => this.props.showListPickerModal(place.id)}
              className="mr-5 text-sm"
            >
              <i className="fa fa-bookmark mr-2" />
              {listName}
            </a>
          </div>
        )}
        {placeAdded && <PlaceNotes notes={place.notes} placeId={place.id} />}
        {!isPublic &&
          (placeAdded ? (
            <div className="mt-3 d-flex align-items-center text-sm">
              {place.dayIndex >= 0 ? (
                <div>
                  <a href="#" onClick={this.handleShowAddToItinerary} className="text-success">
                    <i className="fa fa-calendar-check mr-2" />
                    Added to {placeDateString}
                  </a>
                </div>
              ) : isOverlayRouteVisible ? (
                <div>
                  <div>
                    <a href="#" onClick={this.handleAddToItinerary} className="text-primary">
                      <i className="fas fa-calendar-plus" />
                      &nbsp;Add to{" "}
                      {moment(startDate)
                        .add(overlayRouteDayIndex, "days")
                        .utc()
                        .format("ddd M/D")}
                    </a>
                  </div>
                  <div className="mt-1">
                    <a href="#" onClick={this.handleShowAddToItinerary} className="text-secondary">
                      <i className="fa fa-calendar-alt" />
                      &nbsp;Add to a different day
                    </a>
                  </div>
                </div>
              ) : (
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    onClick={this.handleShowAddToItinerary}
                  >
                    <i className="fa fa-calendar-alt" />
                    &nbsp;Add to itinerary
                  </button>
                </div>
              )}
              <a
                href="#"
                onClick={this.handleDelete}
                title="Delete place"
                className="text-body ml-auto"
                id="delete-place"
              >
                <i className="fas fa-trash mr-2" />
              </a>
            </div>
          ) : (
            <div className="mt-4">
              <PlaceInfoListSelect place={place} type={type} />
            </div>
          ))}
      </>
    );
  }
}

const roundRating = rating => {
  const x = rating * 10;
  const result = x % 5 >= 2.5 ? parseInt(x / 5) * 5 + 5 : parseInt(x / 5) * 5;
  return result / 10;
};

const calculateStarRating = rating => {
  let star_ratings = [];
  const roundedRating = roundRating(rating);
  for (let i = 0; i < Math.floor(roundedRating); i++) {
    star_ratings.push(<i key={"star" + i} className="fa fa-star text-warning" />);
  }
  if ((roundedRating % 1).toFixed(1) * 10 > 0) {
    star_ratings.push(<i key={"star_half"} className="fa fa-star-half-alt text-warning" />);
  }
  for (let j = 0; j < 5 - Math.ceil(roundedRating); j++) {
    star_ratings.push(<i key={"empty_star" + j} className="far fa-star text-warning" />);
  }
  return star_ratings;
};

const calculatePriceLevel = price => {
  let price_level = [];
  for (let j = 0; j < price; j++) {
    price_level.push(<i className="fa fa-dollar-sign text-muted" key={j} />);
  }
  return price_level;
};

const mapStateToProps = state => {
  return {
    isPublic: state.trips.isPublic,
    orderedPlaces: state.firestore.ordered.places,
    isOverlayRouteVisible: state.map.isOverlayRouteVisible,
    overlayRouteDayIndex: state.map.overlayRouteDayIndex,
    trip: state.firestore.data.currentTrip,
    lists: state.firestore.ordered.lists,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deletePlace: (id, listId) => dispatch(deletePlace(id, listId)),
    hideInfoWindow: () => dispatch(hideInfoWindow()),
    showAddToItineraryModal: place => dispatch(showAddToItineraryModal(place)),
    showGalleryModal: placeId => dispatch(showGalleryModal(placeId)),
    showListPickerModal: placeId => dispatch(showListPickerModal(placeId)),
    updatePlace: (id, place) => {
      dispatch(updatePlace(id, place));
    },
    fetchTemporaryGooglePhotos: (googlePlaceId, type) =>
      dispatch(fetchTemporaryGooglePhotos(googlePlaceId, type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceInfoWindow);
