import React from "react";
import { connect } from "react-redux";
import { Droppable } from "react-beautiful-dnd";
import ItineraryPlaceCard from "./ItineraryPlaceCard";
import { deleteDay, clearLodgingForDay } from "../store/actions/tripActions";
import { showDayInfoModal } from "../store/actions/modalActions";
import { showInfoWindow, showOverlayRoute, hideOverlayRoute } from "../store/actions/mapActions";

class ItineraryPlaceList extends React.Component {
  handleDeleteDay = () => {
    if (this.props.tripId && this.props.dayIndex !== null) {
      this.props.deleteDay(this.props.tripId, this.props.dayIndex);
    }
    this.props.hideOverlayRoute();
  };

  handleClearLodging = () => {
    if (this.props.tripId && this.props.dayIndex !== null) {
      this.props.clearLodgingForDay(this.props.tripId, this.props.dayIndex);
    }
  };

  handleDayClick = dayIndex => {
    if (this.props.isOverlayRouteVisible && dayIndex === this.props.overlayRouteDayIndex) {
      this.props.hideOverlayRoute();
    } else {
      this.props.showOverlayRoute(dayIndex);
    }
  };

  render() {
    const {
      date,
      places,
      dayIndex,
      dayDescription,
      lodgingPlaceId,
      placesMap,
      isPublic,
      overlayRouteDayIndex,
      isOverlayRouteVisible,
      trip,
    } = this.props;
    let sortedPlaces = [];

    // Sort the sortedPlaces by placeIndex
    // Need this until redux-firestore bug is fixed (https://github.com/prescottprue/redux-firestore/issues/121)
    if (places) {
      sortedPlaces = places.slice();

      sortedPlaces.sort(function(a, b) {
        var x = a["itineraryPlaceIndex"];
        var y = b["itineraryPlaceIndex"];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    }

    return (
      <div
        className="d-flex flex-column mb-3 mt-2"
        style={
          isOverlayRouteVisible && overlayRouteDayIndex === dayIndex
            ? { borderLeft: "5px solid #4e66f8", paddingLeft: "10px" }
            : undefined
        }
      >
        <div className="d-flex align-items-center">
          <h6 style={{ cursor: "pointer" }} onClick={() => this.handleDayClick(dayIndex)}>
            {dayIndex + 1}. {date.utc().format("ddd M/D")}
          </h6>
          {!isPublic && (
            <div className="dropdown d-inline mb-2 ml-auto mr-2">
              <a
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v text-muted" />
              </a>
              <div className="dropdown-menu mt-3" aria-labelledby="dropdownMenuLink">
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => this.props.showDayInfoModal(dayIndex)}
                >
                  {dayDescription ? "Edit Day Notes" : "Add Day Notes"}
                </a>
                {lodgingPlaceId && placesMap && placesMap[lodgingPlaceId] && (
                  <a className="dropdown-item" href="#" onClick={this.handleClearLodging}>
                    Clear Lodging
                  </a>
                )}
                <a
                  className={trip.numDays <= 1 ? "dropdown-item disabled" : "dropdown-item"}
                  href="#"
                  onClick={this.handleDeleteDay}
                >
                  Delete Day
                </a>
              </div>
            </div>
          )}
        </div>
        {dayDescription && (
          <small
            className="mb-2 mt-n2 text-muted"
            style={isPublic ? undefined : { cursor: "pointer" }}
            onClick={() => !isPublic && this.props.showDayInfoModal(dayIndex)}
          >
            <i className="fas fa-sticky-note" />
            &nbsp;
            {dayDescription}
          </small>
        )}
        {lodgingPlaceId && placesMap && placesMap[lodgingPlaceId] && (
          <small
            className="mb-2 mt-n2 text-muted"
            style={{ cursor: "pointer" }}
            onClick={() => this.props.showInfoWindow(lodgingPlaceId)}
          >
            <i className="fas fa-bed" />
            &nbsp; {placesMap[lodgingPlaceId].title}
          </small>
        )}

        <Droppable droppableId={dayIndex.toString()} type="LIST">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="row"
              style={{ minHeight: "1rem" }}
            >
              {(sortedPlaces && sortedPlaces.length > 0) || snapshot.isDraggingOver ? (
                sortedPlaces.map((place, index) => {
                  return <ItineraryPlaceCard key={place.id} place={place} index={index} />;
                })
              ) : (
                <div className="col-12">
                  <p
                    className="text-muted text-sm"
                    style={
                      isPublic
                        ? { cursor: "default" }
                        : {
                            border: "1px dashed",
                            borderRadius: ".4rem",
                            padding: ".2rem .8rem",
                            cursor: "default",
                          }
                    }
                  >
                    {isPublic ? "Nothing planned" : "Drag a place here to add it"}
                  </p>
                </div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    trip: state.firestore.data.currentTrip,
    placesMap: state.firestore.data.places,
    isPublic: state.trips.isPublic,
    isOverlayRouteVisible: state.map.isOverlayRouteVisible,
    overlayRouteDayIndex: state.map.overlayRouteDayIndex,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteDay: (tripId, dayIndex) => dispatch(deleteDay(tripId, dayIndex)),
    showDayInfoModal: dayIndex => dispatch(showDayInfoModal(dayIndex)),
    showInfoWindow: placeId => dispatch(showInfoWindow(placeId)),
    clearLodgingForDay: (tripId, dayIndex) => dispatch(clearLodgingForDay(tripId, dayIndex)),
    showOverlayRoute: dayIndex => dispatch(showOverlayRoute(dayIndex)),
    hideOverlayRoute: () => dispatch(hideOverlayRoute()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItineraryPlaceList);
