import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import SignIn from "./SignIn";
import { useSelector } from "react-redux";

const SignInPage = () => {
  const auth = useSelector(state => state.firebase.auth);

  useEffect(() => {
    document.title = "Sign In - Scout";
    window.analytics.page("Sign In");
  }, []);

  if (auth.uid && !auth.isAnonymous) {
    return <Redirect to="/home" />;
  }

  return (
    <div className="container">
      <div className="row justify-content-center pt-4">
        <div className="col-xl-5 col-lg-6 col-md-7 card border-0">
          <div className="card-body py-5">
            <SignIn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
