import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { updateTripNotes } from "../store/actions/tripActions";
import Notes from "./Notes";

const TripNotes = props => {
  const isPublic = useSelector(state => state.trips.isPublic);
  const dispatch = useDispatch();
  const { notes, tripId } = props;
  // Open notes dropdown by default
  const [isNotesOpen, setIsNotesOpen] = useState(true);

  const saveTripNotes = notes => {
    dispatch(updateTripNotes(tripId, notes));
  };

  if (isPublic && !notes) {
    return null;
  }

  return (
    <>
      <hr />
      <div className="mt-3">
        <div className="mb-2">
          <h4 style={{ cursor: "pointer" }} onClick={() => setIsNotesOpen(!isNotesOpen)}>
            {isNotesOpen ? (
              <i className="fas fa-chevron-down fa-sm mr-2" />
            ) : (
              <i className="fas fa-chevron-right fa-sm mr-2" />
            )}
            Notes
          </h4>
        </div>
        <Collapse in={isNotesOpen}>
          <div>
            <Notes existingNotes={notes} saveNotes={saveTripNotes} />
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default TripNotes;
