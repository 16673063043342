import React from "react";
import { DateRangePicker } from "react-dates";
import MediaQuery from "react-responsive";
import { MAX_WIDTH_OF_TABLET } from "./Constants";

const CustomDateRangePicker = props => (
  <MediaQuery maxWidth={MAX_WIDTH_OF_TABLET}>
    {isTabletOrSmaller => (
      <DateRangePicker
        numberOfMonths={isTabletOrSmaller ? 1 : 2}
        readOnly={true}
        minimumNights={0}
        {...props}
      />
    )}
  </MediaQuery>
);

export default CustomDateRangePicker;
