import React from "react";
import { Link } from "react-router-dom";
import { chooseAuthorPhotoToShow, chooseAuthorToShow } from "../utils/TripsUtil";

// Maximum number of characters to show in the notes of the Explore trip card before truncation
const MAX_TRIP_CARD_NOTES_CHARACTERS = 120;

const ExploreTripCard = ({ trip, tripAuthor }) => {
  const authorName = chooseAuthorToShow(trip, tripAuthor);
  const authorPhotoSrc = chooseAuthorPhotoToShow(trip, tripAuthor);
  // Default picture: https://unsplash.com/photos/rf6ywHVkrlY
  let tripCardPhotoUrl =
    "https://images.unsplash.com/photo-1436491865332-7a61a109cc05?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&q=90&crop=entropy&cs=tinysrgb&h=400&w=1000&fit=min";

  if (trip.photo?.url) {
    if (trip.photo.url.includes("images.unsplash")) {
      tripCardPhotoUrl =
        trip.photo.url + "q=90&crop=entropy&cs=tinysrgb&h=400&w=700&fit=min&auto=format";
    } else {
      tripCardPhotoUrl = trip.photo.url;
    }
    // Legacy data model had trip.placePhotoUrl, have since moved to trip.photo object.
    // Leaving this here so old trips continue to load the picture.
  } else if (trip.placePhotoUrl && !trip.placePhotoUrl.includes("maps.googleapis")) {
    if (trip.placePhotoUrl.includes("images.unsplash")) {
      tripCardPhotoUrl =
        trip.placePhotoUrl + "q=90&crop=entropy&cs=tinysrgb&h=400&w=700&fit=min&auto=format";
    } else if (trip.placePhotoUrl.includes("firebasestorage")) {
      tripCardPhotoUrl = trip.placePhotoUrl;
    }
  }

  return (
    <div className="col-sm-6 col-lg-4 mb-4">
      <Link to={`/t/${trip.id}/public`} className="text-decoration-none" target="_blank">
        <div className="card h-100 border-0 shadow">
          <div className="card-img-top overflow-hidden gradient-overlay">
            <img
              src={tripCardPhotoUrl}
              className="img-fluid"
              alt={trip.photo && trip.photo.altText}
            />
            {authorPhotoSrc && authorName && (
              <div className="card-img-overlay-bottom z-index-20">
                <div className="media text-white text-sm align-items-center">
                  <img
                    src={authorPhotoSrc}
                    alt="Trip author"
                    className="avatar avatar-border-white mr-2"
                  />
                  <div>{authorName}</div>
                </div>
              </div>
            )}
          </div>

          <div className="card-body d-flex">
            <div className="w-100">
              <div className="d-flex card-subtitle mb-1 text-muted text-sm">
                <p className="flex-grow-1 mb-0">
                  {trip.location}
                  {" · "}
                  {trip.numDays > 1 ? `${trip.numDays} days` : `${trip.numDays} day`}
                </p>
                {trip.numViews > 0 && (
                  <p className="flex-shrink-0 ml-2 mb-0 text-right">
                    <i className="fas fa-eye mr-1" />
                    <span>{trip.numViews}</span>
                  </p>
                )}
              </div>
              <h6 className="card-title text-dark">{trip.title}</h6>
              {trip.notes && (
                <div className="text-sm text-muted mb-1">
                  {trip.notes.length > MAX_TRIP_CARD_NOTES_CHARACTERS
                    ? trip.notes.substring(0, MAX_TRIP_CARD_NOTES_CHARACTERS) + "..."
                    : trip.notes}
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ExploreTripCard;
