import React, { useState } from "react";
import { TRIP_VIEW_PERMISSION } from "./Constants";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { hidePublishingSettingsModal } from "../store/actions/modalActions";
import { updateTripViewPermission } from "../store/actions/tripActions";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import copy from "clipboard-copy";

const PublishingSettingsModal = ({ trip }) => {
  const tripId = trip && trip.id;
  const shouldShowPublishingSettingsModal = useSelector(
    state => state.modals.shouldShowPublishingSettingsModal
  );
  const [viewPermission, setViewPermission] = useState(null);
  const [showLinkCopiedPopover, setShowLinkCopiedPopover] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hidePublishingSettingsModal());
  };

  const handleCopyClick = () => {
    copy(window.location.origin + `/t/${tripId}/public`);
    window.analytics.track("Trip Link Copied", {
      linkType: "publish",
      tripId: tripId,
      location: trip?.location,
      tripTitle: trip?.title,
    });
    setShowLinkCopiedPopover(true);
    setTimeout(() => {
      setShowLinkCopiedPopover(false);
    }, 3000);
  };

  const handleEnter = () => {
    setViewPermission(trip.viewPermission || TRIP_VIEW_PERMISSION.PRIVATE);
  };

  const handleEntered = () => {
    window.analytics.page("Publishing Settings Modal");
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (trip.viewPermission !== viewPermission) {
      if (trip.viewPermission === TRIP_VIEW_PERMISSION.PRIVATE) {
        window.analytics.track("Button Clicked", {
          buttonType: "Publish-Private",
          pageType: "Publishing Settings Modal",
        });
      }
      dispatch(updateTripViewPermission(tripId, viewPermission));
    }
    handleClose();
  };

  const handleViewPublishedTripClick = e => {
    window.analytics.track("Button Clicked", {
      buttonType: "View Published Trip",
      pageType: "Publishing Settings Modal",
    });
  };

  return (
    <Modal
      id="publish-settings-modal"
      show={shouldShowPublishingSettingsModal}
      onHide={handleClose}
      onEnter={handleEnter}
      onEntered={handleEntered}
    >
      <form id="publish-settings-form" onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="container">
            <button
              type="button"
              className="close btn btn-round"
              onClick={handleClose}
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </button>
            <div>
              <h3 className="modal-title">Publishing Settings</h3>
              <div className="d-flex my-3">
                <OverlayTrigger
                  placement="top"
                  trigger="click"
                  show={showLinkCopiedPopover}
                  onHide={() => setShowLinkCopiedPopover(false)}
                  overlay={<Tooltip>Link copied</Tooltip>}
                >
                  <button
                    className="btn btn-primary flex-grow-1"
                    type="button"
                    onClick={handleCopyClick}
                  >
                    <i className="fas fa-link" /> Copy trip link
                  </button>
                </OverlayTrigger>
                <Link
                  className="btn btn-outline-primary ml-2"
                  to={`/t/${tripId}/public`}
                  target="_blank"
                  onClick={handleViewPublishedTripClick}
                >
                  <i className="fas fa-eye" /> View Trip
                </Link>
              </div>
            </div>

            <div className="form-group">
              <div className="custom-control custom-radio">
                <input
                  className="custom-control-input"
                  type="radio"
                  name="viewPermissionGroup"
                  id="publicPermissionRadioOption"
                  value={TRIP_VIEW_PERMISSION.PUBLIC}
                  checked={viewPermission === TRIP_VIEW_PERMISSION.PUBLIC}
                  onChange={() => setViewPermission(TRIP_VIEW_PERMISSION.PUBLIC)}
                />
                <label className="custom-control-label" htmlFor="publicPermissionRadioOption">
                  <span className="font-weight-bold">
                    <i className="fas fa-globe-americas mr-2" />
                    Public
                  </span>
                  <p className="text-muted">Anyone on Scout will be able to view this trip</p>
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  className="custom-control-input"
                  type="radio"
                  name="viewPermissionGroup"
                  id="privatePermissionRadioOption"
                  value={TRIP_VIEW_PERMISSION.PRIVATE}
                  checked={viewPermission === TRIP_VIEW_PERMISSION.PRIVATE}
                  onChange={() => setViewPermission(TRIP_VIEW_PERMISSION.PRIVATE)}
                />
                <label className="custom-control-label" htmlFor="privatePermissionRadioOption">
                  <span className="font-weight-bold">
                    <i className="fas fa-lock mr-2" />
                    Private
                  </span>
                  <p className="text-muted">
                    Only you and people you've shared this trip with can view
                  </p>
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer align-items-center">
          <button className="btn btn-primary" type="submit">
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default PublishingSettingsModal;
