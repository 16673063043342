import React from "react";
import { connect } from "react-redux";
import { Droppable } from "react-beautiful-dnd";
import {
  showRenameListForm,
  showDeleteListConfirmationModal,
  showReorderListsModal,
} from "../store/actions/modalActions";
import { setListChecked, setListUnchecked } from "../store/actions/listActions";
import PlaceCard from "./PlaceCard";
import AddPlaceInput from "./AddPlaceInput";
import { COLOR_MARKER_MAP, MARKER_TYPES, getMarkerFromListIndex } from "./markerColors";

class PlaceList extends React.Component {
  handleShowDeleteListConfirmation = () => {
    this.props.showDeleteListConfirmationModal(this.props.listId);
  };

  handleShowRenameListForm = () => {
    this.props.showRenameListForm(this.props.listId, this.props.title, this.props.color);
  };

  handleToggleList = listId => {
    if (this.props.uncheckedLists.has(listId)) {
      this.props.setListChecked(listId);
    } else {
      this.props.setListUnchecked(listId);
    }
  };

  render() {
    const {
      auth,
      title,
      color,
      listId,
      listIndex,
      trip,
      isPublic,
      uncheckedLists,
      isProUser,
    } = this.props;
    let places = [];

    const listIsVisible = !uncheckedLists.has(listId);

    // Sort the places by placeIndex
    // Need this until redux-firestore bug is fixed (https://github.com/prescottprue/redux-firestore/issues/121)
    if (this.props.places) {
      places = this.props.places.slice();

      places.sort(function(a, b) {
        var x = a["placeIndex"];
        var y = b["placeIndex"];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    }

    let markerColorUrl = getMarkerFromListIndex(
      listIndex ?? listId.charCodeAt(0),
      MARKER_TYPES.FILLED
    )?.url;

    if (color && color in COLOR_MARKER_MAP) {
      markerColorUrl = COLOR_MARKER_MAP[color].filledMarker.url;
    }
    return (
      <>
        <hr className="mt-3" />
        <div className="d-flex align-items-center mb-2">
          <div className="custom-control custom-checkbox mb-2">
            <input
              id={"list-checkbox-" + listIndex}
              type="checkbox"
              className="custom-control-input"
              onChange={() => this.handleToggleList(listId)}
              checked={listIsVisible}
            />
            <label htmlFor={"list-checkbox-" + listIndex} className="custom-control-label" />
          </div>
          <h4
            className={!listIsVisible ? "text-muted" : undefined}
            style={{ cursor: "pointer" }}
            onClick={isPublic ? () => this.handleToggleList(listId) : this.handleShowRenameListForm}
          >
            {this.props.title}{" "}
            {listIsVisible && (
              <img src={markerColorUrl} alt="marker" style={{ height: "24px", width: "24px" }} />
            )}
          </h4>
          {!isPublic && (
            <div className="dropdown d-inline mb-2 ml-auto mr-2">
              <a
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v text-muted" />
              </a>
              <div className="dropdown-menu mt-3" aria-labelledby="dropdownMenuLink">
                <a className="dropdown-item" href="#" onClick={this.handleShowRenameListForm}>
                  Edit
                </a>
                <a className="dropdown-item" href="#" onClick={this.props.showReorderListsModal}>
                  Reorder
                </a>
                <a
                  className="dropdown-item text-danger"
                  href="#"
                  onClick={this.handleShowDeleteListConfirmation}
                >
                  Delete List
                </a>
              </div>
            </div>
          )}
        </div>
        {listIsVisible && !isPublic && !isProUser && title == "Accommodations" && (
          <div className={places && places.length > 0 ? "mb-3" : "mb-2"}>
            <a
              href="https://www.booking.com/index.html?aid=8055295"
              target="_blank"
              rel="noopener noreferrer"
              className="text-secondary"
            >
              <i className="fas fa-bed mr-2" />
              Find hotels on Booking.com
              <i className="ml-2 fas fa-external-link-alt fa-sm"></i>
            </a>
          </div>
        )}
        {listIsVisible && (
          <Droppable
            droppableId={listId}
            type="LIST"
            isDropDisabled={this.props.isPlaceListDropDisabled}
          >
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="row"
                style={{ minHeight: "1rem" }}
              >
                {places &&
                  places.length > 0 &&
                  places.map((place, index) => {
                    return (
                      <PlaceCard
                        key={place.id}
                        place={place}
                        listId={listId}
                        index={index}
                        notes={place.notes}
                      />
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
        {listIsVisible && !isPublic && (
          <div className={places && places.length > 0 ? "mt-2" : "mt-n2"}>
            {listId && trip && (
              <div className="row">
                <div className="col-sm-12">
                  <AddPlaceInput listId={listId} trip={trip} numPlaces={places.length} />
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    isPublic: state.trips.isPublic,
    uncheckedLists: state.lists.uncheckedLists,
    isProUser: state.auth.isProUser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showRenameListForm: (listId, listTitle, listColor) =>
      dispatch(showRenameListForm(listId, listTitle, listColor)),
    showDeleteListConfirmationModal: listId => dispatch(showDeleteListConfirmationModal(listId)),
    setListChecked: listId => dispatch(setListChecked(listId)),
    setListUnchecked: listId => dispatch(setListUnchecked(listId)),
    showReorderListsModal: () => dispatch(showReorderListsModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceList);
