import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PlaceInfoWindow from "./PlaceInfoWindow";
import { hideInfoWindow } from "../store/actions/mapActions";

const CustomPlaceInfoWindow = ({ placeId }) => {
  const placesMap = useSelector(state => state.firestore.data.places);
  const dispatch = useDispatch();

  let place;
  if (placesMap && placeId && placesMap[placeId] && !placesMap[placeId].googlePlaceId) {
    // No GooglePlaceID means it's a custom place
    place = { id: placeId, ...placesMap[placeId] };
  } else {
    // Render nothing if the provided placeId is not a custom place
    return null;
  }

  return (
    <>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: "440px",
          background: "white",
          boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
          borderRadius: "8px",
          padding: "4px 12px 12px 12px",
        }}
      >
        <div className="d-flex flex-row justify-content-end">
          <button
            type="button"
            className="btn btn-round p-0"
            onClick={() => {
              dispatch(hideInfoWindow());
            }}
            aria-label="Close"
          >
            <i className="material-icons">close</i>
          </button>
        </div>
        <div
          style={{
            overflow: "auto",
            maxHeight: "300px",
            padding: "0rem 0.5rem 0.5rem 0.5rem",
          }}
        >
          <PlaceInfoWindow place={place} type="LIST" />
        </div>
      </div>
    </>
  );
};

export default CustomPlaceInfoWindow;
