import React from "react";
import ErrorPage from "./ErrorPage";

function GenericServerError() {
  return (
    <ErrorPage
      errorTitle="This is embarrassing..."
      errorSubtitle="Something unexpected happened. We've noted it on our end."
      imgSrc="/assets/img/illustrations/undraw_server_down_s4lk.svg"
      altText="Computer disconnected from server"
    />
  );
}

export default GenericServerError;
