import React from "react";
import { connect } from "react-redux";
import { addDay, hideItineraryPane } from "../store/actions/tripActions";
import { hideOverlayRoute } from "../store/actions/mapActions";

import moment from "moment";
import ItineraryPlaceList from "./ItineraryPlaceList";
import BiggerThanTabletOnly from "./media_queries/BiggerThanTabletOnly";

class Itinerary extends React.Component {
  handleAddDay = () => {
    if (this.props.trip && this.props.trip.id) {
      this.props.addDay(this.props.trip.id);
    }
  };

  handleCloseClick = () => {
    this.props.hideItineraryPane();
    this.props.hideOverlayRoute();
  };

  render() {
    const { trip, places, isPublic } = this.props;

    let days = [];
    let hasPlaceInItinerary = false;

    if (trip && places) {
      const startDate = moment.unix(trip.startDate.seconds);
      for (let i = 0; i < trip.numDays; i++) {
        var date = moment(startDate).add(i, "days");
        days.push({ places: [], date: date });
      }

      places.map(place => {
        if (place.dayIndex >= 0) {
          if (days[place.dayIndex]) {
            days[place.dayIndex].places.push(place);
          }
          hasPlaceInItinerary = true;
        }
      });
    }

    return (
      <div
        className="col-lg-3 py-4 overflow-auto vh-100"
        style={{ borderLeft: "1px solid #ccc", borderRight: "1px solid #ccc" }}
      >
        <div className="d-flex mb-3 align-items-top">
          <div>
            <h4>Itinerary</h4>
          </div>
          <BiggerThanTabletOnly>
            <div className="ml-auto mt-1 mr-1">
              <button
                type="button"
                aria-label="Close"
                className="close"
                onClick={this.handleCloseClick}
              >
                <span title="Close itinerary">×</span>
              </button>
            </div>
          </BiggerThanTabletOnly>
        </div>
        {days &&
          (days.length > 0 ? (
            !hasPlaceInItinerary && isPublic ? (
              <div className="text-center mt-2">
                <img
                  src="/assets/img/illustrations/undraw_sunny_day_bk3m.svg"
                  className="img-fluid"
                />
                <h3 className="h5 mt-4">Nothing Planned</h3>
                <p className="text-muted">Sometimes spontaneity is the best kind of adventure</p>
              </div>
            ) : (
              [
                days.map((day, index) => (
                  <ItineraryPlaceList
                    key={index}
                    dayIndex={index}
                    date={day.date}
                    places={day.places}
                    tripId={trip.id}
                    lodgingPlaceId={trip.lodgingList && trip.lodgingList[index]}
                    dayDescription={trip.dayDescriptions && trip.dayDescriptions[index]}
                  />
                )),
                !isPublic && (
                  <a
                    key={trip.id + "itineraryAddDayButton"}
                    onClick={this.handleAddDay}
                    href="#"
                    className="text-muted"
                  >
                    + Add a day
                  </a>
                ),
              ]
            )
          ) : (
            <div className="text-center mt-2">
              {isPublic && (
                <img
                  src="/assets/img/illustrations/undraw_sunny_day_bk3m.svg"
                  className="img-fluid"
                />
              )}
              <h3 className="h5 mt-4">{isPublic ? "Nothing Planned" : "No Days"}</h3>
              <p className="text-muted">
                {isPublic
                  ? "Sometimes spontaneity is the best kind of adventure"
                  : "Add a day to start building your itinerary"}
              </p>
              {!isPublic && (
                <button className="btn btn-primary btn-block" onClick={this.handleAddDay}>
                  Add a day
                </button>
              )}
            </div>
          ))}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isPublic: state.trips.isPublic,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideItineraryPane: () => dispatch(hideItineraryPane()),
    hideOverlayRoute: () => dispatch(hideOverlayRoute()),
    addDay: tripId => dispatch(addDay(tripId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Itinerary);
