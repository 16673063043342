import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";
import { useSelector, useDispatch } from "react-redux";
import { hideAuthenticatedModal } from "../store/actions/modalActions";
import CopyTripModalContent from "./CopyTripModalContent";

const SIGNUP_VIEWS = {
  SIGNUP: "SIGNUP",
  SIGNIN: "SIGNIN",
};
Object.freeze(SIGNUP_VIEWS);

export const AUTH_ACTIONS = {
  DISPLAY_COPY_TRIP_CONTENT: "DISPLAY_COPY_TRIP_CONTENT",
  REFRESH_PAGE: "REFRESH_PAGE",
};
Object.freeze(AUTH_ACTIONS);

/**
 * Map from an auth action to the content component that should be displayed
 * in the modal for that action. If null, no content needs to be displayed.
 */
const AUTH_ACTION_TO_CONTENT_COMPONENT = new Map([
  [AUTH_ACTIONS.DISPLAY_COPY_TRIP_CONTENT, CopyTripModalContent],
  [AUTH_ACTIONS.REFRESH_PAGE, null],
]);
Object.freeze(AUTH_ACTION_TO_CONTENT_COMPONENT);

const SignInModalBody = ({ handleCloseModal, onSignupClick }) => {
  useEffect(() => {
    window.analytics.page("Sign In Modal");
  }, []);

  return (
    <Modal.Body>
      <div className="container">
        <SignIn
          isModalContent={true}
          handleCloseModal={handleCloseModal}
          onSignupClick={onSignupClick}
        />
      </div>
    </Modal.Body>
  );
};

const SignUpModalBody = ({ handleCloseModal, onSigninClick }) => {
  useEffect(() => {
    window.analytics.page("Sign Up Modal");
  }, []);

  return (
    <Modal.Body>
      <div className="container">
        <SignUp
          isModalContent={true}
          handleCloseModal={handleCloseModal}
          onSigninClick={onSigninClick}
        />
      </div>
    </Modal.Body>
  );
};

const AuthenticatedModal = () => {
  const auth = useSelector(state => state.firebase.auth);
  const [currentShowingView, setCurrentShowingView] = useState(SIGNUP_VIEWS.SIGNUP);
  const shouldShowAuthenticatedModal = useSelector(state => state.modals.showAuthenticatedModal);
  const authAction = useSelector(state => state.modals.authAction);
  const authActionProps = useSelector(state => state.modals.authActionProps);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideAuthenticatedModal());
  };

  const onSignupSigninClick = viewToGoTo => {
    return e => {
      if (!(e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        setCurrentShowingView(viewToGoTo);
      }
    };
  };

  const isLoggedInNonAnonymousUser = auth => {
    return auth && auth.uid && !auth.isAnonymous;
  };

  const AuthActionComponent = AUTH_ACTION_TO_CONTENT_COMPONENT.get(authAction);
  if (authAction && !AuthActionComponent && isLoggedInNonAnonymousUser(auth)) {
    // If there's an authAction, but no associated component to render, and the user is successfully
    // logged in, then they have completed the auth flow so we reload the page in order to add them as a collaborator if necessary.
    window.location.reload();
    return;
  }

  return (
    <Modal id="authenticated-modal" show={shouldShowAuthenticatedModal} onHide={handleClose}>
      {isLoggedInNonAnonymousUser(auth) && AuthActionComponent && (
        <AuthActionComponent handleClose={handleClose} {...authActionProps} />
      )}
      {!isLoggedInNonAnonymousUser(auth) && currentShowingView === SIGNUP_VIEWS.SIGNIN && (
        <SignInModalBody
          handleCloseModal={handleClose}
          onSignupClick={onSignupSigninClick(SIGNUP_VIEWS.SIGNUP)}
        />
      )}
      {!isLoggedInNonAnonymousUser(auth) && currentShowingView === SIGNUP_VIEWS.SIGNUP && (
        <SignUpModalBody
          handleCloseModal={handleClose}
          onSigninClick={onSignupSigninClick(SIGNUP_VIEWS.SIGNIN)}
        />
      )}
    </Modal>
  );
};

export default AuthenticatedModal;
