// Utility functions for tracking related logic

export const trackGoogleAnalyticsCounter = ({ action, category, label, value }) => {
  window.analytics.track(
    action,
    {
      category: category,
      value: value,
      label: label,
      nonInteraction: 1,
    },
    {
      integrations: {
        All: false,
        "Google Analytics": true,
      },
    }
  );
};
