import React from "react";
import { Link } from "react-router-dom";

const NewTripCard = () => {
  return (
    <div className="col">
      <p className="text-muted mb-4">You have no upcoming trips. Start by creating a new trip.</p>
      <Link to="/new" className="btn btn-primary mb-5">
        New Trip
      </Link>
      <p className="mb-6">
        <img
          src="/assets/img/illustrations/undraw_airport_2581.svg"
          style={{ width: "400px" }}
          className="img-fluid"
          alt="Person with luggage at airport"
        />
      </p>
    </div>
  );
};

export default NewTripCard;
