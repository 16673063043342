import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteTrip } from "../store/actions/tripActions";
import { hideDeleteTripConfirmationModal } from "../store/actions/modalActions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

export class DeleteTripConfirmationModal extends Component {
  handleDelete = () => {
    this.handleClose();
    this.props.deleteTrip(this.props.tripId);
    this.props.history.push("/home");
  };

  handleClose = () => {
    this.props.hideDeleteTripConfirmationModal();
  };

  render() {
    return (
      <DeleteConfirmationModal
        handleDelete={this.handleDelete}
        handleClose={this.handleClose}
        deleteButtonMessage={"Delete Trip"}
        body={"Are you sure you want to delete this trip? This can't be undone."}
        title={"Delete Trip?"}
        showDeleteConfirmation={this.props.showDeleteTripConfirmationModal}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteTrip: id => dispatch(deleteTrip(id)),
    hideDeleteTripConfirmationModal: () => dispatch(hideDeleteTripConfirmationModal()),
  };
};

const mapStateToProps = state => {
  return {
    showDeleteTripConfirmationModal: state.modals.showDeleteTripConfirmationModal,
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DeleteTripConfirmationModal);
