import React, { useState, useCallback } from "react";
import { useHover } from "../hooks/CustomHooks";
import TextareaAutosize from "react-textarea-autosize";
import { useSelector } from "react-redux";
import Linkify from "linkifyjs/react";
import { useEffect } from "react";

const Notes = props => {
  const existingNotes = props.existingNotes;

  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [notes, setNotes] = useState(existingNotes || "");
  const isPublic = useSelector(state => state.trips.isPublic);
  const [hoverRef, isHovered] = useHover();

  const handleCancelNotesInput = () => {
    setIsEditingNotes(false);
  };

  useEffect(() => {
    if (!isEditingNotes) {
      setNotes(existingNotes);
    }
  }, [existingNotes, isEditingNotes]);

  const handleSaveNotes = useCallback(() => {
    props.saveNotes(notes ? notes.trim() : "");
    setIsEditingNotes(false);
  }, [notes, props]);

  // Wrap in a useCallback with a second argument of [notes] so it doesn't cause the child
  // component (TextAreaAutosize) to re-render every single time the parent renders. It
  // re-renders only when "notes" changes
  const handleNotesInputBlur = useCallback(
    e => {
      // If the cancel or save button is clicked let those buttons handle the event
      if (
        e.relatedTarget &&
        (e.relatedTarget.id === "cancel-save" ||
          e.relatedTarget.id === "save-notes" ||
          e.relatedTarget.id === "delete-place")
      ) {
        return;
      } else {
        // Otherwise, when the notes input box loses focus, save the content
        handleSaveNotes();
      }
    },
    [handleSaveNotes]
  );

  const onKeyDown = e => {
    // Cmd or Ctrl + enter will save the text entered as notes
    if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
      handleSaveNotes();
    }
  };

  const linkifyNotesAttributes = {
    onClick: event => {
      // Stop propagation on click events for links clicked in place notes
      // so that the containing text input doesn't activate to allow edit.
      event.stopPropagation();
    },
  };

  if (isEditingNotes) {
    return (
      <div>
        <TextareaAutosize
          autoFocus
          className="form-control form-control-sm"
          minRows={2}
          id="notes"
          type="text"
          name="notes"
          onChange={e => {
            setNotes(e.target.value);
          }}
          onBlur={e => handleNotesInputBlur(e)}
          value={notes}
          onKeyDown={onKeyDown}
        />

        <div className="d-flex justify-content-end mb-3 mt-2">
          <button
            type="button"
            className="btn btn-primary mr-2 px-3"
            id="save-notes"
            onClick={handleSaveNotes}
            style={{ letterSpacing: "normal", textTransform: "none" }}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-light "
            id="cancel-save"
            onClick={handleCancelNotesInput}
            style={{ letterSpacing: "normal", textTransform: "none" }}
          >
            <i className="fas fa-times fa-lg" aria-hidden="true" />
          </button>
        </div>
      </div>
    );
  } else {
    if (existingNotes) {
      return (
        <div
          className="text-sm"
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            cursor: isPublic ? undefined : "pointer",
          }}
          onClick={isPublic ? undefined : () => setIsEditingNotes(true)}
        >
          <Linkify options={{ attributes: linkifyNotesAttributes }}>{existingNotes}</Linkify>
        </div>
      );
    } else {
      if (isPublic) {
        return null;
      } else {
        return (
          <div
            className="text-sm text-black-50"
            style={{
              borderRadius: ".4rem",
              padding: ".6rem .8rem",
              backgroundColor: isHovered ? "#ededed" : "#f7f7f7",
              cursor: "pointer",
            }}
            onClick={() => setIsEditingNotes(true)}
            ref={hoverRef}
          >
            Add notes...
          </div>
        );
      }
    }
  }
};

export default Notes;
