import React, { useEffect, useState } from "react";
import ExploreTripCard from "./ExploreTripCard";
import Header from "./Header";
import Footer from "./Footer";
import { fetchPublicUserData } from "../api/UsersAPI";
import { fetchListOfTripsFromTripIds } from "../api/TripsAPI";
import { EXPLORE_TRIP_IDS } from "./Constants";
import LoadingComponent from "./LoadingComponent";

const ExplorePage = () => {
  const [userIdToUserData, setUserIdToUserData] = useState({});
  const [exploreTrips, setExploreTrips] = useState(null);

  useEffect(() => {
    document.title = "Explore - Scout";
    window.analytics.page("Explore");

    fetchListOfTripsFromTripIds(EXPLORE_TRIP_IDS).then(exploreTrips => {
      setExploreTrips(exploreTrips);

      // Fetch trip authors for the Explore trips
      const tripAuthorPromises = exploreTrips.map(trip => fetchPublicUserData(trip.userId));
      Promise.all(tripAuthorPromises).then(tripAuthors => {
        let userIdToUserData = {};
        tripAuthors.forEach(tripAuthor => {
          if (tripAuthor) {
            userIdToUserData[tripAuthor.id] = tripAuthor;
          } else {
            console.error(
              "Null trip author found while fetching explore trip authors in Explore page"
            );
          }
        });
        setUserIdToUserData(userIdToUserData);
      });
    });
  }, []);

  return (
    <>
      <Header />
      <section className="py-5">
        <div className="container">
          <div className="mb-2">
            <p className="subtitle text-secondary">Popular trips from the community</p>
            <h1>Explore</h1>
          </div>
          {exploreTrips ? (
            exploreTrips.length > 0 ? (
              <div className="mt-4">
                <div className="row">
                  {exploreTrips.map(exploreTrip => (
                    <ExploreTripCard
                      key={exploreTrip.id}
                      trip={exploreTrip}
                      tripAuthor={userIdToUserData[exploreTrip.userId]}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <>
                <p className="text-muted py-5">No popular trips. Where'd they all go? 🤔</p>
              </>
            )
          ) : (
            <LoadingComponent message="Loading..." />
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ExplorePage;
