import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { showAuthenticatedModal } from "../store/actions/modalActions";
import { AUTH_ACTIONS } from "./AuthenticatedModal";
import { Link } from "react-router-dom";
import TabletOrSmallerOnly from "./media_queries/TabletOrSmallerOnly";
import BiggerThanTabletOnly from "./media_queries/BiggerThanTabletOnly";

const SignUpBanner = ({ isPublic }) => {
  const auth = useSelector(state => state.firebase.auth);
  const dispatch = useDispatch();

  const mobileSignUpText = !isPublic
    ? "to easily get back to this trip"
    : "to create your own trip";

  const desktopSignUpText = "Sign up " + mobileSignUpText;

  const onSignUpBannerClicked = () => {
    window.analytics.track("Button Clicked", {
      buttonType: "Signup Banner",
      pageType: isPublic ? "View-only Trip" : "Editable Trip",
    });
    dispatch(showAuthenticatedModal(AUTH_ACTIONS.REFRESH_PAGE, {}));
  };

  const onLearnMoreClicked = () => {
    window.analytics.track("Button Clicked", {
      buttonType: "Learn More Signup Banner",
      pageType: isPublic ? "View-only Trip" : "Editable Trip",
    });
  };

  return (
    (auth?.isAnonymous || !auth?.uid) && (
      <>
        <TabletOrSmallerOnly>
          <div
            className="alert alert-primary"
            style={{
              marginTop: "-1.5rem",
              marginLeft: "-15px",
              marginRight: "-15px",
              borderRadius: "0",
            }}
            onClick={onSignUpBannerClicked}
            role="alert"
          >
            <strong>Sign up </strong>
            {mobileSignUpText}
          </div>
        </TabletOrSmallerOnly>
        <BiggerThanTabletOnly>
          <div className="alert alert-primary mb-0" style={{ borderRadius: "0" }} role="alert">
            {desktopSignUpText}
            <button
              type="button"
              onClick={onSignUpBannerClicked}
              className="btn btn-secondary ml-4"
            >
              Sign Up
            </button>
            <Link to="/home" className="ml-3 alert-link" onClick={onLearnMoreClicked}>
              Learn More
            </Link>
          </div>
        </BiggerThanTabletOnly>
      </>
    )
  );
};

export default SignUpBanner;
