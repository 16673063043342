import React, { Component } from "react";
import { connect } from "react-redux";
import { hideGalleryModal } from "../store/actions/modalActions";
import { clearTemporaryPhotos } from "../store/actions/placeActions";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Img } from "react-image";
import { fetchAndWritePlacePhotos } from "../api/PlacesAPI";
import { NUM_PHOTOS_TO_FETCH_FOR_PLACE } from "./Constants";

const CustomView = ({ getStyles, isModal, data, carouselProps }) => {
  return carouselProps.isDataReady ? (
    <div style={{ ...getStyles("view") }} className="react-images__view">
      <Img
        src={data.source}
        style={{
          maxHeight: "70vh",
          maxWidth: "100%",
          height: "auto",
          userSelect: "none",
        }}
        loader={
          <div className="spinner-border text-white" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        }
        unloader={
          <p className="text-white" style={{ lineHeight: 1 }}>
            Uh oh, the picture couldn't load
          </p>
        }
      />
    </div>
  ) : (
    <div className="spinner-border text-white" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
};

class GalleryModal extends Component {
  onClose = () => {
    const currentPlacePhotos =
      this.props?.places && this.props.places[this.props.galleryModalPlaceId]?.placePhotos;
    if (
      // Only write the place photos if there are no place photos saved on the doc already.
      (!currentPlacePhotos || currentPlacePhotos.length === 0) &&
      // Only write the place photos if there's a valid placeId. Sometimes if the modal is
      // closed repeatedly quickly (hit esc twice for example onClose will get called twice)
      this.props.galleryModalPlaceId
    ) {
      // Write the place photos on the doc when the GalleryModal is closing
      // so we don't have the flicker effect while they are viewing the gallery
      // but we still get to save the permanent photos for the next time the pictures are viewed
      fetchAndWritePlacePhotos(this.props.galleryModalPlaceId, NUM_PHOTOS_TO_FETCH_FOR_PLACE);
    }

    // Hide the gallery modal first and then clear temporary photos state so we don't
    // try to re-render the Modal with changed views
    this.props.hideGalleryModal();
    this.props.clearTemporaryPhotos();
  };

  getImages = (poiPlacePhotos, addedPlacePhotos, temporaryPlacePhotos) => {
    // Choose which source to use, preferring POI > Added place > Temporary place
    const photosSource = poiPlacePhotos || addedPlacePhotos || temporaryPlacePhotos;
    let images = [];
    if (photosSource) {
      images = photosSource.map(photoUrl => {
        return {
          source: photoUrl,
        };
      });
    }
    return images;
  };

  render() {
    const {
      showGalleryModal,
      galleryModalPlaceId,
      places,
      poiPlaceDetails,
      temporaryPlacePhotos,
    } = this.props;

    let isDataReady = false;
    const poiPlacePhotos = poiPlaceDetails && poiPlaceDetails.placePhotos;
    const addedPlacePhotos =
      galleryModalPlaceId && places && places[galleryModalPlaceId].placePhotos;
    let images = this.getImages(poiPlacePhotos, addedPlacePhotos, temporaryPlacePhotos);
    if (images.length > 0) {
      isDataReady = true;
    } else {
      // We need to push a dummy image here so that the Carousel renders its content properly
      // The CustomView itself will show a spinner until `isDataReady` prop is passed to it as `true`
      images = [{ source: "" }];
    }

    return (
      <ModalGateway>
        {showGalleryModal ? (
          <Modal onClose={this.onClose} allowFullscreen={false} closeOnBackdropClickBoolean={true}>
            <Carousel
              views={images}
              components={{
                View: CustomView,
              }}
              trackProps={{ instant: true }}
              isDataReady={isDataReady}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    );
  }
}

const mapStateToProps = state => {
  return {
    showGalleryModal: state.modals.showGalleryModal,
    galleryModalPlaceId: state.modals.galleryModalPlaceId,
    places: state.firestore.data.places,
    poiPlaceDetails: state.places.poiPlaceDetails,
    temporaryPlacePhotos: state.places.temporaryPlacePhotos,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideGalleryModal: () => dispatch(hideGalleryModal()),
    clearTemporaryPhotos: () => dispatch(clearTemporaryPhotos()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GalleryModal);
