import React from "react";
import Modal from "react-bootstrap/Modal";
import CustomDateRangePicker from "./CustomDateRangePicker";
import { updateTripDates, showItineraryPane } from "../store/actions/tripActions";
import { hideTripSettingsModal } from "../store/actions/modalActions";
import moment from "moment";
import { connect } from "react-redux";

class TripSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: props.initialStartDate && props.initialStartDate.utc(),
      endDate: props.initialEndDate && props.initialEndDate.utc(),
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    if (
      this.props.initialStartDate !== this.state.startDate ||
      this.props.initialEndDate !== this.state.endDate
    ) {
      this.props.updateTripDates(this.props.tripId, this.state.startDate, this.state.endDate);
    }
    this.handleClose();
  };

  handleClose = () => {
    this.props.hideTripSettingsModal();
  };

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  showItinerary = () => {
    this.props.hideTripSettingsModal();
    this.props.showItineraryPane();
  };

  handleEntered = () => {
    if (this.props.initialStartDate && this.props.initialEndDate) {
      this.setState({
        startDate: this.props.initialStartDate && this.props.initialStartDate.utc(),
        endDate: this.props.initialEndDate && this.props.initialEndDate.utc(),
      });
    }
  };

  handleDateChange = ({ startDate, endDate }) => {
    // startDate and endDate are Moment objects in local timezones so we need
    // to convert it to UTC while maintaining the same time by converting to UTC
    // then adding the offset in
    const utcStartDate =
      startDate &&
      moment(startDate)
        .utc()
        .add(startDate.utcOffset(), "m");
    const utcEndDate =
      endDate &&
      moment(endDate)
        .utc()
        .add(endDate.utcOffset(), "m");
    this.setState({
      startDate: utcStartDate,
      endDate: utcEndDate,
    });
  };

  render() {
    return (
      <Modal
        id="task-modal"
        show={this.props.showTripSettingsModal}
        onHide={this.handleClose}
        onEntered={this.handleEntered}
      >
        <form id="rename-form" onSubmit={this.handleSubmit}>
          <Modal.Body>
            <button
              type="button"
              className="close btn btn-round"
              onClick={this.handleClose}
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </button>
            <h4 className="modal-title mb-4">Change Dates</h4>
            <div className="form-group">
              <label htmlFor="tripDates" className="form-label">
                Dates
              </label>
              <CustomDateRangePicker
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                startDateId="startDate" // PropTypes.string.isRequired,
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId="endDate" // PropTypes.string.isRequired,
                onDatesChange={this.handleDateChange} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                required={true}
                block={true}
                hideKeyboardShortcutsPanel={true}
                isOutsideRange={() => false}
                endDateOffset={day => day.add(this.props.numDays - 1, "days")}
              />
              <small className="form-text text-muted">
                To add a day, click "+ Add a day" at the bottom of the{" "}
                <a href="#" onClick={this.showItinerary}>
                  itinerary
                </a>{" "}
                view . To delete, click the menu button next to the day and "Delete Day".
              </small>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    showTripSettingsModal: state.modals.showTripSettingsModal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTripDates: (tripId, startDate, endDate) =>
      dispatch(updateTripDates(tripId, startDate, endDate)),
    hideTripSettingsModal: () => dispatch(hideTripSettingsModal()),
    showItineraryPane: () => dispatch(showItineraryPane()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TripSettings);
