import React from "react";
import { TRIP_VIEW_PERMISSION } from "./Constants";
import { useDispatch, useSelector } from "react-redux";
import { showPublishingSettingsModal, showPublishTripModal } from "../store/actions/modalActions";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const PublishTripButton = ({ trip }) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.firebase.auth);
  const handlePublishClick = () => {
    dispatch(showPublishTripModal());
  };
  const handlePublishingSettingsClick = e => {
    dispatch(showPublishingSettingsModal());
  };
  if (!trip) {
    return null;
  }

  if (trip.userId === auth.uid) {
    // Owner is viewing the publish trip button
    if (trip.viewPermission === TRIP_VIEW_PERMISSION.PUBLIC) {
      return (
        <button className={"link-button"} onClick={handlePublishingSettingsClick} type="button">
          <i className="fas fa-check mr-2" />
          Published
        </button>
      );
    } else {
      return (
        <button
          className={"btn btn-outline-primary btn-sm"}
          onClick={handlePublishClick}
          type="button"
        >
          <i className="fas fa-upload mr-2" />
          Publish
        </button>
      );
    }
  } else {
    // It's a collaborator viewing the trip publish button
    return trip.viewPermission === TRIP_VIEW_PERMISSION.PUBLIC ? (
      <OverlayTrigger
        delay={{ show: 500 }}
        overlay={
          <Tooltip id="published-tooltip">This trip is public, anyone on Scout can view it</Tooltip>
        }
      >
        <span className={"text-primary"} style={{ cursor: "default" }}>
          <i className="fas fa-check mr-2" />
          Published
        </span>
      </OverlayTrigger>
    ) : // Show nothing if the trip is not published for collaborators
    null;
  }
};

export default PublishTripButton;
