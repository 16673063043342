/* eslint-disable no-undef */
import React from "react";
import { Marker } from "react-google-maps";
import { useSelector } from "react-redux";

const CurrentLocationMarker = () => {
  const shouldShowCurrentLocationMarker = useSelector(
    state => state.map.shouldShowCurrentLocationMarker
  );
  const currentLatLng = useSelector(state => state.map.currentLatLng);

  if (shouldShowCurrentLocationMarker && currentLatLng) {
    return (
      <>
        {/* Inner blue dot of the marker */}
        <Marker
          position={{
            lat: currentLatLng.lat,
            lng: currentLatLng.lng,
          }}
          clickable={false}
          cursor="pointer"
          draggable={false}
          flat={true}
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: "#4285F4",
            fillOpacity: 1,
            scale: 6,
            strokeColor: "white",
            strokeWeight: 2,
          }}
          zIndex={3}
        />
        {/* Outer ring of the marker */}
        <Marker
          position={{
            lat: currentLatLng.lat,
            lng: currentLatLng.lng,
          }}
          clickable={false}
          cursor="pointer"
          draggable={false}
          flat={true}
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: "#C8D6EC",
            fillOpacity: 0.6,
            scale: 20,
            strokeWeight: 0,
          }}
          zIndex={2}
        />
      </>
    );
  }
  return null;
};

export default CurrentLocationMarker;
