/**
 * Given a trip and tripOwner object, returns the author name to show for a trip
 *
 * @param {tripObject} trip Trip object
 * @param {tripOwner} tripOwnerUserData Object containing information about the trip owner
 * @returns {string} Name of trip author to show
 */
export const chooseAuthorToShow = (trip, tripOwnerUserData) => {
  if (trip && tripOwnerUserData) {
    if (trip.customAuthor) {
      return trip.customAuthor;
    } else if (tripOwnerUserData.name) {
      return tripOwnerUserData.name;
    } else {
      return "A Scout User";
    }
  } else {
    return null;
  }
};

/**
 * Given a trip and tripOwner object, returns a url of the trip owner's photo
 *
 * @param {tripObject} trip Trip object
 * @param {tripOwner} tripOwnerUserData Object containing information about the trip owner
 * @returns {string} Url of the trip owner's photo
 */
export const chooseAuthorPhotoToShow = (trip, tripOwnerUserData) => {
  if (trip && tripOwnerUserData) {
    if (trip.customAuthorPhotoUrl) {
      return trip.customAuthorPhotoUrl;
    } else if (tripOwnerUserData.photoURL) {
      return tripOwnerUserData.photoURL;
    }
  }
  return null;
};
