import React, { Component } from "react";
import { connect } from "react-redux";
import { checkTripAccess, tripLoadingCompleted } from "../store/actions/tripActions";
import ListView from "./ListView";
import TripDoesNotExistError from "./error_pages/TripDoesNotExistError";
import LoadingComponent from "./LoadingComponent";

class TripLoadingView extends Component {
  constructor(props) {
    super(props);
    const tripId = props.match.params.tripId;
    props.checkTripAccess(tripId);
  }

  componentWillUnmount() {
    this.props.tripLoadingCompleted();
  }

  render() {
    const { accessLookupResponse } = this.props;
    if (!accessLookupResponse) {
      return (
        <div>
          <LoadingComponent message="Opening Trip..." />
        </div>
      );
    }

    const { accessLookupError, isTripViewable } = accessLookupResponse;

    if (accessLookupError) {
      // If there's any error trying to get the trip doc
      return <TripDoesNotExistError />;
    } else if (isTripViewable) {
      return <ListView isPublic={!!this.props.showPublicView} match={this.props.match} />;
    } else {
      console.error("UNHANDLED ERROR CASE - should not get here");
      return <TripDoesNotExistError />;
    }
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    accessLookupResponse: state.trips.accessLookupResponse,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkTripAccess: tripId => dispatch(checkTripAccess(tripId)),
    tripLoadingCompleted: () => dispatch(tripLoadingCompleted()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TripLoadingView);
