import firebase from "firebase/compat/app";

/**
 * Fetches a list of trips where the user is a collaborator in.
 * @param {string} userId - The ID of the user to look up trips where they're a collaborator.
 * @returns {Promise.<Object[]>} A Promise resolved with an array of 0 or more trip objects
 * and [] if there's an error
 */
export const fetchTripsCollaboratingOn = async userId => {
  let collaborators;
  try {
    // Query all subcollections where the current user is a collaborator
    collaborators = await firebase
      .firestore()
      .collectionGroup("collaborators")
      .where("userId", "==", userId)
      .get();
  } catch (err) {
    console.error(`Error fetching subcollections where the user ${userId} is a collaborator:`, err);
    return [];
  }
  if (collaborators.empty) {
    // Return an empty array if there are no collaborators
    return [];
  }

  // From the subcollections, get all the trips where the user is a collaborator.
  let collaboratorTripQueries = [];
  collaborators.forEach(doc => {
    // Need to get the parent's parent because the collaborator doc is stored
    // as trip/collaborators/{collaboratorId}. To get the trip doc, we need to get
    // the parent (collaborators subscollection) then its parent (the trip doc)
    collaboratorTripQueries.push(doc.ref.parent.parent.get());
  });
  let collaboratorTripPromises;
  try {
    collaboratorTripPromises = await Promise.all(collaboratorTripQueries);
  } catch (err) {
    console.error("Error getting parent trip of collaborator subcollections:", err);
    return [];
  }

  let collaboratorTrips = collaboratorTripPromises.map(tripSnapshot => {
    return { ...tripSnapshot.data(), id: tripSnapshot.id };
  });

  // Filter out trips created by the user.
  // This is needed because if you open your own trip from a share link, you're added
  // to the collaborators subcollection and this will produce duplicates in the final trips list
  return collaboratorTrips.filter(trip => trip.userId !== userId);
};
