import React from "react";
import { Redirect, Route } from "react-router-dom";

const AuthenticatedRoute = ({ component: Component, isAuthenticated, componentProps, ...rest }) => (
  <Route
    exact
    {...rest}
    render={props => {
      return isAuthenticated ? <Component {...props} {...componentProps} /> : <Redirect to="/" />;
    }}
  />
);

export default AuthenticatedRoute;
