import React, { Component } from "react";
import { connect } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import moment from "moment";
import {
  showHighlightMarker,
  hideHighlightMarker,
  removePlaceFromItinerary,
} from "../store/actions/placeActions";
import { showInfoWindow, showOverlayRoute } from "../store/actions/mapActions";
import { showPlaceTimeModal } from "../store/actions/modalActions";
import ClampLines from "react-clamp-lines";

class ItineraryPlaceCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
    };
  }

  handleMouseEnter = () => {
    // Show trashcan icon
    this.setState({ isHovering: true });
    // Show map marker highlight
    this.props.showHighlightMarker(this.props.place.id);
  };

  handleMouseLeave = () => {
    // Hide trashcan icon
    this.setState({ isHovering: false });
    // Hide highlight map marker icon
    this.props.hideHighlightMarker(this.props.place.id);
  };

  handleMouseDown = e => {
    // prevents focus from getting set on div causing an ugly blue box
    if (this.props.isPublic) {
      e.preventDefault();
    }
  };

  handleClick = place => {
    if (place && place.id) {
      this.props.showInfoWindow(place.id);
      this.props.showOverlayRoute(place.dayIndex);
    }
  };

  handleRemovePlace = e => {
    e.stopPropagation();
    this.props.removePlaceFromItinerary(this.props.place.id);
  };

  handleSetTime = e => {
    this.props.showPlaceTimeModal(this.props.place.id);
    e.stopPropagation();
  };

  render() {
    const { place, lists, isPublic } = this.props;
    const placeCardStyle = [
      "alert-danger",
      "alert-primary",
      "alert-success",
      "alert-secondary",
      "alert-info",
    ];

    const hoverBackgroundColors = ["#fbe7e9", "#eaedfe", "#e5f4e9", "#fce8f1", "#e3f4f6"];

    let cardStyle = "card h-100 ";
    let backgroundHoverStyle = "";
    if (lists && lists[place.listId]) {
      const styleIndex = lists[place.listId].listIndex % placeCardStyle.length;
      cardStyle += placeCardStyle[styleIndex];
      backgroundHoverStyle += `linear-gradient(to right, rgba(255,255,255,0), ${hoverBackgroundColors[styleIndex]} 30%)`;
    }

    if (
      place &&
      !this.state.isHovering &&
      this.props.showHighlightMarker &&
      this.props.highlightMarkerId === place.id
    ) {
      cardStyle += " border-dark";
    }

    const momentStartTime = place.startTime ? moment.unix(place.startTime.seconds) : null;
    const momentEndTime = place.endTime ? moment.unix(place.endTime.seconds) : null;
    let formattedStartTime = "";
    let formattedEndTime = "";
    if (momentStartTime) {
      formattedStartTime =
        momentStartTime.minutes() === 0
          ? momentStartTime.utc().format("ha")
          : momentStartTime.utc().format("h:mma");
    }
    if (momentEndTime) {
      formattedEndTime =
        momentEndTime.minutes() === 0
          ? momentEndTime.utc().format("ha")
          : momentEndTime.utc().format("h:mma");
    }
    let placeTime = "";
    if (momentStartTime && momentEndTime) {
      placeTime = formattedStartTime + " - " + formattedEndTime;
    } else if (momentStartTime) {
      placeTime = formattedStartTime;
    }

    return (
      <Draggable
        draggableId={"itinerary-" + place.id}
        index={this.props.index}
        isDragDisabled={isPublic}
      >
        {provided => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            className="col-12 mb-2"
            tabIndex="0"
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            onClick={() => this.handleClick(place)}
          >
            <div
              className={cardStyle}
              style={isPublic ? { cursor: "pointer" } : undefined}
              onMouseDown={this.handleMouseDown}
            >
              <div className="d-flex align-items-center" style={{ padding: ".2rem .8rem" }}>
                <div
                  className="w-100"
                  style={{
                    paddingTop: ".3rem",
                    paddingBottom: ".3rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      marginBottom: "0rem",
                    }}
                    title={place.title}
                  >
                    {place.title}
                  </h6>
                  {placeTime && (
                    <h6
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        marginBottom: "0rem",
                      }}
                      className="text-muted"
                    >
                      <span>
                        <i className="far fa-clock" />
                        {" " + placeTime}
                      </span>
                    </h6>
                  )}
                  {place.notes && (
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: 1.3,
                      }}
                      className="mt-1"
                    >
                      <ClampLines
                        text={place.notes.split(". ")[0]}
                        id="clamped-itinerary-notes"
                        lines={2}
                        ellipsis="..."
                        className="text-muted"
                        buttons={false}
                      />
                    </div>
                  )}
                </div>
                {this.state.isHovering && !isPublic && (
                  <div
                    style={{
                      position: "absolute",
                      right: "8px",
                      paddingTop: "5px",
                      paddingLeft: "24px",
                      background: backgroundHoverStyle,
                      height: "100%",
                    }}
                  >
                    <a
                      onClick={this.handleSetTime}
                      href="#"
                      title="Set a time"
                      className="mr-3 text-dark"
                    >
                      <i className="fas fa-clock" />
                    </a>
                    <a
                      onClick={this.handleRemovePlace}
                      href="#"
                      title="Remove from day"
                      className="text-dark"
                    >
                      <i className="fas fa-times" />
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

const mapStateToProps = state => {
  return {
    lists: state.firestore.data.lists,
    showHighlightMarker: state.places.showHighlightMarker,
    highlightMarkerId: state.places.highlightMarkerId,
    isPublic: state.trips.isPublic,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showHighlightMarker: id => dispatch(showHighlightMarker(id)),
    hideHighlightMarker: id => dispatch(hideHighlightMarker(id)),
    showInfoWindow: placeId => dispatch(showInfoWindow(placeId)),
    removePlaceFromItinerary: id => dispatch(removePlaceFromItinerary(id)),
    showOverlayRoute: dayIndex => dispatch(showOverlayRoute(dayIndex)),
    showPlaceTimeModal: placeId => dispatch(showPlaceTimeModal(placeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItineraryPlaceCard);
