import React, { Component } from "react";
import { connect } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import moment from "moment";
import {
  deletePlace,
  showHighlightMarker,
  hideHighlightMarker,
} from "../store/actions/placeActions";
import { showInfoWindow } from "../store/actions/mapActions";
import { showPlaceDetailsModal } from "../store/actions/modalActions";
import ClampLines from "react-clamp-lines";
import { MAX_WIDTH_OF_TABLET } from "./Constants";
import MediaQuery from "react-responsive";

class PlaceCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
      imgThumbnailUrl: "",
    };
  }

  handleMouseDown = e => {
    // preventDefault when isPublic so focus is not set on div causing an ugly blue box
    if (this.props.isPublic) {
      e.preventDefault();
    }
  };

  handleMouseEnter = () => {
    // Show trashcan icon
    this.setState({ isHovering: true });
    // Show map marker highlight
    this.props.showHighlightMarker(this.props.place.id);
  };

  handleMouseLeave = () => {
    // Hide trashcan icon
    this.setState({ isHovering: false });
    // Hide highlight map marker icon
    this.props.hideHighlightMarker(this.props.place.id);
  };

  handleClick = (place, isTabletOrSmaller) => {
    if (place && place.id) {
      this.props.showInfoWindow(place.id);
      if (isTabletOrSmaller) {
        this.props.showPlaceDetailsModal(place.id);
      }
    }
  };

  handleDelete = e => {
    this.props.deletePlace(this.props.place.id, this.props.listId);
    e.stopPropagation();
  };

  render() {
    const { place, isPublic } = this.props;
    const starRating = calculateStarRating(place.rating);
    const priceLevel = place.priceLevel && calculatePriceLevel(place.priceLevel);
    const cardNoNotesStyle = isPublic
      ? {
          paddingBottom: ".5rem",
          paddingTop: ".5rem",
          paddingLeft: ".9rem",
          paddingRight: ".9rem",
        }
      : { padding: ".5rem .9rem" };
    const cardBodyNoNotesStyle =
      place.thumbnailImgUrl && isPublic
        ? undefined
        : { paddingTop: ".3rem", paddingBottom: ".3rem" };
    const cardTitleStyle = isPublic
      ? undefined
      : { fontSize: "14px", fontWeight: 500, marginBottom: "0rem" };
    const momentStartTime = place.startTime ? moment.unix(place.startTime.seconds) : null;
    const momentEndTime = place.endTime ? moment.unix(place.endTime.seconds) : null;
    let placeTime = "";
    if (momentStartTime && momentEndTime) {
      placeTime = (
        <span className="text-muted mr-2">
          {momentStartTime.minutes() === 0
            ? momentStartTime.utc().format("ha")
            : momentStartTime.utc().format("h:mma")}
          -
          {momentEndTime.minutes() === 0
            ? momentEndTime.utc().format("ha")
            : momentEndTime.utc().format("h:mma")}
        </span>
      );
    } else if (momentStartTime) {
      placeTime = (
        <span className="text-muted mr-2">
          {momentStartTime.minutes() === 0
            ? momentStartTime.utc().format("ha")
            : momentStartTime.utc().format("h:mma")}
        </span>
      );
    }

    if (this.props.notes) {
      return (
        <MediaQuery maxWidth={MAX_WIDTH_OF_TABLET}>
          {isTabletOrSmaller => (
            <Draggable draggableId={place.id} index={this.props.index} isDragDisabled={isPublic}>
              {provided => (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  className="col-sm-12 mb-2"
                  tabIndex="0"
                  onMouseEnter={isTabletOrSmaller ? undefined : this.handleMouseEnter}
                  onMouseLeave={isTabletOrSmaller ? undefined : this.handleMouseLeave}
                  onClick={() => this.handleClick(place, isTabletOrSmaller)}
                >
                  <div
                    className="card h-100 border-0 shadow"
                    style={isPublic ? { cursor: "pointer" } : undefined}
                    onMouseDown={this.handleMouseDown}
                  >
                    <div className="d-flex" style={{ padding: ".5rem .9rem" }}>
                      {place.thumbnailImgUrl && isPublic && (
                        <div className="mr-3">
                          <img
                            src={place.thumbnailImgUrl}
                            className="rounded"
                            alt="Place"
                            style={{ width: 80, height: 80, objectFit: "cover" }}
                          />
                        </div>
                      )}
                      <div className="flex-grow-1">
                        <div className="d-flex flex-row">
                          <h6 style={cardTitleStyle}>
                            {place.title}
                            {place.isStarred && (
                              <i
                                className="fas fa-star text-warning ml-2"
                                style={{ cursor: "default" }}
                                title="Starred"
                              />
                            )}
                            {place.dayIndex >= 0 && (
                              <i
                                className="fa fa-calendar-check text-muted ml-2"
                                style={{ cursor: "default" }}
                                title="Added to itinerary"
                              />
                            )}
                          </h6>
                          {!isPublic && this.state.isHovering && (
                            <div className="ml-auto mb-n2">
                              <a
                                href="#"
                                onClick={this.handleDelete}
                                title="Delete place"
                                className="text-dark"
                              >
                                <i className="fas fa-trash" />
                              </a>
                            </div>
                          )}
                        </div>

                        {!isPublic && placeTime && <h6 style={cardTitleStyle}>{placeTime}</h6>}

                        {isPublic && (place.rating || place.totalRatings) && (
                          <div className="d-flex card-subtitle mb-n1">
                            {place.rating && (
                              <span className="text-muted text-sm">
                                {parseFloat(Math.round(place.rating * 10) / 10).toFixed(1) + " "}
                                &nbsp;
                              </span>
                            )}
                            {place.rating && (
                              <span className="text-xs" style={{ marginTop: "2px" }}>
                                {starRating}
                              </span>
                            )}
                            &nbsp;
                            {place.totalRatings && (
                              <span className="text-muted text-sm">
                                {"(" + place.totalRatings.toLocaleString() + ")"}
                              </span>
                            )}
                            {priceLevel && (
                              <span className="text-xs" style={{ marginTop: "2px" }}>
                                &nbsp;&nbsp;{"·"}&nbsp;&nbsp;{priceLevel}
                              </span>
                            )}
                          </div>
                        )}
                        <div
                          className="mt-2"
                          style={{
                            lineHeight: 1.3,
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                            wordBreak: "break-word",
                          }}
                        >
                          <ClampLines
                            text={this.props.notes}
                            id="clamped-place-notes"
                            lines={4}
                            moreText="+ More"
                            lessText="- Less"
                            ellipsis="..."
                            className={"text-sm" + (isPublic ? "" : " text-muted")}
                            stopPropagation={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Draggable>
          )}
        </MediaQuery>
      );
    } else {
      return (
        <MediaQuery maxWidth={MAX_WIDTH_OF_TABLET}>
          {isTabletOrSmaller => (
            <Draggable draggableId={place.id} index={this.props.index} isDragDisabled={isPublic}>
              {provided => (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  className="col-sm-12 mb-2"
                  tabIndex="0"
                  onMouseEnter={isTabletOrSmaller ? undefined : this.handleMouseEnter}
                  onMouseLeave={isTabletOrSmaller ? undefined : this.handleMouseLeave}
                  onClick={() => this.handleClick(place, isTabletOrSmaller)}
                >
                  <div
                    className="card h-100 border-0 shadow"
                    style={isPublic ? { cursor: "pointer" } : undefined}
                    onMouseDown={this.handleMouseDown}
                  >
                    <div className="d-flex align-items-start" style={cardNoNotesStyle}>
                      {place.thumbnailImgUrl && isPublic && (
                        <div className="mr-3">
                          <img
                            src={place.thumbnailImgUrl}
                            className="rounded"
                            alt="Picture of the place"
                            style={{ width: 80, height: 80, objectFit: "cover" }}
                          />
                        </div>
                      )}
                      <div className="w-100" style={cardBodyNoNotesStyle}>
                        <h6 style={cardTitleStyle}>
                          {place.title}
                          {place.isStarred && (
                            <i
                              className="fas fa-star text-warning ml-2"
                              style={{ cursor: "default" }}
                              title="Starred"
                            />
                          )}
                          {!isPublic && place.dayIndex >= 0 && (
                            <i
                              className="fa fa-calendar-check text-muted ml-2"
                              style={{ cursor: "default" }}
                              title="Added to itinerary"
                            />
                          )}
                        </h6>
                        {!isPublic && placeTime && <h6 style={cardTitleStyle}>{placeTime}</h6>}

                        {isPublic && (place.rating || place.totalRatings) && (
                          <div className="d-flex card-subtitle">
                            {place.rating && (
                              <span className="text-muted text-sm">
                                {parseFloat(Math.round(place.rating * 10) / 10).toFixed(1) + " "}
                                &nbsp;
                              </span>
                            )}
                            {place.rating && (
                              <span className="text-xs" style={{ marginTop: "2px" }}>
                                {starRating}
                              </span>
                            )}
                            &nbsp;
                            {place.totalRatings && (
                              <span className="text-muted text-sm">
                                {"(" + place.totalRatings.toLocaleString() + ")"}
                              </span>
                            )}
                            {priceLevel && (
                              <span className="text-xs" style={{ marginTop: "2px" }}>
                                &nbsp;&nbsp;{"·"}&nbsp;&nbsp;{priceLevel}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                      {/* TODO uncomment below out and merge with trashcan when save is supported for public view */}
                      {/* {isPublic && (
                    <div>
                      <button type="button" className="btn btn-outline-primary btn-sm">
                        <span>
                          <i className="far fa-bookmark" />
                        </span>
                        &nbsp;Save
                      </button>
                    </div>
                  )} */}
                      {!isPublic && this.state.isHovering && (
                        <a
                          href="#"
                          onClick={this.handleDelete}
                          className="text-dark ml-auto"
                          title="Delete place"
                        >
                          <i className="fas fa-trash" />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Draggable>
          )}
        </MediaQuery>
      );
    }
  }
}

const roundRating = rating => {
  const x = rating * 10;
  const result = x % 5 >= 2.5 ? parseInt(x / 5) * 5 + 5 : parseInt(x / 5) * 5;
  return result / 10;
};

const calculateStarRating = rating => {
  let star_ratings = [];
  const roundedRating = roundRating(rating);
  for (let i = 0; i < Math.floor(roundedRating); i++) {
    star_ratings.push(<i key={"star" + i} className="fa fa-star text-warning" />);
  }
  if ((roundedRating % 1).toFixed(1) * 10 > 0) {
    star_ratings.push(<i key={"star_half"} className="fa fa-star-half-alt text-warning" />);
  }
  for (let j = 0; j < 5 - Math.ceil(roundedRating); j++) {
    star_ratings.push(<i key={"empty_star" + j} className="far fa-star text-warning" />);
  }
  return star_ratings;
};

const calculatePriceLevel = price => {
  let price_level = [];
  for (let j = 0; j < price; j++) {
    price_level.push(<i key={"price-" + j} className="fa fa-dollar-sign text-muted" />);
  }
  return price_level;
};

const mapStateToProps = state => {
  return {
    isPublic: state.trips.isPublic,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deletePlace: (id, listId) => dispatch(deletePlace(id, listId)),
    showHighlightMarker: id => dispatch(showHighlightMarker(id)),
    hideHighlightMarker: id => dispatch(hideHighlightMarker(id)),
    showInfoWindow: placeId => dispatch(showInfoWindow(placeId)),
    showPlaceDetailsModal: placeId => dispatch(showPlaceDetailsModal(placeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceCard);
