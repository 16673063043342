import React, { Component } from "react";
import { connect } from "react-redux";
import { addPlaceFromGooglePlaces, addCustomPlace } from "../store/actions/placeActions";
import { showSubscribeModal } from "../store/actions/modalActions";
import PlacesAutocomplete from "react-places-autocomplete";
import BiggerThanTabletOnly from "./media_queries/BiggerThanTabletOnly";
import { SCOUT_PRO_PLACE_COUNT_THRESHOLD } from "./Constants";

const CUSTOM_PLACE_SUGGESTION_ID = "custom-place-suggestion-id";
class AddPlaceInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeTitle: "",
      shouldShowCustomAddPlace: false,
    };

    // sessionToken is used by Google Maps API so we aren't charged for the
    // autocomplete calls when we make the subsequent placeDetail() call.
    /*global google*/
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();

    const trip = this.props.trip;
    if (this.props.places && this.props.places.length > 0) {
      // Initialize the search bounds to the area surrounding the places in the trip
      let bounds = new google.maps.LatLngBounds();
      this.props.places.forEach(place => {
        place.lat && place.lng && bounds.extend(new google.maps.LatLng(place.lat, place.lng));
      });
      this.state["initialSearchBounds"] = bounds;
    } else if (trip && trip.lat && trip.lng) {
      // If no places in the trip, initialize the search bounds to a 15km (9.3 mi) radius
      // around the center of the trip
      const tripCenter = new google.maps.LatLng(trip.lat, trip.lng);
      const circle = new google.maps.Circle({ radius: 15000, center: tripCenter });
      this.state["initialSearchBounds"] = circle.getBounds();
    }
  }

  isValidPlaceTitleInput = placeTitle => {
    return placeTitle && placeTitle.trim();
  };

  // Adding more places is disabled if all of the following conditions are met:
  // More than SCOUT_PRO_PLACE_COUNT_THRESHOLD places added, user is not a Pro user, and
  // the trip was not created by a pro user. If any of the previous is false, then the user is
  // allowed to add places.
  shouldDisableInput = () => {
    return (
      this.props.places?.length > SCOUT_PRO_PLACE_COUNT_THRESHOLD &&
      !this.props.isProUser &&
      !this.props.isTripCreatorProUser
    );
  };

  handleChange = placeTitle => {
    this.setState({ placeTitle, shouldShowCustomAddPlace: true });
  };

  handleInputBlur = e => {
    this.setState({ shouldShowCustomAddPlace: false });
  };

  handlePlaceSelect = (address, googlePlaceId) => {
    const tripId = this.props.trip ? this.props.trip.id : null;

    if (googlePlaceId === CUSTOM_PLACE_SUGGESTION_ID || (!googlePlaceId && address)) {
      // GooglePlaceID is manually set to CUSTOM_PLACE_SUGGESTION_ID when adding a custom place.
      // If the user hits the enter key though, the PlacesAutoComplete component will pass us the current input value ("<title>")
      // as the address, with no GooglePlaceID.

      // Only allow user to select a custom place with actual text, do nothing if they enter whitespace only
      if (!this.isValidPlaceTitleInput(this.state.placeTitle)) {
        return;
      }

      this.props.addCustomPlace(
        this.state.placeTitle,
        this.props.listId,
        tripId,
        this.props.numPlaces
      );
    } else {
      if (!address || !googlePlaceId) {
        return;
      }

      // Fetch place photos if user is Pro or the trip owner is Pro
      const shouldFetchPlacePhoto = this.props.isProUser || this.props.isTripCreatorProUser;

      this.props.addPlaceFromGooglePlaces(
        googlePlaceId,
        this.props.numPlaces,
        this.props.listId,
        tripId,
        shouldFetchPlacePhoto,
        this.sessionToken
      );

      // Generate a new session token after the previous was used in addPlace()
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    }

    // Clear the state so the input is cleared
    this.setState({ placeTitle: "" });
    // Set focus to the place field
    this.placeInput.focus();
  };

  isPlaceAlreadyAdded = (alreadyAddedPlaceIds, placeId) => {
    return alreadyAddedPlaceIds && alreadyAddedPlaceIds.has(placeId);
  };

  onPlaceInputClick = () => {
    if (this.shouldDisableInput()) {
      this.props.showSubscribeModal();
    }
  };

  render() {
    const { places } = this.props;
    const alreadyAddedGooglePlaceIds = new Set(places.map(place => place.googlePlaceId));
    const customPlaceSuggestion = {
      description: "Add a custom place not tied to a specific address",
      id: CUSTOM_PLACE_SUGGESTION_ID,
      placeId: CUSTOM_PLACE_SUGGESTION_ID,
    };
    const activeSuggestionStyle = {
      backgroundColor: "#f8f9fa",
      color: "#4E66F8",
      cursor: "pointer",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      wordBreak: "break-word",
      whiteSpace: "normal",
    };
    const inactiveSuggestionStyle = {
      cursor: "pointer",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      wordBreak: "break-word",
      whiteSpace: "normal",
    };

    return (
      <PlacesAutocomplete
        value={this.state.placeTitle}
        onChange={this.handleChange}
        onSelect={this.handlePlaceSelect}
        onError={(status, clearSuggestions) => {
          if (status !== "ZERO_RESULTS") {
            // Log real errors from autocomplete (zero results is a normal occurrence)
            console.error("Error from React Places Autocomplete: ", status);
          }
          clearSuggestions();
        }}
        searchOptions={{
          locationBias: this.props.searchBounds || this.state.initialSearchBounds,
          sessionToken: this.sessionToken,
        }}
        highlightFirstSuggestion={true}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          const firstActiveSuggestion = suggestions.find(suggestion => suggestion.active);
          return (
            <div>
              <input
                {...getInputProps({
                  placeholder: "+ Add place or address",
                  className: "form-control",
                  onBlur: () => {
                    this.setState({ shouldShowCustomAddPlace: false });
                  },
                  onKeyDown: e => {
                    if (e.key === "Escape") {
                      this.setState({ shouldShowCustomAddPlace: false });
                    }
                  },
                })}
                onFocus={() => {
                  this.setState({ shouldShowCustomAddPlace: true });
                }}
                onClick={this.onPlaceInputClick}
                ref={input => {
                  this.placeInput = input;
                }}
                readOnly={this.shouldDisableInput()}
              />
              {(loading ||
                suggestions.length > 0 ||
                (this.state.shouldShowCustomAddPlace &&
                  this.isValidPlaceTitleInput(this.state.placeTitle))) && (
                <div
                  style={{
                    border: "1px solid rgba(0,0,0,.15)",
                    borderRadius: ".4rem",
                  }}
                >
                  {loading && <div className="dropdown-item">Searching...</div>}
                  {suggestions.map(suggestion => {
                    const className = "dropdown-item";
                    const style = suggestion.active
                      ? activeSuggestionStyle
                      : inactiveSuggestionStyle;
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {suggestion.formattedSuggestion.mainText}{" "}
                        </span>
                        <span
                          className="text-muted"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {suggestion.formattedSuggestion.secondaryText}
                        </span>
                        {this.isPlaceAlreadyAdded(
                          alreadyAddedGooglePlaceIds,
                          suggestion.placeId
                        ) && (
                          <>
                            <br />
                            <span className="text-info" style={{ fontSize: "12px" }}>
                              Already added
                            </span>
                          </>
                        )}
                      </div>
                    );
                  })}
                  {this.state.shouldShowCustomAddPlace && (
                    <div
                      {...getSuggestionItemProps(customPlaceSuggestion, {
                        className: "dropdown-item text-secondary text-sm d-flex flex-row",
                        // Set the style of the custom place input to "active" style only if there's no currently active suggestion.
                        style: firstActiveSuggestion
                          ? inactiveSuggestionStyle
                          : activeSuggestionStyle,
                      })}
                    >
                      <i className="fas fa-plus mr-2 mt-1" />
                      Add '{this.state.placeTitle}'
                      <BiggerThanTabletOnly>
                        <>
                          {firstActiveSuggestion && firstActiveSuggestion.index === 0 && (
                            <span className="text-muted ml-auto text-nowrap">
                              <i className="fas fa-arrow-up" /> arrow
                            </span>
                          )}
                        </>
                      </BiggerThanTabletOnly>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchBounds: state.map.searchBounds,
    places: state.firestore.ordered.places,
    auth: state.firebase.auth,
    isProUser: state.auth.isProUser,
    isTripCreatorProUser: state.trips.isTripCreatorProUser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addPlaceFromGooglePlaces: (
      placeId,
      placeIndex,
      listId,
      tripId,
      shouldFetchPlacePhoto,
      autocompleteSessionToken
    ) =>
      dispatch(
        addPlaceFromGooglePlaces(
          placeId,
          placeIndex,
          listId,
          tripId,
          shouldFetchPlacePhoto,
          autocompleteSessionToken
        )
      ),
    addCustomPlace: (title, listId, tripId, placeIndex) =>
      dispatch(addCustomPlace(title, listId, tripId, placeIndex)),
    showSubscribeModal: () => dispatch(showSubscribeModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPlaceInput);
