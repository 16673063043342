import React from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { createList } from "../store/actions/listActions";
import { hideAddListForm } from "../store/actions/modalActions";

class AddListForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.title.trim() !== "") {
      const tripId = this.props.trip ? this.props.trip.id : null;
      this.props.createList(tripId, {
        title: this.state.title,
        listIndex: Math.max(
          ...this.props.lists.map(list => (list.listIndex !== undefined ? list.listIndex + 1 : 0)),
          0
        ),
      });
    }
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ title: "" });
    this.props.hideAddListForm();
  };

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleEntered = () => {
    // Set focus to the title field
    this.titleInput.focus();
  };

  render() {
    return (
      <Modal
        id="task-modal"
        show={this.props.showAddListModal}
        onHide={this.handleClose}
        onEntered={this.handleEntered}
      >
        <form id="create-list-form" onSubmit={this.handleSubmit}>
          <Modal.Body>
            <button
              type="button"
              className="close btn btn-round"
              onClick={this.handleClose}
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </button>
            <h4 className="modal-title mb-4">New Section</h4>
            <div className="form-group">
              <input
                className="form-control col"
                type="text"
                id="listTitle"
                placeholder='For example, "Restaurants"'
                name="title"
                onChange={this.updateInput}
                value={this.state.title}
                autoComplete="off"
                ref={input => {
                  this.titleInput = input;
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <button className="btn btn-primary" type="submit">
              Create List
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    showAddListModal: state.modals.showAddListModal,
    lists: state.firestore.ordered.lists,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createList: (tripId, listDetails) => dispatch(createList(tripId, listDetails)),
    hideAddListForm: () => dispatch(hideAddListForm()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddListForm);
