export const showInfoWindow = infoIndex => {
  // infoIndex is a placeId
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_INFO_WINDOW", infoIndex: infoIndex });
  };
};

export const hideInfoWindow = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_INFO_WINDOW" });
  };
};

export const setSearchBounds = latLngBounds => {
  return (dispatch, getState) => {
    dispatch({ type: "SET_SEARCH_BOUNDS", latLngBounds: latLngBounds });
  };
};

export const showOverlayRoute = dayIndex => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_OVERLAY_ROUTE", overlayRouteDayIndex: dayIndex });
  };
};

export const hideOverlayRoute = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_OVERLAY_ROUTE" });
  };
};

export const showCurrentLocationMarker = currentLatLng => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_MAP_CURRENT_LOCATION_MARKER", currentLatLng });
  };
};

export const hideCurrentLocationMarker = () => {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_MAP_CURRENT_LOCATION_MARKER" });
  };
};
