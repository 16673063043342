import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";

export class DeleteConfirmationModal extends Component {
  render() {
    return (
      <div>
        <Modal show={this.props.showDeleteConfirmation} onHide={this.props.handleClose}>
          <Modal.Body>
            <button type="button" className="close btn btn-round" onClick={this.props.handleClose}>
              <i className="material-icons">close</i>
            </button>
            <h3 className="modal-title mb-3">{this.props.title}</h3>
            <p className="text-muted">{this.props.body}</p>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-muted"
              onClick={this.props.handleClose}
            >
              Cancel
            </button>
            <button type="button" className="btn btn-danger" onClick={this.props.handleDelete}>
              {this.props.deleteButtonMessage}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default DeleteConfirmationModal;
