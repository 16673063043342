import React, { Component } from "react";
import Select from "react-select";
import { compose } from "redux";
import { connect } from "react-redux";
import { showAddListForm } from "../store/actions/modalActions";
import {
  updatePlaceList,
  hideSpecificPlaceSearchResult,
  addPlaceFromGooglePlaces,
  clearPoiInfo,
} from "../store/actions/placeActions";
import { hideInfoWindow } from "../store/actions/mapActions";
import { withRouter } from "react-router-dom";

class PlaceInfoListSelect extends Component {
  constructor(props) {
    super(props);

    this.state = { value: null };

    if (props.type === "LIST") {
      let matchingList = props.lists.find(list => {
        return list.id === props.place.listId;
      });
      if (matchingList) {
        this.state = {
          value: { value: matchingList.id, label: matchingList.title },
        };
      }
    }
  }

  handleChange = (val, action) => {
    const place = this.props.place;

    if (action.action === "select-option") {
      if (val.value === "new_list") {
        this.props.showAddListForm();
      } else if (this.props.type === "LIST") {
        // **** Move a place between Lists ****
        let mutatedList = [];
        let movedPlaceId = null;
        let movedPlaceDestinationListOrDay = null;
        const sourceListId = place.listId;
        const destinationListId = val.value;
        const sourcePlaceIndex = place.placeIndex;
        const destinationPlaceIndex = this.props.places.filter(
          place => place.listId === destinationListId
        ).length;

        // Place has not changed lists
        if (sourceListId === destinationListId) {
          return;
        }

        //Decrement placeIndex of places in source list
        this.props.places
          .filter(place => place.listId === sourceListId)
          .map((currPlace, i) => {
            const currPlaceIndex = currPlace.placeIndex;
            if (currPlaceIndex === sourcePlaceIndex) {
              mutatedList.push({
                id: currPlace.id,
                placeIndex: destinationPlaceIndex,
              });
              movedPlaceId = currPlace.id;
              movedPlaceDestinationListOrDay = destinationListId;
            }
            if (currPlaceIndex > sourcePlaceIndex) {
              mutatedList.push({
                id: currPlace.id,
                placeIndex: currPlaceIndex - 1,
              });
            }
          });

        // Increment placeIndex of places in destination list
        this.props.places
          .filter(place => place.listId === destinationListId)
          .map((currPlace, i) => {
            const currPlaceIndex = currPlace.placeIndex;
            if (currPlaceIndex >= destinationPlaceIndex) {
              mutatedList.push({
                id: currPlace.id,
                placeIndex: currPlaceIndex + 1,
              });
            }
          });
        const context = {
          movedPlaceId: movedPlaceId,
          movedPlaceDestinationListOrDay: movedPlaceDestinationListOrDay,
        };
        this.props.updatePlaceList("LIST", "BETWEEN", mutatedList, context);
        this.setState({ value: { value: val.value, label: val.label } });
      } else if (this.props.type === "SEARCH_RESULT" || this.props.type === "POI") {
        // Add place to the list with listId
        this.props.addPlaceFromGooglePlaces(
          place.googlePlaceId,
          this.props.places.filter(place => place.listId === val.value).length,
          val.value,
          this.props.match.params.tripId,
          this.props.isProUser || this.props.isTripCreatorProUser,
          null // No autocompleteSessionToken in this case
        );

        if (this.props.type === "POI") {
          this.props.clearPoiInfo();
        }
        if (this.props.type === "SEARCH_RESULT") {
          this.props.hideSpecificPlaceSearchResult(place.googlePlaceId);
        }
        this.props.hideInfoWindow();

        this.setState({ value: { value: val.value, label: val.label } });
      }
    }
    if (action.action === "clear") {
      this.setState({ value: null });
    }
  };

  render() {
    // Sort the lists by listIndex if all lists have a listIndex
    //   Note: originally lists didn't have a listIndex hence this check is necessary
    let listCopy = [...this.props.lists];
    let allHaveListIndex = true;
    listCopy.forEach(list => (allHaveListIndex = allHaveListIndex && "listIndex" in list));
    if (allHaveListIndex) {
      listCopy.sort((a, b) => (a.listIndex > b.listIndex ? 1 : -1));
    }

    const styles = {
      menuPortal: base => ({ ...base, zIndex: 9999 }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        if (data.value === "new_list") {
          return {
            ...styles,
            color: "#E83E8C",
          };
        } else {
          return styles;
        }
      },
      placeholder: defaultStyles => {
        return {
          ...defaultStyles,
          color: "#4E66F8",
        };
      },
    };

    const options = [
      {
        options: listCopy.map(list => ({ value: list.id, label: list.title })),
      },
      {
        options: [{ value: "new_list", label: "+ New list" }],
      },
    ];

    return (
      <Select
        options={options}
        value={this.state.value}
        menuPortalTarget={document.body}
        styles={styles}
        isClearable={true}
        isMulti={false}
        hideSelectedOptions={false}
        isSearchable={false}
        menuShouldBlockScroll={true}
        onChange={this.handleChange}
        placeholder={"Add to list..."}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    lists: state.firestore.ordered.lists,
    places: state.firestore.ordered.places,
    isProUser: state.auth.isProUser,
    isTripCreatorProUser: state.trips.isTripCreatorProUser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showAddListForm: () => dispatch(showAddListForm()),
    updatePlaceList: (listType, moveType, places, context) =>
      dispatch(updatePlaceList(listType, moveType, places, context)),
    hideInfoWindow: () => dispatch(hideInfoWindow()),
    hideSpecificPlaceSearchResult: googlePlaceId =>
      dispatch(hideSpecificPlaceSearchResult(googlePlaceId)),
    addPlaceFromGooglePlaces: (
      placeId,
      placeIndex,
      listId,
      tripId,
      shouldFetchPlacePhoto,
      autocompleteSessionToken
    ) =>
      dispatch(
        addPlaceFromGooglePlaces(
          placeId,
          placeIndex,
          listId,
          tripId,
          shouldFetchPlacePhoto,
          autocompleteSessionToken
        )
      ),
    clearPoiInfo: () => dispatch(clearPoiInfo()),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PlaceInfoListSelect);
