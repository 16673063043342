const initState = {
  showAddListModal: false,
  showRenameListModal: false,
  showRenameTripModal: false,
  showReorderListsModal: false,
  showDeleteTripConfirmationModal: false,
  showDeleteListConfirmationModal: false,
  showComingSoonModal: false,
  showAddToItineraryModal: false,
  showDayInfoModal: false,
  showTripSettingsModal: false,
  showPublishingSettingsModal: false,
  shouldShowPublishTripModal: false,
  showAddLodgingModal: false,
  showPlaceTimeModal: false,
  showGalleryModal: false,
  showPlaceDetailsModal: false,
  deleteDayDayIndex: null,
  renameListId: null,
  renameListTitle: null,
  renameListColor: null,
  deleteListId: null,
  addLodgingPlaceId: null,
  placeTimeModalPlaceId: null,
  galleryModalPlaceId: null,
  addToItineraryPlaceId: null,
  listPickerModalPlaceId: null,
  placeDetailsModalPlaceId: null,
  dayInfoIndex: null,
  isGetViewOnlyLinkShown: false,
  showAuthenticatedModal: false,
  authAction: null,
  authActionProps: null,
  shouldShowSubscribeModal: false,
};

const modalReducer = (state = initState, action) => {
  switch (action.type) {
    case "SHOW_ADD_LIST_FORM":
      return {
        ...state,
        showAddListModal: true,
      };
    case "HIDE_ADD_LIST_FORM":
      return {
        ...state,
        showAddListModal: false,
      };
    case "SHOW_RENAME_LIST_FORM":
      return {
        ...state,
        showRenameListModal: true,
        renameListId: action.renameListId,
        renameListTitle: action.renameListTitle,
        renameListColor: action.renameListColor,
      };
    case "HIDE_RENAME_LIST_FORM":
      return {
        ...state,
        showRenameListModal: false,
        renameListId: null,
        renameListTitle: null,
        renameListColor: null,
      };
    case "SHOW_RENAME_TRIP_FORM":
      return {
        ...state,
        showRenameTripModal: true,
      };
    case "HIDE_RENAME_TRIP_FORM":
      return {
        ...state,
        showRenameTripModal: false,
      };
    case "SHOW_DELETE_TRIP_CONFIRMATION_MODAL":
      return {
        ...state,
        showDeleteTripConfirmationModal: true,
      };
    case "HIDE_DELETE_TRIP_CONFIRMATION_MODAL":
      return {
        ...state,
        showDeleteTripConfirmationModal: false,
      };
    case "SHOW_DELETE_LIST_CONFIRMATION_MODAL":
      return {
        ...state,
        showDeleteListConfirmationModal: true,
        deleteListId: action.listId,
      };
    case "HIDE_DELETE_LIST_CONFIRMATION_MODAL":
      return {
        ...state,
        showDeleteListConfirmationModal: false,
        deleteListId: null,
      };
    case "SHOW_COMING_SOON_MODAL":
      return {
        ...state,
        showComingSoonModal: true,
      };
    case "HIDE_COMING_SOON_MODAL":
      return {
        ...state,
        showComingSoonModal: false,
      };
    case "SHOW_DAY_INFO_MODAL":
      return {
        ...state,
        showDayInfoModal: true,
        dayInfoIndex: action.dayIndex,
      };
    case "HIDE_DAY_INFO_MODAL":
      return {
        ...state,
        showDayInfoModal: false,
        dayInfoIndex: null,
      };
    case "SHOW_SHARE_TRIP_MODAL":
      return {
        ...state,
        showShareTripModal: true,
      };
    case "HIDE_SHARE_TRIP_MODAL":
      return {
        ...state,
        showShareTripModal: false,
      };
    case "SHOW_REORDER_LIST_MODAL":
      return {
        ...state,
        showReorderListsModal: true,
      };
    case "HIDE_REORDER_LIST_MODAL":
      return {
        ...state,
        showReorderListsModal: false,
      };
    case "SHOW_ADD_TO_ITINERARY_MODAL":
      return {
        ...state,
        showAddToItineraryModal: true,
        addToItineraryPlaceId: action.place.id,
      };
    case "HIDE_ADD_TO_ITINERARY_MODAL":
      return {
        ...state,
        showAddToItineraryModal: false,
        addToItineraryPlaceId: null,
      };
    case "SHOW_GET_VIEW_ONLY_LINK":
      return {
        ...state,
        isGetViewOnlyLinkShown: true,
      };
    case "HIDE_GET_VIEW_ONLY_LINK":
      return {
        ...state,
        isGetViewOnlyLinkShown: false,
      };
    case "SHOW_TRIP_SETTINGS_MODAL":
      return {
        ...state,
        showTripSettingsModal: true,
      };
    case "HIDE_TRIP_SETTINGS_MODAL":
      return {
        ...state,
        showTripSettingsModal: false,
      };
    case "SHOW_PUBLISH_SETTINGS_MODAL":
      return {
        ...state,
        shouldShowPublishingSettingsModal: true,
      };
    case "HIDE_PUBLISH_SETTINGS_MODAL":
      return {
        ...state,
        shouldShowPublishingSettingsModal: false,
      };
    case "SHOW_PUBLISH_TRIP_MODAL":
      return {
        ...state,
        shouldShowPublishTripModal: true,
      };
    case "HIDE_PUBLISH_TRIP_MODAL":
      return {
        ...state,
        shouldShowPublishTripModal: false,
      };
    case "SHOW_ADD_LODGING_MODAL":
      return {
        ...state,
        showAddLodgingModal: true,
        addLodgingPlaceId: action.placeId,
      };
    case "HIDE_ADD_LODGING_MODAL":
      return {
        ...state,
        showAddLodgingModal: false,
        addLodgingPlaceId: null,
      };
    case "SHOW_PLACE_TIME_MODAL":
      return {
        ...state,
        showPlaceTimeModal: true,
        placeTimeModalPlaceId: action.placeId,
      };
    case "HIDE_PLACE_TIME_MODAL":
      return {
        ...state,
        showPlaceTimeModal: false,
        placeTimeModalPlaceId: null,
      };
    case "SHOW_PLACE_DETAILS_MODAL":
      return {
        ...state,
        showPlaceDetailsModal: true,
        placeDetailsModalPlaceId: action.placeId,
      };
    case "HIDE_PLACE_DETAILS_MODAL":
      return {
        ...state,
        showPlaceDetailsModal: false,
        placeDetailsModalPlaceId: null,
      };
    case "SHOW_GALLERY_MODAL":
      return {
        ...state,
        showGalleryModal: true,
        galleryModalPlaceId: action.placeId,
      };
    case "HIDE_GALLERY_MODAL":
      return {
        ...state,
        showGalleryModal: false,
        galleryModalPlaceId: null,
      };
    case "SHOW_LIST_PICKER_MODAL":
      return {
        ...state,
        showListPickerModal: true,
        listPickerModalPlaceId: action.placeId,
      };
    case "HIDE_LIST_PICKER_MODAL":
      return {
        ...state,
        showListPickerModal: false,
        listPickerModalPlaceId: null,
      };
    case "SHOW_AUTHENTICATED_MODAL":
      return {
        ...state,
        showAuthenticatedModal: true,
        authAction: action.authAction,
        authActionProps: action.authActionProps,
      };
    case "HIDE_AUTHENTICATED_MODAL":
      return {
        ...state,
        showAuthenticatedModal: false,
        authAction: null,
        authActionProps: null,
      };
    case "SHOW_SUBSCRIBE_MODAL":
      return {
        ...state,
        shouldShowSubscribeModal: true,
      };
    case "HIDE_SUBSCRIBE_MODAL":
      return {
        ...state,
        shouldShowSubscribeModal: false,
      };
    default:
      return state;
  }
};
export default modalReducer;
