import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  signUp,
  signUpWithGoogle,
  signUpWithFacebook,
  onSignupUnmounted,
} from "../../store/actions/authActions";

class SignUp extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    showSignupWithEmailForm: false,
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { firstName, lastName, email, password } = this.state;
    this.props.signUp({
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
    });
  };

  handleSignUpWithEmailClick = () => {
    this.setState({ showSignupWithEmailForm: true });
  };

  componentWillUnmount() {
    this.props.onSignupUnmounted();
  }

  render() {
    const { authError, isModalContent, handleCloseModal, onSigninClick } = this.props;
    const showSignupWithEmailForm = this.state.showSignupWithEmailForm;

    return (
      <div className="text-center">
        {isModalContent ? (
          <>
            <div className="mb-5">
              <div className="text-left justify-content-between d-flex">
                <h2>Create Account</h2>
                <button
                  type="button"
                  className="close btn btn-round"
                  onClick={handleCloseModal}
                  aria-label="Close"
                >
                  <i className="material-icons">close</i>
                </button>
              </div>
              <p className="text-left text-muted">It's easy and free</p>
            </div>
            {authError ? (
              <div className="alert alert-danger" role="alert">
                {authError}
              </div>
            ) : null}
          </>
        ) : (
          <>
            <div className="mb-5">
              <h2>Create Account</h2>
              <p className="text-muted">It's easy and free</p>
            </div>
            {authError ? (
              <div className="alert alert-danger" role="alert">
                {authError}
              </div>
            ) : null}
          </>
        )}
        <button
          className="btn text-white btn-block d-flex align-items-center mb-3"
          onClick={this.props.signUpWithFacebook}
          style={{ backgroundColor: "#3975EA", letterSpacing: ".15em" }}
        >
          <img
            alt="Facebook logo"
            src="/assets/icons/logo-fb.png"
            className="rounded mr-2"
            style={{ width: "26px" }}
          />
          <span className="flex-grow-1" id="continueWithFacebook">
            Continue with Facebook
          </span>
        </button>
        <button
          className="btn btn-outline-muted btn-block d-flex align-items-center"
          onClick={this.props.signUpWithGoogle}
          style={{ letterSpacing: ".15em" }}
        >
          <img alt="Google logo" src="/assets/icons/logo-google.svg" className="rounded mr-2" />
          <span className="flex-grow-1" id="continueWithGoogle">
            Continue with Google
          </span>
        </button>
        <hr data-content="OR" className="my-3 hr-text letter-spacing-2" />
        {showSignupWithEmailForm ? (
          <form onSubmit={this.handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <input
                  className="form-control"
                  id="firstName"
                  type="text"
                  placeholder="First name"
                  name="fname"
                  onChange={this.handleChange}
                  autoFocus
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <input
                  className="form-control"
                  id="lastName"
                  type="text"
                  placeholder="Last name"
                  name="lname"
                  onChange={this.handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <input
                className="form-control"
                id="email"
                type="email"
                placeholder="Email address"
                name="email"
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                id="password"
                type="password"
                placeholder="Password"
                name="new-password"
                onChange={this.handleChange}
                required
              />
            </div>
            <button className="btn btn-lg btn-block btn-primary mb-3" type="submit">
              Create Account
            </button>
          </form>
        ) : (
          <button
            className="btn btn-outline-muted btn-block mb-5"
            onClick={this.handleSignUpWithEmailClick}
          >
            <span className="align-middle" id="showSignUpWithEmail">
              Sign up with email
            </span>
          </button>
        )}

        <p className="mb-5 text-muted text-sm">
          Already have an account?{" "}
          <Link to="/signin" onClick={onSigninClick}>
            Sign in
          </Link>
        </p>

        <p className="text-muted text-sm">
          By creating an account, you are agreeing to our{" "}
          <Link to="/terms" className="text-muted">
            <u>Terms of Service</u>
          </Link>{" "}
          and{" "}
          <Link to="/privacy" className="text-muted">
            <u>Privacy Policy</u>
          </Link>
          .
        </p>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signUp: newUser => dispatch(signUp(newUser)),
    signUpWithGoogle: () => dispatch(signUpWithGoogle()),
    signUpWithFacebook: () => dispatch(signUpWithFacebook()),
    onSignupUnmounted: () => dispatch(onSignupUnmounted()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);
