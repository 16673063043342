import React, { Component } from "react";
import { connect } from "react-redux";
import { InfoWindow } from "react-google-maps";
import PlaceInfoWindow from "./PlaceInfoWindow";
import { clearPoiInfo } from "../store/actions/placeActions";
import { hideInfoWindow } from "../store/actions/mapActions";

class PlaceMarkerInfoWindow extends Component {
  handleInfoWindowCloseClick = () => {
    this.props.hideInfoWindow();
    this.props.clearPoiInfo();
  };

  render() {
    return (
      <InfoWindow
        onCloseClick={this.handleInfoWindowCloseClick}
        options={{
          closeBoxURL: ``,
          enableEventPropagation: true,
        }}
      >
        <div
          style={{
            lineHeight: 1.6,
            fontWeight: 400,
            fontFamily: "Poppins,sans-serif",
            padding: ".5rem",
            width: "440px",
            maxHeight: "500px",
          }}
        >
          <PlaceInfoWindow {...this.props} />
        </div>
      </InfoWindow>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideInfoWindow: () => dispatch(hideInfoWindow()),
    clearPoiInfo: () => dispatch(clearPoiInfo()),
  };
};

export default connect(null, mapDispatchToProps)(PlaceMarkerInfoWindow);
