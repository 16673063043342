import React from "react";
import ErrorPage from "./ErrorPage";
import { BUTTON_OPTIONS } from "./ErrorPage";

function CopyingTripError() {
  return (
    <ErrorPage
      errorTitle="Couldn't make a copy"
      errorSubtitle="Our copy machine hit a jam. You can go back and try again."
      imgSrc="/assets/img/illustrations/undraw_pet_adoption_2qkw.svg"
      altText="Man staring at cat picture"
      buttonOption={BUTTON_OPTIONS.BACK}
    />
  );
}

export default CopyingTripError;
