import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./store/reducers/rootReducer";
import thunk from "redux-thunk";
import { createFirestoreInstance } from "redux-firestore";
import { ReactReduxFirebaseProvider, getFirebase, isLoaded } from "react-redux-firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/browser";
import { CaptureConsole } from "@sentry/integrations";
import LoadingComponent from "./components/LoadingComponent";
import { composeWithDevTools } from "redux-devtools-extension";

const fbConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "scoutexplore.com",
  databaseURL: "https://scout-test-c0176.firebaseio.com",
  projectId: "scout-test-c0176",
  storageBucket: "scout-test-c0176.appspot.com",
  messagingSenderId: "1098835797707",
};
const rrfConfig = {
  useFirestoreForProfile: true,
  userProfile: "users",
};

const initialState = {};
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk.withExtraArgument({ getFirebase })))
);

firebase.initializeApp(fbConfig);
var firebaseDb = firebase.firestore();

if (
  process.env.REACT_APP_USE_LOCAL_EMULATOR === "true" &&
  process.env.NODE_ENV === "development" &&
  window.location.hostname === "localhost"
) {
  // From https://firebase.google.com/docs/emulator-suite/connect_and_prototype?database=Firestore
  // Note that the Firebase Web SDK must connect to the WebChannel port
  firebaseDb.settings({
    host: "localhost:8080",
    ssl: false,
  });
  firebase.functions().useFunctionsEmulator("http://localhost:5001");
} else {
  firebase.functions();
}

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://b8ff1cb813d04f0881771ad3b513a870@sentry.io/1854436",
    integrations: [new CaptureConsole({ levels: ["error"] })],
    ignoreErrors: ["mergeOptions is not a function"],
  });
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => {
    return state.firebase.auth;
  });
  if (!isLoaded(auth)) return <LoadingComponent />;
  return children;
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <AuthIsLoaded>
        <App />
      </AuthIsLoaded>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
