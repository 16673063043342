import React from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { hidePlaceDetailsModal } from "../store/actions/modalActions";
import { selectTab } from "../store/actions/tripActions";
import { deletePlace } from "../store/actions/placeActions";
import PlaceNotes from "./PlaceNotes";
import { YELP_SUPPORTED_COUNTRIES, TABELOG_SUPPORTED_COUNTRIES } from "./Constants";

const PlaceDetailsModal = () => {
  const showPlaceDetailsModal = useSelector(state => state.modals.showPlaceDetailsModal);
  const placesMap = useSelector(state => state.firestore.data.places);
  const placeId = useSelector(state => state.modals.placeDetailsModalPlaceId);
  const isPublic = useSelector(state => state.trips.isPublic);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hidePlaceDetailsModal());
  };

  const handleViewOnMap = () => {
    handleClose();
    dispatch(selectTab("MAP"));
  };

  const handleDelete = () => {
    handleClose();
    dispatch(deletePlace(placeId, place.listId));
  };

  const place = placeId && placesMap[placeId];
  let usa_adapted_phone = "";
  let yelpLink = "";
  let tripAdvisorLink = "";
  let tabelogLink = "";

  // Show the international phone number if it's not a US number (+1)
  if (place && place.phone && place.formattedPhone) {
    const phone_reg = /\+1\s.+/;
    usa_adapted_phone = phone_reg.test(place.phone) ? place.formattedPhone : place.phone;
  }

  if (place?.addressComponents && place?.addressComponents.city !== "" && place?.name) {
    const searchBaseUrl =
      "https://www.google.com/search?source=hp&hl=en&btnI=I%27m%20Feeling%20Lucky&q=";
    tripAdvisorLink =
      searchBaseUrl +
      encodeURIComponent(
        "site:tripadvisor.com" + " " + place.name + " " + place.addressComponents.city
      );
    if (YELP_SUPPORTED_COUNTRIES.includes(place.addressComponents.country)) {
      yelpLink =
        searchBaseUrl +
        encodeURIComponent(
          "site:yelp.com" +
            " " +
            place.name +
            " " +
            place.addressComponents.streetNumber +
            " " +
            place.addressComponents.streetName +
            " " +
            place.addressComponents.city
        );
    }
    if (
      TABELOG_SUPPORTED_COUNTRIES.includes(place.addressComponents.country) &&
      place.types.some(item => ["food", "restaurant", "meal_takeaway"].includes(item))
    ) {
      tabelogLink =
        searchBaseUrl +
        encodeURIComponent(
          "site:tabelog.com" +
            " " +
            place.name +
            " " +
            place.addressComponents.streetNumber +
            " " +
            place.addressComponents.streetName +
            " " +
            place.addressComponents.city
        );
    }
  }

  return (
    <Modal id="place-details-modal" show={showPlaceDetailsModal} onHide={handleClose}>
      <Modal.Body>
        <button
          type="button"
          className="close btn btn-round"
          onClick={handleClose}
          aria-label="Close"
        >
          <i className="material-icons">close</i>
        </button>
        {place?.title && <h3 className="mb-3">{place.title}</h3>}
        {place?.address && (
          <div
            className="mb-1 d-flex flex-row text-muted "
            id="modal-place-address"
            style={{ lineHeight: 1.2 }}
          >
            <i
              className="fas fa-map-marker-alt mr-3"
              style={{ paddingRight: "4px", paddingTop: "2px" }}
            />
            <a
              className="text-sm text-muted"
              href={
                place.googleMapsUrl ||
                "https://www.google.com/maps/search/?api=1&query=" +
                  encodeURIComponent(place.address)
              }
              target="_blank"
            >
              {place.address}
            </a>
          </div>
        )}

        {place?.phone && (
          <div className="mb-1 d-flex flex-row" id="modal-place-phone-number">
            <i className="fas fa-phone mr-3 text-muted" style={{ paddingTop: "2px" }} />
            <a className="text-sm text-muted" href={`tel:${place.phone}`}>
              {usa_adapted_phone}
            </a>
          </div>
        )}
        <div>
          {yelpLink !== "" && (
            <a
              className="text-sm mr-3"
              href={yelpLink}
              target="_blank"
              style={{ color: "#d32323" }}
              id="modal-place-yelp-link"
            >
              <i className="fab fa-yelp mr-1" /> Yelp
            </a>
          )}
          {tabelogLink !== "" && (
            <a
              className="text-sm mr-3"
              href={tabelogLink}
              target="_blank"
              style={{ color: "#F19D42" }}
              id="modal-place-tabelog-link"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/tabelog_logo.png?alt=media&token=f1305278-a888-49d2-9322-060f55a25aac"
                height="22"
              />
              Tabelog
            </a>
          )}
          {tripAdvisorLink !== "" && (
            <a
              className="text-sm mr-3"
              href={tripAdvisorLink}
              target="_blank"
              style={{ color: "#00a680" }}
              id="modal-place-tripadvisor-link"
            >
              <i className="fab fa-tripadvisor mr-1" /> TripAdvisor
            </a>
          )}
          {place?.googleMapsUrl && (
            <a
              className="text-sm"
              href={place.googleMapsUrl}
              target="_blank"
              style={{ color: "#4285F4" }}
              id="modal-place-google-maps-link"
            >
              <i className="fab fa-google mr-1" /> Google
            </a>
          )}
        </div>
        <div className="my-4 d-flex flex-row" id="modal-place-view-on-map">
          <button className="btn btn-primary btn-block" onClick={handleViewOnMap}>
            <i className="far fa-map mr-2" style={{ paddingTop: "2px" }} />
            View on map
          </button>
        </div>
        {place && placeId && <PlaceNotes notes={place.notes} placeId={placeId} />}
        {!isPublic && (
          <div className="d-flex flex-row">
            <button className="mt-3 link-button text-dark ml-auto" onClick={handleDelete}>
              <i className="fas fa-trash mr-2" />
            </button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PlaceDetailsModal;
