import React from "react";
import LoadingPage from "./LoadingPage";
import CopyingTripError from "./error_pages/CopyingTripError";

const CopyingTripLoadingPage = () => {
  return (
    <LoadingPage
      shouldShowSpinner
      spinnerText="Copying Trip..."
      imgAlt="Loading page"
      imgSrc="/assets/img/illustrations/undraw_photocopy_gj0t.svg"
      errorComponent={CopyingTripError}
    />
  );
};

export default CopyingTripLoadingPage;
