import firebase from "firebase/compat/app";
import { getApp } from "@firebase/app";
import { getStripePayments, getCurrentUserSubscriptions } from "@stripe/firestore-stripe-payments";

/**
 * Fetches public user data for a given userId. Returns null
 * if no document exists for the specified userId, or if there
 * is an error fetching the document.
 * @returns Object with the userId and the data found on the document
 */
export const fetchPublicUserData = async userId => {
  try {
    const userSnapshot = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();
    if (userSnapshot.exists) {
      return { id: userSnapshot.id, ...userSnapshot.data() };
    } else {
      console.error(`User ${userId} doesn't exist`);
      return null;
    }
  } catch (err) {
    console.error(`Error fetching public user data for user ${userId}: ${err}`);
    return null;
  }
};

/**
 * Checks if a user is subscribed to the Pro plan by checking if they have any active subscriptions
 *
 * @returns {Promise<boolean>} If user is subscribed or not
 */
export const isUserProSubscription = async () => {
  const app = getApp();
  const payments = getStripePayments(app, {
    productsCollection: "products",
    customersCollection: "users",
  });

  const subscriptions = await getCurrentUserSubscriptions(payments, {
    status: ["active", "trialing"],
  });
  return subscriptions?.length > 0;
};
