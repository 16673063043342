import history from "../../Router/history";
import * as Sentry from "@sentry/browser";
import { isUserProSubscription } from "../../api/UsersAPI";

export const signIn = credentials => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(userCredential => {
        const user = userCredential.user;
        dispatch({ type: "LOGIN_SUCCESS" });
        window.analytics.identify(user.uid, {
          name: user.displayName,
          email: user.email,
        });
        window.analytics.track("Signed In", { authType: "email" });
      })
      .catch(err => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    window.analytics.reset();
    window.analytics.track("Signed Out");

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "CLEAR_SUBSCRIPTION_STATUS" });
        dispatch({ type: "SIGNOUT_SUCCESS" });
        history.push("/");
      });
  };
};

export const signUp = newUser => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    if (!newUser.firstName || !newUser.lastName) {
      dispatch({ type: "SIGNUP_ERROR", err: new Error("First and last name can't be empty") });
      return null;
    }

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then(resp => {
        const user = resp.user;
        window.analytics.identify(user.uid, {
          name: user.displayName,
          email: user.email,
        });
        window.analytics.track("Signed Up", { authType: "email" });

        const createPublicUserData = firestore
          .collection("users")
          .doc(user.uid)
          .set({
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            name: newUser.firstName + " " + newUser.lastName,
          });

        const createPrivateUserData = firestore
          .collection("users")
          .doc(user.uid)
          .collection("privateUserData")
          .doc(user.uid)
          .set({
            email: user.email,
          });
        return Promise.all([createPublicUserData, createPrivateUserData]);
      })
      .then(() => {
        dispatch({ type: "SIGNUP_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "SIGNUP_ERROR", err });
      });
  };
};

export const signUpWithGoogle = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    var provider = new firebase.auth.GoogleAuthProvider();
    const firestore = firebase.firestore();

    firebase.auth().useDeviceLanguage();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function(result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        window.analytics.identify(user.uid, {
          name: user.displayName,
          email: user.email,
        });
        if (result.additionalUserInfo?.isNewUser) {
          window.analytics.track("Signed Up", { authType: "google" });
        } else {
          window.analytics.track("Signed In ", { authType: "google" });
        }

        const createPublicUserData = firestore
          .collection("users")
          .doc(user.uid)
          .set(
            {
              name: user.displayName,
              firstName: result?.additionalUserInfo?.profile?.given_name || "",
              lastName: result?.additionalUserInfo?.profile?.family_name || "",
              photoURL: user.photoURL,
            },
            { merge: true } // Merge the data so in the case of signing-in, existing data isn't overwritten
          );

        const createPrivateUserData = firestore
          .collection("users")
          .doc(user.uid)
          .collection("privateUserData")
          .doc(user.uid)
          .set({
            email: user.email,
          });

        return Promise.all([createPublicUserData, createPrivateUserData]);
      })
      .catch(function(err) {
        // Handle Errors here.
        dispatch({ type: "SIGNUP_ERROR", err });
        // ...
      })
      .then(() => {
        dispatch({ type: "SIGNUP_SUCCESS" });
      });
  };
};

export const signUpWithFacebook = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const auth = firebase.auth();
    var provider = new firebase.auth.FacebookAuthProvider();
    provider.setCustomParameters({
      display: "popup",
    });

    const firestore = firebase.firestore();

    auth.useDeviceLanguage();

    auth
      .signInWithPopup(provider)
      .then(function(result) {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        window.analytics.identify(user.uid, {
          name: user.displayName,
          email: user.email,
        });
        if (result.additionalUserInfo?.isNewUser) {
          window.analytics.track("Signed Up", { authType: "facebook" });
        } else {
          window.analytics.track("Signed In ", { authType: "facebook" });
        }

        const createPublicUserData = firestore
          .collection("users")
          .doc(user.uid)
          .set(
            {
              name: user.displayName,
              firstName: result?.additionalUserInfo?.profile?.first_name || "",
              lastName: result?.additionalUserInfo?.profile?.last_name || "",
              photoURL: user.photoURL,
            },
            { merge: true } // Merge the data so in the case of signing-in, existing data isn't overwritten
          );

        const createPrivateUserData = firestore
          .collection("users")
          .doc(user.uid)
          .collection("privateUserData")
          .doc(user.uid)
          .set({
            email: user.email,
          });

        return Promise.all([createPublicUserData, createPrivateUserData]);
      })
      .catch(function(err) {
        if (err.code === "auth/account-exists-with-different-credential") {
          // User's email already exists.
          // The provider account's email address.
          var email = err.email;
          // Get sign-in methods for this email.
          auth.fetchSignInMethodsForEmail(email).then(methods => {
            // If the user has several sign-in methods,
            // the first method in the list will be the "recommended" method to use.
            let authMethod;
            if (methods[0] === "password") {
              authMethod = "email";
            } else if (methods[0] === "google.com") {
              authMethod = "Google";
            } else if (methods[0] === "facebook.com") {
              authMethod = "Facebook";
            }
            err.message = `You have an account using ${authMethod} login. Sign in with ${authMethod} to continue.`;
            // Report info level logging to Sentry to track how often this occurs
            Sentry.captureMessage(`Failed sign in from Facebook, existing auth: ${authMethod}`);
            dispatch({ type: "LOGIN_ERROR", err });
          });
        } else {
          dispatch({ type: "SIGNUP_ERROR", err });
        }
      })
      .then(() => {
        dispatch({ type: "SIGNUP_SUCCESS" });
      });
  };
};

export const sendForgotPasswordEmail = email => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    var auth = firebase.auth();

    auth
      .sendPasswordResetEmail(email)
      .then(function() {
        history.push("/reset_email_sent");
        dispatch({ type: "PASSWORD_RESET_SUCCESS", email: email });
      })
      .catch(function(err) {
        dispatch({ type: "PASSWORD_RESET_ERROR", email: email, err: err });
      });
  };
};

export const onSignupUnmounted = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: "SIGNUP_UNMOUNTED" });
  };
};

export const onSigninUnmounted = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: "SIGNIN_UNMOUNTED" });
  };
};

export const fetchAndSetSubscriptionStatus = () => {
  return (dispatch, getState, { getFirebase }) => {
    const user = getFirebase().auth().currentUser;
    if (user) {
      isUserProSubscription().then(response => {
        window.analytics.identify(user.uid, {
          isProUser: response,
        });
        dispatch({ type: "FETCH_AND_SET_SUBSCRIPTION_STATUS", status: response });
      });
    }
  };
};

export const clearSubscriptionStatus = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: "CLEAR_SUBSCRIPTION_STATUS" });
  };
};

export const setSignInCompleteRedirectRoute = redirectRoute => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: "SET_SIGNIN_COMPLETE_REDIRECT_ROUTE",
      signInCompleteRedirectRoute: redirectRoute,
    });
  };
};

export const clearSignInCompleteRedirectRoute = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: "CLEAR_SIGNIN_COMPLETE_REDIRECT_ROUTE",
    });
  };
};
