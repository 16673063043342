import React, { Component } from "react";
import RenameForm from "./RenameForm";
import { connect } from "react-redux";
import { hideRenameTripForm } from "../store/actions/modalActions";
import { renameTrip } from "../store/actions/tripActions";

export class RenameTripForm extends Component {
  render() {
    return (
      <RenameForm
        modalTitle="Rename Trip"
        title={this.props.title}
        placeholder="Name of trip"
        showRenameModal={this.props.showRenameTripModal}
        hideRenameForm={this.props.hideRenameTripForm}
        rename={this.props.renameTrip}
        id={this.props.tripId}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    renameTrip: (tripId, tripTitle) => dispatch(renameTrip(tripId, tripTitle)),
    hideRenameTripForm: () => dispatch(hideRenameTripForm()),
  };
};

const mapStateToProps = state => {
  return {
    showRenameTripModal: state.modals.showRenameTripModal,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenameTripForm);
